import {
  DeleteSkillCategoryRequest,
  GetSkillCategoryRequest,
  GetSkillCategoryResponse,
  UpdateSkillCategoryCoverRequest,
  UpdateSkillCategoryOperationRequest,
  UpdateSkillCategoryPublishableRequest,
  UpdateSkillCategoryRequest,
  UpdateSkillCategorySkillOrderOperationRequest,
  UpdateSkillCategorySkillOrderRequest,
  DeleteSkillCategoryBadgeRequest,
  UpdateSkillCategoryBadgeRequest,
  UpdateSkillCategoryLanguagesRequest,
  UpdateSkillCategoryLanguagesOperationRequest,
  MoveSkillCategoryToOrganizationRequest,
  CopySkillCategoryToOrganizationRequest,
} from "@revolutionrobotics/admin-api";

import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { EvolvedTable } from "../../components/Table";
import { MoveApi, SkillApi, CopyApi } from "../../services/configuration";
import LanguageContext from "../../services/LanguageContext";
import { ImageWithPlaceholder } from "../../components/ImageWithPlaceholder";
import { ErrorResponse, SetError } from "../../services/general";
import { ButtonWithStyle } from "../../components/ButtonWithStyle";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { DeleteModal } from "../../components/DeleteModal";
import { useNavigate } from "react-router";
import { NewSkillForm } from "../../components/Skill/NewSkillForm";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { LanguageComponent } from "../../components/Language/LanguageComponent";
import { LiveValidationComponent } from "../../components/LiveValidationComponent";
import { ErrorState } from "../../components/ErrorState";
import { styles } from "../../styles/styles";
import { MoveRequestModal } from "../../components/MoveRequestModal";
import { CopyRequestModal } from "../../components/CopyRequestModal";

export type Params = {
  id: string;
};

interface MultiLangData {
  id: string;
  language: string;
  title: string;
  subtitle: string;
}

export function SkillCategoryDetails() {
  const { id } = useParams<Params>();

  const [data, setData] = useState({} as GetSkillCategoryResponse);
  const [multiLangData, setMultiLangData] = useState([] as MultiLangData[]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const { language, setSystemLanguages } = useContext(LanguageContext);
  const navigate = useNavigate();

  const getSkillCategoryDetails = () => {
    console.log("getSkillCategoryDetails()");
    if (!id) {
      return;
    }
    setLoading(true);

    const request: GetSkillCategoryRequest = {
      id: id,
    };

    SkillApi.getSkillCategory(request)
      .then(
        (result: GetSkillCategoryResponse) => {
          setData(result);
          setSystemLanguages(result.systemLanguages);
          console.log(result);
          let multiLang: MultiLangData[] = [];
          result.supportedLanguages.forEach((language) => {
            multiLang.push({
              id: id,
              language: language.id,
              title: result.title[language.id],
              subtitle: result.subtitle[language.id],
            });
          });

          setMultiLangData(multiLang);
        },
        (error: ErrorResponse) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadSkillCategoryImage = () => {
    console.log("uploadSkillCategoryImage()", file?.name);

    if (!file || !id) {
      return;
    }

    const req: UpdateSkillCategoryCoverRequest = {
      id: id,
      file: file,
    };

    SkillApi.updateSkillCategoryCover(req).then(
      (result) => {
        console.log(result, id, "cover has been updated.");
        window.location.reload();
      },
      (error) => {
        SetError(error, setError);
      }
    );
  };

  const uploadSkillCategoryBadge = () => {
    console.log("uploadSkillCategoryBadge()", file?.name);

    if (!file || !id) {
      return;
    }

    const req: UpdateSkillCategoryBadgeRequest = {
      id: id,
      file: file,
    };

    SkillApi.updateSkillCategoryBadge(req).then(
      (result) => {
        console.log(result, id, "badge has been updated.");
        window.location.reload();
      },
      (error) => {
        SetError(error, setError);
      }
    );
  };
  const updateSkillCategoryDetails = (data: any) => {
    console.log("updateSkillCategoryDetails()");
    if (!id) {
      return;
    }
    let details: UpdateSkillCategoryRequest = {
      subtitle: data["subtitle"],
      title: data["title"],
      lang: data["language"],
    };

    let request: UpdateSkillCategoryOperationRequest = {
      id: id,
      request: details,
    };

    SkillApi.updateSkillCategory(request)
      .then(
        (result) => {
          console.log(result, id, "details has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getSkillCategoryDetails();
      });
  };

  const setPublishableState = (state: boolean) => {
    console.log("setPublishableState()", state);

    if (!id) {
      return;
    }

    const req: UpdateSkillCategoryPublishableRequest = {
      publishable: state,
      id: id,
    };

    SkillApi.updateSkillCategoryPublishable(req)
      .then(
        (result) => {
          console.log(result, id, "publishable state has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getSkillCategoryDetails();
      });
  };

  const deleteBadge = () => {
    console.log("deleteBadge()");

    if (!id) {
      return;
    }

    const req: DeleteSkillCategoryBadgeRequest = {
      id: id,
    };

    SkillApi.deleteSkillCategoryBadge(req)
      .then(
        (result) => {
          console.log(result, id, "badge has been deleted.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getSkillCategoryDetails();
        setFile(null);
      });
  };

  const deleteSkillCategory = () => {
    console.log("deleteSkillCategory()");

    if (!id) {
      return;
    }

    const req: DeleteSkillCategoryRequest = {
      id: id,
    };

    SkillApi.deleteSkillCategory(req)
      .then(
        (result) => {
          console.log(result, id, " has been deleted.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        navigate(`/skills`);
      });
  };

  const updateSkillCategorySkillOrder = (ids: string[]) => {
    console.log("updateSkillCategorySkillOrder()");

    if (!id) {
      return;
    }

    const request: UpdateSkillCategorySkillOrderRequest = {
      ids: ids,
    };

    const req: UpdateSkillCategorySkillOrderOperationRequest = {
      id: id,
      request: request,
    };

    SkillApi.updateSkillCategorySkillOrder(req)
      .then(
        () => {
          console.log("Skill category skills order has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getSkillCategoryDetails();
      });
  };
  const updateSkillCategoryLanguages = (data: any) => {
    console.log("updateSkillCategoryLanguages()");

    if (!id || !data.publishableLanguages || !data.supportedLanguages) {
      return;
    }

    const request: UpdateSkillCategoryLanguagesRequest = {
      publishableLanguages: data.publishableLanguages,
      supportedLanguages: data.supportedLanguages,
    };

    const req: UpdateSkillCategoryLanguagesOperationRequest = {
      id: id,
      request: request,
    };

    SkillApi.updateSkillCategoryLanguages(req)
      .then(
        () => {
          console.log("Skill category languages has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getSkillCategoryDetails();
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("SkillCategoryDetails did mount.", id);
    getSkillCategoryDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, []);

  function moveSkillCategory(organizationID: string) {
    if (!id) {
      return;
    }

    const req: MoveSkillCategoryToOrganizationRequest = {
      id: id,
      pid: organizationID,
    };

    MoveApi.moveSkillCategoryToOrganization(req)
      .then(
        () => {
          console.log("Challenge Category languages has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        navigate(`/challenges`);
      });
  }
  function copySkillCategory(organizationID: string) {
    if (!id) {
      return;
    }

    const req: CopySkillCategoryToOrganizationRequest = {
      id: id,
      pid: organizationID,
    };
    setLoading(true);
    CopyApi.copySkillCategoryToOrganization(req)
      .then(
        () => {
          console.log("Copy skill category to organization was successful.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
        navigate(`/challenges`);
      });
  }

  return (
    <>
      {error && (
        <ErrorState
          refresh={getSkillCategoryDetails}
          error={error}
          setError={setError}
        />
      )}

      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row className=" mb-3">
                  <Col
                    className="col-3 justify-content-around"
                    style={{ textAlign: "center" }}
                  >
                    <ImageWithPlaceholder
                      src={data.coverThumbnailUrl}
                      alt={data.title ? data.title[language] : undefined}
                      className="img-thumbnail"
                      shouldOpenNewTab={true}
                      style={styles.coverImage}
                    />
                    <Row className="mt-2">
                      <Col>
                        <ButtonWithModal
                          buttonTitle="Upload cover"
                          modalTitle={`Upload new image for Epic Category ${id}`}
                          agreeCallback={() => {
                            uploadSkillCategoryImage();
                          }}
                          cancelCallback={() => {}}
                        >
                          <input
                            type="file"
                            accept="image/jpeg"
                            onChange={(e) => {
                              if (
                                e.target &&
                                e.target.files &&
                                e.target.files?.length > 0
                              ) {
                                setFile(e.target.files[0]);
                              } else {
                                setFile(null);
                              }
                            }}
                          ></input>
                        </ButtonWithModal>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-7">
                    <Row className=" align-items-center">
                      <Col>
                        <Row>
                          <h1
                            className="mx-auto mb-1 text-center"
                            style={styles.pageTitle}
                          >
                            {data.title[language]}
                          </h1>
                        </Row>
                        <Row>
                          <h2
                            className="mx-auto mb-3 text-center text-secondary"
                            style={styles.secondaryText}
                          >
                            {data.subtitle[language]}
                          </h2>
                        </Row>
                        <Row>
                          <h3
                            className="mx-auto mb-1 text-center"
                            style={styles.pageTitle}
                          >
                            {id}
                          </h3>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-2" style={{ textAlign: "center" }}>
                    <ImageWithPlaceholder
                      src={data.badgeUrl}
                      alt={data.title ? data.title[language] : undefined}
                      className="img-thumbnail"
                      shouldOpenNewTab={true}
                    />
                    <Row className="mt-2">
                      <Col>
                        <ButtonWithModal
                          buttonTitle="Upload badge"
                          modalTitle={`Upload new badge for Epic Category`}
                          agreeCallback={() => {
                            uploadSkillCategoryBadge();
                          }}
                          cancelCallback={() => {}}
                          onlyIcon
                        >
                          <input
                            type="file"
                            accept="image/png"
                            onChange={(e) => {
                              if (
                                e.target &&
                                e.target.files &&
                                e.target.files?.length > 0
                              ) {
                                setFile(e.target.files[0]);
                              } else {
                                setFile(null);
                              }
                            }}
                          ></input>
                        </ButtonWithModal>
                      </Col>
                      <Col>
                        <DeleteModal
                          buttonTitle="Delete badge"
                          modalTitle="Delete current badge"
                          agreeCallback={() => {
                            deleteBadge();
                          }}
                          onlyIcon
                        ></DeleteModal>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <LanguageComponent
                    supportedLanguages={data.supportedLanguages}
                    publishableLanguages={data.publishableLanguages}
                    systemLanguages={data.systemLanguages}
                    updateLanguages={updateSkillCategoryLanguages}
                  />
                </Row>
                <Row>
                  <LiveValidationComponent validation={data.validation} />
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Row className=" align-items-center">
                      <Col className="col-9">
                        <h2 style={styles.secondaryText}>Details</h2>
                      </Col>
                      <Col className="text-end">
                        <ButtonWithStyle
                          buttonTitle={
                            data.publishable ? "Revoke Live" : "Make Live"
                          }
                          variant="secondary"
                          onClickCallback={() => {
                            setPublishableState(!data.publishable);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <EvolvedTable
                          data={multiLangData}
                          ignoreID
                          saveAction={(editedData: object) => {
                            updateSkillCategoryDetails(editedData);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3 align-items-center">
                      <Col className="col-9">
                        <h2 style={styles.secondaryText}>Epics</h2>
                      </Col>
                      <Col className="text-end">
                        <ButtonWithModal
                          buttonTitle={"Add new"}
                          modalTitle={"Add new epic"}
                          isModalWithForm={true}
                        >
                          <NewSkillForm
                            categoryId={id!}
                            setError={setError}
                            refresh={getSkillCategoryDetails}
                          />
                        </ButtonWithModal>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <EvolvedTable
                          data={data.skills.rows}
                          customHeaders={[
                            "title",
                            "subtitle",
                            "publishable",
                            "live",
                          ]}
                          basePath="/skills/skill"
                          updateDataOrder={updateSkillCategorySkillOrder}
                          insertAfterForm={
                            <NewSkillForm
                              categoryId={id!}
                              setError={setError}
                              refresh={getSkillCategoryDetails}
                            />
                          }
                        />
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        gap: 32,
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div>
                        <MoveRequestModal
                          buttonTitle={`Move "${data.title[language]}" (organization)`}
                          modalTitle={`Move "${data.title[language]}" epic category to another organization..`}
                          agreeCallback={moveSkillCategory}
                        />
                      </div>
                      <div>
                        <CopyRequestModal
                          buttonTitle={`Copy "${data.title[language]}" (organization)`}
                          modalTitle={`Copy "${data.title[language]}" epic category to another organization..`}
                          agreeCallback={copySkillCategory}
                        />
                      </div>
                      <div>
                        <DeleteModal
                          buttonTitle={`Delete "${data.title[language]}"`}
                          modalTitle={`Delete "${data.title[language]}" epic.`}
                          agreeCallback={() => {
                            deleteSkillCategory();
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
