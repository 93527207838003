import React from "react";
import { Col, Form } from "react-bootstrap";
import { styles } from "../styles/styles";
import "../styles/input.css";

interface FormItemProps {
  title: string;
  type: string;
  required?: boolean;
  name: string;
  handleChange: Function;
  isTextArea?: boolean;
  fileAccept?: string;
  error?: string;
}

export function FormItem({
  title,
  type,
  required,
  name,
  handleChange,
  isTextArea,
  fileAccept,
  error,
}: FormItemProps) {
  return type === "checkbox" ? (
    <Col>
      <Form.Group as={Col} controlId={"validationCustom01" + name}>
        <Form.Label>
          {title}
          <span style={styles.requiredText}>{required ? " *" : ""}</span>
        </Form.Label>
        <Form.Check
          type="checkbox"
          name={name}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <Form.Control.Feedback>Good</Form.Control.Feedback>
      </Form.Group>
    </Col>
  ) : (
    <Col>
      <Form.Group as={Col} controlId={"validationCustom01" + name}>
        <Form.Label>
          {title}
          <span style={{ color: "red" }}>{required ? " *" : ""}</span>
        </Form.Label>
        <Form.Control
          isInvalid={!!error}
          className="form-control"
          required={required}
          style={
            type === ("text" || "number") ? styles.input : styles.fileInput
          }
          type={type}
          placeholder={type === ("text" || "number") ? title : undefined}
          name={name}
          autoComplete="current-password"
          onChange={(e) => {
            handleChange(e);
          }}
          onFocus={(e) => {
            if (type === "file" && fileAccept) {
              (e.target as HTMLInputElement).accept = fileAccept;
            }
          }}
          as={isTextArea ? "textarea" : undefined}
          rows={type === "text" ? (isTextArea ? 3 : 1) : undefined}
        />
        <Form.Control.Feedback type={!!error ? "invalid" : "valid"}>
          {!!error ? error : "Good"}
        </Form.Control.Feedback>
      </Form.Group>
    </Col>
  );
}
