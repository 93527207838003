import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormItem } from "../../components/FormItem";
import { ButtonWithStyle } from "../../components/ButtonWithStyle";
import {
  UpdateUserPasswordInOrganizationOperationRequest,
  UpdateUserPasswordInOrganizationRequest,
} from "@revolutionrobotics/admin-api";
import { OrganizationApi } from "../../services/configuration";
import { SetError } from "../../services/general";

export type UserPasswordFormProps = {
  id: string;
  uid: string;
  getUserDetails: () => void;
  needsPassword: boolean;
};

export function UserPasswordForm({
  id,
  uid,
  getUserDetails,
  needsPassword,
}: UserPasswordFormProps) {
  const [validated, setValidated] = useState(false);
  const [inputs, setInputs] = useState<any | null>(null);
  const [error, setError] = useState<string | undefined>();

  const validateSamePassword = () => {
    if (inputs.password !== inputs.password_repeat) {
      setError("Passwords don't match");
      return;
    } else {
      setError(undefined);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (!inputs || !inputs.password || !inputs.password_repeat) return;

    validateSamePassword();

    let request: UpdateUserPasswordInOrganizationRequest = {
      password: inputs.password,
    };

    let req: UpdateUserPasswordInOrganizationOperationRequest = {
      id,
      uid,
      request,
    };
    console.log(req);
    OrganizationApi.updateUserPasswordInOrganization(req)
      .then(
        () => {
          console.log(id, "User password has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getUserDetails();
      });
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values: any) => ({ ...values, [name]: value }));
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col>
          <Row>
            <FormItem
              title={"New password"}
              type={"password"}
              required
              name={"password"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"Repeat new password"}
              type={"password"}
              required
              name={"password_repeat"}
              handleChange={handleChange}
              error={error}
            />
          </Row>
        </Col>
      </Row>

      <ButtonWithStyle
        buttonTitle={needsPassword ? "Set" : "Update"}
        type="submit"
        variant="primary"
      />
    </Form>
  );
}
