import * as colors from "./ColorConstants";

export const styles = {
  //Menu
  menuContainer: {
    height: "100vh",
    width: "150px",
    backgroundColor: colors.menuBackgroundColor,
    overflow: "auto",
  },
  menuIcon: {
    width: 24,
    height: 24,
  },
  //Page
  pageContainer: {
    backgroundColor: colors.pageBackgroundColor,
    height: "100vh",
    width: window.innerWidth - 150,
    overflow: "auto",
    marginLeft: "150px",
  },
  //TopManuBar
  topMenuContainer: {
    backgroundColor: colors.pageBackgroundColor,
  },
  organizationSelectorContainer: {
    maxWidth: "400px",
    textAlign: "center" as "center",
  },
  languageSelectorContainer: {
    maxWidth: "300px",
    textAlign: "center" as "center",
  },
  logoutContainer: {
    maxWidth: "100px",
    textAlign: "center" as "center",
    marginBottom: "6px",
    marginTop: "6px",
  },
  //CarouselComponent
  carouselModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "50px",
  },
  carouselItem: {
    borderRadius: "5px",
    backgroundColor: colors.modalBackgroundColor,
    textAlign: "center" as const,
    minHeight: "50vh",
  },
  carouselImage: {
    maxWidth: "auto",
    maxHeight: "50vh",
    verticalAlign: "center",
    backgroundColor: colors.modalBackgroundColor,
  },

  //ButtonWithModal
  buttonWithModal: {
    backgroundColor: colors.modalBackgroundColor,
    borderRadius: "8px",
    color: colors.primaryTextColor,
  },
  buttonWithModalHeader: {
    backgroundColor: colors.modalBackgroundColor,
    color: colors.primaryTextColor,
    borderBottom: "0px",
  },
  buttonWithModalBody: {
    backgroundColor: colors.modalBackgroundColor,
    color: colors.primaryTextColor,
    width: "100%",
  },
  buttonWithModalFooter: {
    backgroundColor: colors.modalBackgroundColor,
    color: colors.primaryTextColor,
    borderTop: "0px",
    width: "100%",
    position: "relative" as const,
  },
  requiredText: {
    color: "red",
  },
  addUserSeparatorLine: {
    width: "100%",
    height: 2,
    backgroundColor: "grey",
    marginTop: 24,
  },
  addUsersButtonContainer: {
    gap: 16,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    marginTop: 8,
  },
  //Text
  pageTitle: {
    color: colors.pageTitleColor,
  },
  primaryText: {
    color: colors.primaryTextColor,
  },
  secondaryText: {
    color: colors.secondaryTextColor,
  },
  thirdText: {
    color: colors.thirdTextColor,
  },
  errorText: {
    color: colors.dangerButtonColor,
    fontSize: "10px",
  },
  //Table
  table: {
    backgroundColor: colors.tableBackgroundColor,
  },
  tableHeader: {
    backgroundColor: colors.tableHeaderBackgroundColor,
    borderWidth: "0px",
  },
  tableHeaderText: {
    borderWidth: "0px",
    color: colors.tableHeaderTextColor,
  },
  tableRow: {
    height: "100px",
    borderWidth: "0px",
  },
  clickableTableRow: {
    height: "100px",
    borderWidth: "0px",
    cursor: "pointer",
  },
  tableDataCell: {
    verticalAlign: "middle",
    position: "relative" as const,
    borderWidth: "0px",
  },
  tableTextContainer: {
    whiteSpace: "break-spaces" as const,
    //overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle",
  },
  tableText: {
    color: colors.secondaryTextColor,
    borderWidth: "0px",
  },
  successIcon: {
    fontSize: "50px",
    color: colors.successIconColor,
  },
  copyIcon: {
    color: colors.secondaryTextColor,
    marginLeft: "5px",
  },
  failIcon: {
    fontSize: "50px",
    color: colors.failIconColor,
  },
  pdfIcon: {
    fontSize: "50px",
    color: colors.secondaryTextColor,
    cursor: "pointer",
  },
  videoIcon: {
    fontSize: "50px",
    color: colors.secondaryTextColor,
    cursor: "pointer",
  },
  tableInput: {
    width: "100%",
    height: "50%",
    backgroundColor: colors.inputBackgroundColor,
    color: colors.inputTextColor,
    borderWidth: "0px",
    borderRadius: "5px",
  },
  tableFileInput: {
    color: colors.inputTextColor,
    width: "250px",
  },
  tableActionButtonContainer: {
    width: "200px",
    verticalAlign: "middle",
    textAlign: "center" as "center",
    borderWidth: "0px",
  },
  tableInsertAfterButton: {
    position: "absolute",
    height: "50px",
    bottom: "-25px",
    left: "-25px",
    zIndex: "55",
  },
  gridTableInsertAfterButton: {
    position: "absolute",
    height: "50px",
    bottom: "50%",
    right: "-35px",
    zIndex: "55",
    transform: "translate(0px, 25px)",
  },
  tableSmallThumbnail: {
    minHeight: "75px",
    maxHeight: "75px",
    maxWidth: "75px",
  },
  tableThumbnail: {
    minHeight: "150px",
    maxHeight: "150px",
  },
  tableEmptyState: {
    color: colors.primaryTextColor,
    height: "200px",
    textAlign: "center" as "center",
  },
  tableEmptyStateIcon: {
    color: colors.secondaryTextColor,
    fontSize: "50px",
    marginBottom: "5px",
  },
  tableDropDown: {
    minWidth: "160px",
    backgroundColor: colors.inputBackgroundColor,
    color: colors.inputTextColor,
    borderWidth: "0px",
    borderRadius: "5px",
  },
  //GridTable
  OrderNumberText: {
    position: "absolute" as "absolute",
    color: colors.primaryTextColor,
    left: "20px",
    fontSize: "20px",
    backgroundColor: colors.orderBackground,
    margin: "5px",
    paddingLeft: "5px",
    paddingRight: "5px",
    borderRadius: "8px",
    zIndex: 55,
  },
  //Button
  primaryButton: {
    backgroundColor: colors.primaryButtonColor,
    color: colors.primaryButtonTextColor,
    borderWidth: "0px",
  },
  secondaryButton: {
    backgroundColor: colors.secondaryButtonColor,
    color: colors.secondaryButtonTextColor,
    borderWidth: "0px",
  },
  dangerButton: {
    backgroundColor: colors.dangerButtonColor,
    color: colors.dangerButtonTextColor,
    borderWidth: "0px",
  },
  button: {
    backgroundColor: colors.buttonColor,
    color: colors.buttonTextColor,
    borderWidth: "0px",
    width: "auto",
  },
  actionButtonWidth: {
    minWidth: 50,
  },
  buttonWidth: {
    minWidth: 150,
    maxWidth: 300,
  },
  modalFormCancelButton: {
    position: "absolute" as const,
    bottom: "35px",
    right: "25px",
  },

  //Image
  image: {
    borderColor: colors.imageBorderColor,
    backgroundColor: colors.imageBackgroundColor,
  },
  imagePlaceholderContainer: {
    borderWidth: "0px",
    backgroundColor: "transparent",
  },
  imagePlaceholder: {
    width: "100%",
    height: "100%",

    color: colors.primaryIconColor,
    borderColor: colors.imageBorderColor,
    backgroundColor: colors.imageBackgroundColor,
  },
  imagePlaceholderBorder: {
    border: "1px solid #FFF03A",
    borderRadius: "0.375rem",
  },
  imageDeleteIconContainer: {
    position: "absolute" as "absolute",
    bottom: "10px",
    right: "10px",
  },
  coverImage: { maxWidth: "250px", height: "auto" },
  //Avatars
  avatarImageContainer: {
    maxWidth: "190px",
    minWidth: "190px",
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "25px",
    justifyContent: "center",
    position: "relative" as const,
    display: "flex",
  },
  //HeroRobots
  HeroRobotsImageContainer: {
    maxWidth: "380px",
    minWidth: "380px",
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "25px",
    justifyContent: "center",
    position: "relative" as const,
    display: "flex",
  },
  //InputField
  input: {
    width: "100%",
    height: "50%",
    backgroundColor: colors.inputBackgroundColor,
    color: colors.inputTextColor,
    borderWidth: "0px",
    borderRadius: "5px",
  },
  selectableInput: {
    width: "100%",
    backgroundColor: colors.inputBackgroundColor,
    color: colors.inputTextColor,
    borderWidth: "0px",
    borderRadius: "5px",
  },
  fileInput: {
    backgroundColor: colors.inputBackgroundColor,
    color: colors.inputTextColor,
    borderWidth: "0px",
    borderRadius: "5px",
  },
  //LoadingIndicator
  loadingIndicatorContainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingSpinner: {
    width: "5rem",
    height: "5rem",
    color: colors.primaryTextColor,
  },
  loadingText: { color: colors.primaryTextColor },
  //SearchBar
  searchBarInput: {
    margin: "8px",
    width: "80%",
    backgroundColor: colors.tableBackgroundColor,
    color: colors.primaryTextColor,
    borderWidth: "0px",
    borderRadius: "5px",
  },
  searchBarButton: { marginBottom: "3px" },
  noResult: {
    color: colors.secondaryTextColor,
    height: "200px",
    textAlign: "center" as "center",
  },
  noResultStateIcon: {
    color: colors.secondaryTextColor,
    fontSize: "50px",
    marginBottom: "5px",
  },
  //language
  languageComponentItem: {
    backgroundColor: colors.secondaryButtonColor,
    textAlign: "center" as "center",
    display: "flex",
    padding: "4px",
    paddingLeft: "16px",
    paddingRight: "16px",
    borderRadius: "16px",
    margin: "8px",
    color: colors.secondaryButtonTextColor,
  },
  languageComponentItemDropdown: {
    backgroundColor: colors.secondaryButtonColor,
    textAlign: "center" as "center",
    padding: "4px",
    paddingLeft: "16px",
    paddingRight: "16px",
    borderRadius: "16px",
    margin: "8px",
    color: colors.secondaryButtonTextColor,
  },
  languageComponentCloseIcon: {
    marginLeft: "8px",
    fontSize: 20,
    cursor: "pointer",
  },
  //Login
  loginComponent: {
    width: 500,
    color: colors.primaryTextColor,
    backgroundColor: colors.tableBackgroundColor,
    textAlign: "center" as "center",
    borderRadius: "8px",
  },
  forgotPasswordContainer: {
    justifyContent: "center",
  },
  forgotPassword: {
    color: colors.secondaryButtonTextColor,
    backgroundColor: "transparent",
  },
  //Logout
  logoutText: {
    cursor: "pointer",
    color: colors.primaryTextColor,
  },
  //LiveValidation
  validationSuccessIcon: {
    color: colors.successIconColor,
  },
  //Pagination
  paginationContainer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    gap: 8,
  },
};
