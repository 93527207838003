import {
  CreateRobotSoftwareOperationRequest,
  UpdateRobotSoftwareUpdateImageRequest,
  CreateRobotSoftwareResponse,
  CreateRobotSoftwareRequest,
} from "@revolutionrobotics/admin-api";
import React, { useState } from "react";
import { Col, Form, Row, Dropdown } from "react-bootstrap";
import { RobotSoftwareApi } from "../../services/configuration";
import { SetError } from "../../services/general";
import { ButtonWithStyle } from "../ButtonWithStyle";
import { FormItem } from "../FormItem";
import { styles } from "../../styles/styles";

interface NewUpdateFormProps {
  setError: Function;
  refresh: Function;
}

export function NewUpdateForm({ setError, refresh }: NewUpdateFormProps) {
  const [validated, setValidated] = useState(false);
  const [inputs, setInputs] = useState<any | null>({
    softwareRevisionMatching: "1.0.x",
  });
  const [file, setFile] = useState<File | null>(null);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    createRobotSoftware();
  };

  const createRobotSoftware = () => {
    if (
      !inputs ||
      isNaN(inputs.major) ||
      isNaN(inputs.minor) ||
      isNaN(inputs.patch)
    ) {
      return;
    }

    const details: CreateRobotSoftwareRequest = {
      compatibleHardwareRevisions: ["2.0.0"],
      major: inputs.major,
      minor: inputs.minor,
      patch: inputs.patch,
      softwareRevisionMatching: inputs.softwareRevisionMatching,
    };

    const req: CreateRobotSoftwareOperationRequest = {
      request: details,
    };

    RobotSoftwareApi.createRobotSoftware(req)
      .then(
        (response: CreateRobotSoftwareResponse) => {
          console.log(response.id, "has been created.");
          if (file) {
            updateRobotSoftwareUpdateImage(response.id);
          }
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        if (!file) {
          refresh();
        }
      });
  };

  const updateRobotSoftwareUpdateImage = (id: string) => {
    console.log("updateRobotSoftwareUpdateImage()");

    if (!id || !file) {
      return;
    }

    const req: UpdateRobotSoftwareUpdateImageRequest = {
      file: file,
      id: id,
    };
    RobotSoftwareApi.updateRobotSoftwareUpdateImage(req)
      .then(
        () => {
          console.log(id, "software image has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        refresh();
      });
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value =
      typeof event.target.value === "number"
        ? event.target.value
        : parseInt(event.target.value.replace(/[^\d]+/g, ""));

    setInputs((values: any) => ({ ...values, [name]: value }));
  };

  const handleDropdownChange = (name: string, value: string) => {
    const newInputs = { ...inputs, [name]: value };
    setInputs(newInputs);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col>
          <Row>
            <div>Compatible Hardvare Revisions:</div>
            <Dropdown>
              <Dropdown.Toggle
                style={styles.selectableInput}
                variant="warning"
                id="dropdown-basic"
              >
                2.0.0
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: "100%" }}>
                <Dropdown.Item key={"version"}>2.0.0</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Row>
          <Row>
            <div>Software revision matching: </div>
            <Dropdown>
              <Dropdown.Toggle
                style={styles.selectableInput}
                variant="warning"
                id="dropdown-basic"
              >
                {inputs.softwareRevisionMatching}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: "100%" }}>
                {["0.1.x", "1.0.x"].map((item) => (
                  <Dropdown.Item
                    onClick={() =>
                      handleDropdownChange("softwareRevisionMatching", item)
                    }
                    key={item}
                  >
                    {item}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"Major"}
              type={"number"}
              required={true}
              name={"major"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"Minor"}
              type={"number"}
              required={true}
              name={"minor"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"Patch"}
              type={"number"}
              required={true}
              name={"patch"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <Form.Label>Software image:</Form.Label>
            <input
              type="file"
              style={styles.tableFileInput}
              accept=".gz"
              onChange={(e) => {
                if (e.target && e.target.files && e.target.files?.length > 0) {
                  setFile(e.target.files[0]);
                } else {
                  setFile(null);
                }
              }}
            />
          </Row>
        </Col>
      </Row>

      <ButtonWithStyle buttonTitle="Create" type="submit" variant="primary" />
    </Form>
  );
}
