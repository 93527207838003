import {
  GetLanguagesResponse,
  GetLanguagesRow,
  UpdateLanguageOperationRequest,
  UpdateLanguageRequest,
} from "@revolutionrobotics/admin-api";
import { LanguageApi } from "../../services/configuration";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ErrorResponse, SetError } from "../../services/general";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { EvolvedTable } from "../../components/Table";
import { NewLanguageForm } from "../../components/Language/NewLanguageForm";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { ErrorState } from "../../components/ErrorState";
import { styles } from "../../styles/styles";

export function Language() {
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [tableData, setTableData] = useState([] as GetLanguagesRow[]);
  const [loading, setLoading] = useState(true);

  const getLanguages = () => {
    console.log("getLanguages()");
    setLoading(true);

    LanguageApi.getLanguages()
      .then(
        (result: GetLanguagesResponse) => {
          setTableData(result.languages.rows);
          console.log(result);
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const updateLanguage = (data: any) => {
    console.log("uploadHeroRobotImage()");

    if (!data.id || !data.name) {
      return;
    }
    const request: UpdateLanguageRequest = {
      name: data.name,
    };

    const req: UpdateLanguageOperationRequest = {
      id: data.id,
      request: request,
    };
    setLoading(true);

    LanguageApi.updateLanguage(req)
      .then(
        () => {
          console.log(data.id, "has been updated.");
        },
        (error) => {
          SetError(error, setError);
          getLanguages();
        }
      )
      .finally(() => {
        getLanguages();
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Language did mount.");
    getLanguages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {error && (
        <ErrorState refresh={getLanguages} error={error} setError={setError} />
      )}
      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row>
                  <Col>
                    <h1
                      className="mx-auto mb-3 text-center text-uppercase"
                      style={styles.pageTitle}
                    >
                      Language
                    </h1>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3 align-items-center">
                  <Col className="text-end">
                    <ButtonWithModal
                      buttonTitle={"Add new"}
                      modalTitle={"Add new language"}
                      isModalWithForm={true}
                    >
                      <NewLanguageForm
                        setLoading={setLoading}
                        setError={setError}
                        refresh={getLanguages}
                      />
                    </ButtonWithModal>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EvolvedTable
                      data={tableData}
                      saveAction={(editedData: object) => {
                        updateLanguage(editedData);
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
