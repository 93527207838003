import {
  GetChallengeRequest,
  GetChallengeResponse,
  UpdateChallengeCoverRequest,
  DeleteChallengeRequest,
  UpdateChallengePublishableRequest,
  UpdateChallengeRequest,
  UpdateChallengeOperationRequest,
  GetChallengePdfEntry,
  UpdateChallengePdfRequest,
  UpdateChallengeLanguagesRequest,
  UpdateChallengeLanguagesOperationRequest,
  UpdateChallengeIntroRequest,
  MoveChallengeToCategoryRequest,
  CopyChallengeToCategoryRequest,
} from "@revolutionrobotics/admin-api";
import React, { useContext, useEffect, useState } from "react";
import { ErrorResponse, SetError } from "../../services/general";
import { Col, Container, Row } from "react-bootstrap";
import LanguageContext from "../../services/LanguageContext";
import { ButtonWithStyle } from "../../components/ButtonWithStyle";
import { useParams } from "react-router-dom";
import { ChallengeApi, MoveApi, CopyApi } from "../../services/configuration";
import { useNavigate } from "react-router";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { ImageWithPlaceholder } from "../../components/ImageWithPlaceholder";
import DeleteIcon from "@mui/icons-material/Delete";
import { EvolvedTable } from "../../components/Table";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { styles } from "../../styles/styles";
import { DeleteModal } from "../../components/DeleteModal";
import { LanguageComponent } from "../../components/Language/LanguageComponent";
import { LiveValidationComponent } from "../../components/LiveValidationComponent";
import { ErrorState } from "../../components/ErrorState";
import { MoveRequestModal } from "../../components/MoveRequestModal";
import { CopyRequestModal } from "../../components/CopyRequestModal";

export type Params = {
  id: string;
};

interface MultiLangData {
  id: string;
  language: string;
  title: string;
  description: string;
  pdf: GetChallengePdfEntry;
}

interface MultiLangRowData {
  description: string;
  id: string;
  order: number;
  title: string;
}

export function ChallengeDetails() {
  const { id } = useParams<Params>();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({} as GetChallengeResponse);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [multiLangData, setMultiLangData] = useState([] as MultiLangData[]);
  const [multiLangFormData, setMultiLangFormsData] = useState(
    [] as MultiLangRowData[]
  );

  const [file, setFile] = useState<File | null>(null);

  const { language, setSystemLanguages } = useContext(LanguageContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("ChallengeDetails did mount.", id);
    getChallenge();
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, []);

  const getChallenge = () => {
    console.log("getChallenge()");
    if (!id) {
      return;
    }

    setLoading(true);

    const req: GetChallengeRequest = {
      id: id,
    };

    ChallengeApi.getChallenge(req)
      .then(
        (result: GetChallengeResponse) => {
          setData(result);
          setSystemLanguages(result.systemLanguages);
          console.log(result);

          let titleAndSubtitleData: MultiLangData[] = [];

          result.supportedLanguages.forEach((language) => {
            titleAndSubtitleData.push({
              id: id,
              language: language.id,
              title: result.title[language.id],
              description: result.description[language.id],
              pdf: result.pdf[language.id],
            });
          });

          setMultiLangData(titleAndSubtitleData);

          let rows: MultiLangRowData[] = [];

          result.formItems.rows.forEach((row) => {
            rows.push({
              description: row.description[language],
              id: row.id,
              order: row.order,
              title: row.title[language],
            });
          });

          setMultiLangFormsData(rows);
        },
        (error: ErrorResponse) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadChallengeCover = () => {
    console.log("uploadChallengeCover()", file?.name);

    if (!file || !id) {
      return;
    }

    const req: UpdateChallengeCoverRequest = {
      id: id,
      file: file,
    };

    ChallengeApi.updateChallengeCover(req).then(
      (result) => {
        console.log(result, id, "intro has been updated.");
        window.location.reload();
      },
      (error) => {
        SetError(error, setError);
      }
    );
  };

  const updateChallengeIntro = () => {
    console.log("updateChallengeIntro()", file?.name);

    if (!file || !id) {
      return;
    }

    const req: UpdateChallengeIntroRequest = {
      id: id,
      file: file,
    };

    ChallengeApi.updateChallengeIntro(req).then(
      (result) => {
        console.log(result, id, "cover has been updated.");
        window.location.reload();
      },
      (error) => {
        SetError(error, setError);
      }
    );
  };

  const deleteChallenge = () => {
    console.log("deleteChallenge()");

    if (!id) {
      return;
    }

    const req: DeleteChallengeRequest = {
      id: id,
    };

    ChallengeApi.deleteChallenge(req)
      .then(
        (result) => {
          console.log(result, id, " has been deleted.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        navigate(`/challenges`);
      });
  };

  const setChallengePublishableState = (state: boolean) => {
    console.log("setPublishableState()", state);

    if (!id) {
      return;
    }

    const req: UpdateChallengePublishableRequest = {
      publishable: state,
      id: id,
    };

    ChallengeApi.updateChallengePublishable(req)
      .then(
        (result) => {
          console.log(result, id, "publishable state has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getChallenge();
      });
  };

  const updateChallenge = (data: any) => {
    console.log("updateChallenge()");
    if (!id) {
      return;
    }
    setLoading(true);
    let details: UpdateChallengeRequest = {
      lang: data["language"],
      title: data["title"],
      description: data["description"],
    };

    let request: UpdateChallengeOperationRequest = {
      id: id,
      request: details,
    };

    ChallengeApi.updateChallenge(request)
      .then(
        (result) => {
          console.log(result, id, "challenge has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        if (data["pdf"] !== null) {
          updateChallengePdf(data);
        } else {
          getChallenge();
          setLoading(false);
        }
      });
  };
  const updateChallengePdf = (data: any) => {
    console.log("updateChallengePdf()");
    if (!id) {
      return;
    }

    let request: UpdateChallengePdfRequest = {
      lang: data["language"],
      id: id,
      file: data["pdf"],
    };

    ChallengeApi.updateChallengePdf(request)
      .then(
        () => {
          console.log(id, data["language"], "challenge pdf has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getChallenge();
      });
  };
  const updateChallengeLanguages = (data: any) => {
    console.log("updateChallengeLanguages()");

    if (!id || !data.publishableLanguages || !data.supportedLanguages) {
      return;
    }

    const request: UpdateChallengeLanguagesRequest = {
      publishableLanguages: data.publishableLanguages,
      supportedLanguages: data.supportedLanguages,
    };

    const req: UpdateChallengeLanguagesOperationRequest = {
      id: id,
      request: request,
    };

    ChallengeApi.updateChallengeLanguages(req)
      .then(
        () => {
          console.log("Challenge languages has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getChallenge();
      });
  };

  function moveChallenge(organizationID: string) {
    if (!id) {
      return;
    }

    const req: MoveChallengeToCategoryRequest = {
      id: id,
      pid: organizationID,
    };

    MoveApi.moveChallengeToCategory(req)
      .then(
        () => {
          console.log("Move challenge was successful.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        navigate(`/challenges`);
      });
  }
  function copyChallenge(organizationID: string) {
    if (!id) {
      return;
    }

    const req: CopyChallengeToCategoryRequest = {
      id: id,
      pid: organizationID,
    };
    setLoading(true);
    CopyApi.copyChallengeToCategory(req)
      .then(
        () => {
          console.log("Copy challenge was successful.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
        navigate(`/challenges`);
      });
  }

  return (
    <>
      {error && (
        <ErrorState refresh={getChallenge} error={error} setError={setError} />
      )}
      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row className="mb-3">
                  <Col
                    className="col-2 justify-content-around"
                    style={{ textAlign: "center" }}
                  >
                    <ImageWithPlaceholder
                      src={data.coverThumbnailUrl}
                      alt={data.title ? data.title[language] : undefined}
                      className="img-thumbnail"
                      shouldOpenNewTab={true}
                      style={styles.coverImage}
                    />
                    <Row className="mt-2">
                      <Col>
                        <ButtonWithModal
                          buttonTitle="Upload cover"
                          modalTitle={`Upload new image for challenge ${data.title[language]}`}
                          agreeCallback={() => {
                            uploadChallengeCover();
                          }}
                          cancelCallback={() => {}}
                        >
                          <input
                            type="file"
                            accept="image/jpeg"
                            onChange={(e) => {
                              if (
                                e.target &&
                                e.target.files &&
                                e.target.files?.length > 0
                              ) {
                                setFile(e.target.files[0]);
                              } else {
                                setFile(null);
                              }
                            }}
                          ></input>
                        </ButtonWithModal>
                      </Col>
                    </Row>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <Row>
                      <h1
                        className="mx-auto mb-1 text-center"
                        style={styles.pageTitle}
                      >
                        {data.title[language]}
                      </h1>
                    </Row>
                    <Row>
                      <h2
                        className="mx-auto mb-1 text-center"
                        style={styles.pageTitle}
                      >
                        {id}
                      </h2>
                    </Row>
                    <ButtonWithStyle
                      buttonTitle="go to submissions"
                      variant="primary"
                      onClickCallback={() => {
                        navigate(`${"/challenges/submissions"}/${id}`);
                      }}
                    />
                  </Col>
                  <Col
                    className="col-2 justify-content-around"
                    style={{ textAlign: "center" }}
                  >
                    <ImageWithPlaceholder
                      src={data.introUrl}
                      alt={data.title ? data.title[language] : undefined}
                      className="img-thumbnail"
                      shouldOpenNewTab={true}
                      style={styles.coverImage}
                    />
                    <Row className="mt-2">
                      <Col>
                        <ButtonWithModal
                          buttonTitle="Upload intro"
                          modalTitle={`Upload new intro for challenge ${data.title[language]}`}
                          agreeCallback={() => {
                            updateChallengeIntro();
                          }}
                          cancelCallback={() => {}}
                        >
                          <input
                            type="file"
                            accept="image/gif"
                            onChange={(e) => {
                              if (
                                e.target &&
                                e.target.files &&
                                e.target.files?.length > 0
                              ) {
                                setFile(e.target.files[0]);
                              } else {
                                setFile(null);
                              }
                            }}
                          ></input>
                        </ButtonWithModal>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <LanguageComponent
                    supportedLanguages={data.supportedLanguages}
                    publishableLanguages={data.publishableLanguages}
                    systemLanguages={data.systemLanguages}
                    updateLanguages={updateChallengeLanguages}
                  />
                </Row>
                <Row>
                  <LiveValidationComponent validation={data.validation} />
                </Row>
                <Row>
                  <Col>
                    <h2 style={styles.secondaryText}>Details:</h2>
                  </Col>
                  <Col className="mb-3 text-end">
                    <ButtonWithStyle
                      buttonTitle={
                        data.publishable
                          ? "Revoke Publishable"
                          : "Make Publishable"
                      }
                      onClickCallback={() =>
                        setChallengePublishableState(!data.publishable)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EvolvedTable
                      data={multiLangData}
                      ignoreID
                      customHeaders={[
                        "language",
                        "title",
                        "description",
                        "pdf",
                      ]}
                      saveAction={(editedData: object) => {
                        updateChallenge(editedData);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <h2 style={styles.secondaryText}>Form:</h2>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {
                      <EvolvedTable
                        data={multiLangFormData}
                        ignoreID
                        customHeaders={["title", "description"]}
                        basePath="/challenges/form"
                      />
                    }
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    gap: 32,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <div>
                    <MoveRequestModal
                      buttonTitle={`Move "${data.title[language]}"`}
                      modalTitle={`Move "${data.title[language]}" challenge category.`}
                      agreeCallback={moveChallenge}
                    />
                  </div>
                  <div>
                    <CopyRequestModal
                      buttonTitle={`Copy "${data.title[language]}"`}
                      modalTitle={`Copy "${data.title[language]}" challenge category.`}
                      agreeCallback={copyChallenge}
                    />
                  </div>
                  <div>
                    <DeleteModal
                      icon={<DeleteIcon />}
                      buttonTitle={`Delete "${data.title[language]}"`}
                      modalTitle={`Delete "${data.title[language]}" challenge.`}
                      agreeCallback={() => deleteChallenge()}
                    />
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
