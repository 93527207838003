import {
  CreateSkillStepRequest,
  CreateSkillStepOperationRequest,
  CreateSkillStepResponse,
  UpdateSkillStepImageRequest,
} from "@revolutionrobotics/admin-api";
import React, { useState, useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { SkillApi } from "../../services/configuration";
import { SetError } from "../../services/general";
import { ButtonWithStyle } from "../ButtonWithStyle";
import { LanguageSelector } from "../Language/LanguageSelector";
import { FormItem } from "../FormItem";
import LanguageContext from "../../services/LanguageContext";

interface HeroFormProps {
  insertAfterId?: string;
  setError: Function;
  refresh: Function;
  setLoading: Function;
  skillId: string | undefined;
}

export function NewSkillStepForm({
  insertAfterId,
  setError,
  refresh,
  setLoading,
  skillId,
}: HeroFormProps) {
  const [validated, setValidated] = useState(false);
  const [inputs, setInputs] = useState<any | null>(null);
  const [file, setFile] = useState<Array<File> | null>(null);
  const { language } = useContext(LanguageContext);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    createNewSkillStep(insertAfterId);
  };

  const createNewSkillStep = (
    insertAfterId?: string,
    shouldCreateWithEmptyText?: boolean
  ) => {
    console.log("createNewSkillStep()");

    if (!inputs || !inputs.title || !skillId) {
      return;
    }
    setLoading(true);

    const details: CreateSkillStepRequest = {
      explanation: shouldCreateWithEmptyText ? "-" : inputs.explanation,
      lang: language,
      title: shouldCreateWithEmptyText ? "-" : inputs.title,
      skillId: skillId,
    };

    const req: CreateSkillStepOperationRequest = {
      request: details,
      insertAfterID: insertAfterId || undefined,
    };

    SkillApi.createSkillStep(req)
      .then(
        (response: CreateSkillStepResponse) => {
          console.log(response.id, "has been created");
          updateImage(response.id);
        },
        (error) => {
          setLoading(false);
          SetError(error, setError);
        }
      )
      .finally(() => {});
  };

  const updateImage = (id: string) => {
    console.log("uploadSkillStepImage()", file?.[0]?.name, id);

    if (!file || !file[0] || !id) {
      refresh();
      return;
    }

    const req: UpdateSkillStepImageRequest = {
      id: id,
      file: file[0],
    };

    SkillApi.updateSkillStepImage(req)
      .then(
        () => {
          console.log(id, "step image has been updated.");
        },
        (error) => {
          setLoading(false);
          SetError(error, setError);
        }
      )
      .finally(() => {
        if (file.length > 1) {
          setFile(file.splice(0, 1));
          createNewSkillStep(undefined, true);
        } else {
          setLoading(false);
          setFile(null);
          refresh();
        }
      });
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((values: any) => ({ ...values, [name]: value }));
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Row className="mb-3">
          <Form.Label>Language</Form.Label>
          <LanguageSelector />
        </Row>
        <Row>
          <FormItem
            title={"Title"}
            type={"text"}
            required={true}
            name={"title"}
            handleChange={handleChange}
          />
        </Row>
        <Row className="mt-2">
          <FormItem
            title={"Explanation"}
            type={"text"}
            name={"explanation"}
            handleChange={handleChange}
            isTextArea={true}
          />
        </Row>
        <Row className="mt-2 mb-3">
          <Col>
            <Form.Label>
              Cover
              <span style={{ color: "red" }}> *</span>
            </Form.Label>
            <Row>
              <input
                type="file"
                accept="image/png"
                multiple
                onChange={(e) => {
                  if (
                    e.target &&
                    e.target.files &&
                    e.target.files?.length > 0
                  ) {
                    setFile(Array.from(e.target.files));
                  } else {
                    setFile(null);
                  }
                }}
              ></input>
            </Row>
          </Col>
        </Row>
      </Row>

      <ButtonWithStyle buttonTitle="Create" type="submit" variant="primary" />
    </Form>
  );
}
