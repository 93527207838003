import {
  AddAdminToOrganizationOperationRequest,
  AddAdminToOrganizationRequest,
} from "@revolutionrobotics/admin-api";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { OrganizationApi } from "../../services/configuration";
import { SetError } from "../../services/general";
import { ButtonWithStyle } from "../ButtonWithStyle";
import { FormItem } from "../FormItem";

interface AddAdminFormProps {
  setLoading: Function;
  setError: Function;
  refresh: Function;
  id?: string;
}

export function AddAdminForm({
  setLoading,
  setError,
  refresh,
  id,
}: AddAdminFormProps) {
  const [validated, setValidated] = useState(false);
  const [inputs, setInputs] = useState<any | null>(null);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    addAdminToOrganization();
  };

  const addAdminToOrganization = () => {
    console.log("addAdmin()");

    if (!id || !inputs.email) {
      return;
    }
    setLoading(true);

    const details: AddAdminToOrganizationRequest = {
      email: inputs.email,
    };

    const req: AddAdminToOrganizationOperationRequest = {
      request: details,
      id: id,
    };

    OrganizationApi.addAdminToOrganization(req)
      .then(
        () => {
          console.log("admin has been add to organization.");
        },
        (error) => {
          setLoading(false);
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values: any) => ({ ...values, [name]: value }));
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col>
          <Row className="mt-2">
            <FormItem
              title={"Email"}
              type={"text"}
              required={true}
              name={"email"}
              handleChange={handleChange}
            />
          </Row>
        </Col>
      </Row>

      <ButtonWithStyle buttonTitle="Create" type="submit" variant="primary" />
    </Form>
  );
}
