import React from "react";
import { styles } from "../../styles/styles";
import { AuthenticationApi } from "../../services/configuration";
import { ErrorResponse } from "../../services/general";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";

export function LogoutComponent() {
  const navigate = useNavigate();

  const logoutAdmin = () => {
    AuthenticationApi.logoutAdmin()
      .then(
        () => {},
        (error: ErrorResponse) => {
          console.log(error);
        }
      )
      .finally(() => {
        navigate("/login");
      });
  };

  return (
    <Row
      className="align-items-center"
      style={styles.logoutText}
      onClick={logoutAdmin}
    >
      <Col>Log out</Col>
    </Row>
  );
}
