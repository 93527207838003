import {
  CreateSkillCategoryOperationRequest,
  CreateSkillCategoryRequest,
  CreateSkillCategoryResponse,
  UpdateSkillCategoryCoverRequest,
} from "@revolutionrobotics/admin-api";
import React, { useState, useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { SkillApi } from "../../services/configuration";
import { SetError } from "../../services/general";
import { ButtonWithStyle } from "../ButtonWithStyle";
import { LanguageSelector } from "../Language/LanguageSelector";
import { FormItem } from "../FormItem";
import LanguageContext from "../../services/LanguageContext";

interface SkillCategoryFormProps {
  insertAfterId?: string;
  setError: Function;
  refresh: Function;
}

export function NewSkillCategoryForm({
  insertAfterId,
  setError,
  refresh,
}: SkillCategoryFormProps) {
  const [validated, setValidated] = useState(false);
  const [inputs, setInputs] = useState<any | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const { language } = useContext(LanguageContext);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    createSkillCategory();
  };
  const createSkillCategory = () => {
    if (!inputs || !inputs.title || !inputs.subtitle || !inputs.file) return;

    const details: CreateSkillCategoryRequest = {
      lang: language,
      title: inputs.title,
      subtitle: inputs.subtitle,
    };

    const req: CreateSkillCategoryOperationRequest = {
      request: details,
      insertAfterID: insertAfterId || undefined,
    };

    SkillApi.createSkillCategory(req)
      .then(
        (response: CreateSkillCategoryResponse) => {
          console.log(response.id, "has been created.");
          uploadSkillCategoryImage(response.id);
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {});
  };

  const uploadSkillCategoryImage = (id: string) => {
    console.log("uploadSkillCategoryImage()", file?.name);

    if (!file || !id) {
      return;
    }

    const req: UpdateSkillCategoryCoverRequest = {
      id: id,
      file: file,
    };

    SkillApi.updateSkillCategoryCover(req)
      .then(
        (result) => {
          console.log(result, id, "cover has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setFile(null);
        refresh();
      });
  };

  const handleFileUpload = (event: any) => {
    if (event.target && event.target.files && event.target.files?.length > 0) {
      setFile(event.target.files[0]);
    } else {
      setFile(null);
    }
  };

  const handleChange = (event: any) => {
    if (event.target.name === "file") {
      handleFileUpload(event);
    }
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values: any) => ({ ...values, [name]: value }));
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col>
          <Row className="mb-3">
            <Form.Label>Language</Form.Label>
            <LanguageSelector />
          </Row>
          <Row>
            <FormItem
              title={"Title"}
              type={"text"}
              required={true}
              name={"title"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"Subtitle"}
              type={"text"}
              required={true}
              name={"subtitle"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"Cover"}
              type={"file"}
              required={true}
              name={"file"}
              handleChange={handleChange}
              fileAccept="image/jpeg"
            />
          </Row>
        </Col>
      </Row>

      <ButtonWithStyle buttonTitle="Create" type="submit" variant="primary" />
    </Form>
  );
}
