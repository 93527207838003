import {
  GetChallengeFormItemRequest,
  GetChallengeFormItemResponse,
  UpdateChallengeFormItemRequest,
  UpdateChallengeFormItemOperationRequest,
  UpdateChallengeFormItemAchievementRequest,
  UpdateChallengeFormItemAchievementOperationRequest,
} from "@revolutionrobotics/admin-api";
import React, { useContext, useEffect, useState } from "react";
import { ErrorResponse, SetError } from "../../services/general";
import { Col, Container, Row } from "react-bootstrap";
import LanguageContext from "../../services/LanguageContext";
import { useParams } from "react-router-dom";
import { ChallengeApi } from "../../services/configuration";
import { EvolvedTable } from "../../components/Table";
import { styles } from "../../styles/styles";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { LiveValidationComponent } from "../../components/LiveValidationComponent";
import { ErrorState } from "../../components/ErrorState";

export type Params = {
  id: string;
};

interface MultiLangData {
  id: string;
  language: string;
  title: string;
  description: string;
  suffix: string;
}
interface AchievementData {
  id: string;
  achievementGold: string;
  achievementSilver: string;
  achievementSteel: string;
  achievementBronze: string;
  achievementTitanium: string;
  typeId: string;
  availableTypeIds: string[];
}

export function ChallengeFormDetails() {
  const { id } = useParams<Params>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [data, setData] = useState({} as GetChallengeFormItemResponse);
  const [multiLangData, setMultiLangData] = useState([] as MultiLangData[]);
  const [achievementData, setAchievementData] = useState(
    [] as AchievementData[]
  );
  const { language, setSystemLanguages } = useContext(LanguageContext);

  const getChallengeForm = () => {
    console.log("getChallengeForm()");
    if (!id) {
      return;
    }
    setLoading(true);

    const request: GetChallengeFormItemRequest = {
      id: id,
    };

    ChallengeApi.getChallengeFormItem(request)
      .then(
        (result: GetChallengeFormItemResponse) => {
          setData(result);
          setSystemLanguages(result.systemLanguages);
          console.log(result);

          let LangData: MultiLangData[] = [];

          result.supportedLanguages.forEach((language) => {
            LangData.push({
              id: id,
              language: language.id,
              title: result.title[language.id],
              description: result.description[language.id],
              suffix: result.suffix[language.id],
            });
          });

          setMultiLangData(LangData);

          let achievement: AchievementData[] = [];
          achievement.push({
            id: id,
            achievementGold: result.achievementGold,
            achievementSilver: result.achievementSilver,
            achievementSteel: result.achievementSteel,
            achievementBronze: result.achievementBronze,
            achievementTitanium: result.achievementTitanium,
            typeId: result.typeId,
            availableTypeIds: result.availableTypeIds,
          });
          setAchievementData(achievement);
          console.log(result);
        },
        (error: ErrorResponse) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const updateChallengeForm = (data: any) => {
    console.log("updateChallengeForm()");
    if (!id) {
      return;
    }
    let details: UpdateChallengeFormItemRequest = {
      lang: data["language"],
      title: data["title"],
      description: data["description"],
      suffix: data["suffix"],
    };

    let request: UpdateChallengeFormItemOperationRequest = {
      id: id,
      request: details,
    };

    ChallengeApi.updateChallengeFormItem(request)
      .then(
        (result) => {
          console.log(result, id, "form has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getChallengeForm();
      });
  };

  const updateChallengeFormAchievement = (data: any) => {
    console.log("updateForm()");
    if (!id) {
      return;
    }
    console.log(data);
    let details: UpdateChallengeFormItemAchievementRequest = {
      achievementGold: data["achievementGold"],
      achievementSilver: data["achievementSilver"],
      achievementSteel: data["achievementSteel"],
      achievementBronze: data["achievementBronze"],
      achievementTitanium: data["achievementTitanium"],
      typeId: data["typeId"],
    };

    let request: UpdateChallengeFormItemAchievementOperationRequest = {
      id: id,
      request: details,
    };

    ChallengeApi.updateChallengeFormItemAchievement(request)
      .then(
        (result) => {
          console.log(result, id, "form has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getChallengeForm();
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("ChallengeFormDetails did mount.", id);
    getChallengeForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, []);

  return (
    <>
      {error && (
        <ErrorState
          refresh={getChallengeForm}
          error={error}
          setError={setError}
        />
      )}

      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Col>
                  <h1
                    className="mx-auto mb-1 text-center"
                    style={styles.pageTitle}
                  >
                    {data.title[language]}
                  </h1>
                </Col>
                <Row>
                  <LiveValidationComponent validation={data.validation} />
                </Row>
                <Col>
                  <h2 style={styles.secondaryText}>Details:</h2>
                </Col>
                <EvolvedTable
                  data={multiLangData}
                  ignoreID
                  customHeaders={["language", "title", "description", "suffix"]}
                  saveAction={(editedData: object) => {
                    updateChallengeForm(editedData);
                  }}
                />
                <Col>
                  <h2 style={styles.secondaryText}>Achievement:</h2>
                </Col>
                <EvolvedTable
                  data={achievementData}
                  ignoreID
                  customHeaders={[
                    "achievementSteel",
                    "achievementBronze",
                    "achievementSilver",
                    "achievementGold",
                    "achievementTitanium",
                    "typeId",
                  ]}
                  customHeadersText={[
                    "ACHIEVEMENT STEEL",
                    "ACHIEVEMENT BRONZE",
                    "ACHIEVEMENT SILVER",
                    "ACHIEVEMENT GOLD",
                    "ACHIEVEMENT TITANIUM",
                    "TYPE",
                  ]}
                  saveAction={(editedData: object) => {
                    updateChallengeFormAchievement(editedData);
                  }}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
