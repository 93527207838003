import {
  GetChallengeCategoryRequest,
  GetChallengeCategoryResponse,
  UpdateChallengeCategoryPublishableRequest,
  DeleteChallengeCategoryRequest,
  UpdateChallengeCategoryRequest,
  UpdateChallengeCategoryOperationRequest,
  UpdateChallengeCategoryChallengeOrderOperationRequest,
  UpdateChallengeCategoryChallengeOrderRequest,
  UpdateChallengeCategoryLanguagesOperationRequest,
  UpdateChallengeCategoryLanguagesRequest,
  MoveChallengeCategoryToOrganizationRequest,
  CopyChallengeCategoryToOrganizationRequest,
} from "@revolutionrobotics/admin-api";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { EvolvedTable } from "../../components/Table";
import { ChallengeApi, MoveApi, CopyApi } from "../../services/configuration";
import LanguageContext from "../../services/LanguageContext";
import { ErrorResponse, SetError } from "../../services/general";
import { ButtonWithStyle } from "../../components/ButtonWithStyle";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { DeleteModal } from "../../components/DeleteModal";
import { useNavigate } from "react-router";
import { NewChallengeForm } from "../../components/Challenge/NewChallengeForm";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { CarouselModal } from "../../components/CarouselModal";
import { LanguageComponent } from "../../components/Language/LanguageComponent";
import { LiveValidationComponent } from "../../components/LiveValidationComponent";
import { styles } from "../../styles/styles";
import { ErrorState } from "../../components/ErrorState";
import { MoveRequestModal } from "../../components/MoveRequestModal";
import { CopyRequestModal } from "../../components/CopyRequestModal";

export type Params = {
  id: string;
};

interface MultiLangData {
  id: string;
  language: string;
  title: string;
}

export function ChallengeCategoryDetails() {
  const { id } = useParams<Params>();

  const [data, setData] = useState({} as GetChallengeCategoryResponse);
  const [multiLangData, setMultiLangData] = useState([] as MultiLangData[]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [showCarousel, setShowCarousel] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const { language, setSystemLanguages } = useContext(LanguageContext);
  const navigate = useNavigate();

  const getChallengeCategoryDetails = () => {
    console.log("getChallengeCategoryDetails()");
    if (!id) {
      return;
    }
    setLoading(true);

    const request: GetChallengeCategoryRequest = {
      id: id,
    };

    ChallengeApi.getChallengeCategory(request)
      .then(
        (result: GetChallengeCategoryResponse) => {
          setData(result);
          setSystemLanguages(result.systemLanguages);
          console.log(result);
          let multiLang: MultiLangData[] = [];
          result.supportedLanguages.forEach((language) => {
            multiLang.push({
              id: id,
              language: language.id,
              title: result.title[language.id],
            });
          });

          setMultiLangData(multiLang);
        },
        (error: ErrorResponse) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const updateChallengeCategoryDetails = (data: any) => {
    console.log("updateChallengeCategoryDetails()");
    if (!id) {
      return;
    }
    let details: UpdateChallengeCategoryRequest = {
      title: data["title"],
      lang: data["language"],
    };

    let request: UpdateChallengeCategoryOperationRequest = {
      id: id,
      request: details,
    };

    ChallengeApi.updateChallengeCategory(request)
      .then(
        (result) => {
          console.log(result, id, "details has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getChallengeCategoryDetails();
      });
  };

  const setPublishableState = (state: boolean) => {
    console.log("setPublishableState()", state);

    if (!id) {
      return;
    }

    const req: UpdateChallengeCategoryPublishableRequest = {
      publishable: state,
      id: id,
    };

    ChallengeApi.updateChallengeCategoryPublishable(req)
      .then(
        (result) => {
          console.log(result, id, "publishable state has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getChallengeCategoryDetails();
      });
  };

  const deleteChallengeCategory = () => {
    console.log("deleteChallengeCategory()");

    if (!id) {
      return;
    }

    const req: DeleteChallengeCategoryRequest = {
      id: id,
    };

    ChallengeApi.deleteChallengeCategory(req)
      .then(
        (result) => {
          console.log(result, id, " has been deleted.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        navigate(`/challenges`);
      });
  };

  const updateChallengeCategoryChallengeOrder = (ids: string[]) => {
    console.log("updateChallengeCategoryChallengeOrder()");

    if (!id) {
      return;
    }

    const request: UpdateChallengeCategoryChallengeOrderRequest = {
      ids: ids,
    };

    const req: UpdateChallengeCategoryChallengeOrderOperationRequest = {
      id: id,
      request: request,
    };

    ChallengeApi.updateChallengeCategoryChallengeOrder(req)
      .then(
        () => {
          console.log("Challenge category challenges order has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getChallengeCategoryDetails();
      });
  };
  const updateChallengeCategoryLanguages = (data: any) => {
    console.log("updateSkillCategoryLanguages()");

    if (!id || !data.publishableLanguages || !data.supportedLanguages) {
      return;
    }

    const request: UpdateChallengeCategoryLanguagesRequest = {
      publishableLanguages: data.publishableLanguages,
      supportedLanguages: data.supportedLanguages,
    };

    const req: UpdateChallengeCategoryLanguagesOperationRequest = {
      id: id,
      request: request,
    };

    ChallengeApi.updateChallengeCategoryLanguages(req)
      .then(
        () => {
          console.log("Challenge Category languages has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getChallengeCategoryDetails();
      });
  };

  function moveChallengeCategory(organizationID: string) {
    if (!id) {
      return;
    }

    const req: MoveChallengeCategoryToOrganizationRequest = {
      id: id,
      pid: organizationID,
    };

    MoveApi.moveChallengeCategoryToOrganization(req)
      .then(
        () => {
          console.log("Challenge Category languages has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        navigate(`/challenges`);
      });
  }
  function copyChallengeCategory(organizationID: string) {
    if (!id) {
      return;
    }

    const req: CopyChallengeCategoryToOrganizationRequest = {
      id: id,
      pid: organizationID,
    };
    setLoading(true);
    CopyApi.copyChallengeCategoryToOrganization(req)
      .then(
        () => {
          console.log("Challenge Category languages has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
        navigate(`/challenges`);
      });
  }

  const setCarouselVisibility = (value: boolean, index: any) => {
    if (index !== null || index !== undefined) {
      setCarouselIndex(index);
    }
    setShowCarousel(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("ChallengeCategoryDetails did mount.", id);
    getChallengeCategoryDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, []);

  return (
    <>
      {error && (
        <ErrorState
          refresh={getChallengeCategoryDetails}
          error={error}
          setError={setError}
        />
      )}
      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row className="mb-3 align-items-center">
                  <Col>
                    <Row>
                      <h1
                        className="mx-auto mb-1 text-center"
                        style={styles.pageTitle}
                      >
                        {data.title[language]}
                      </h1>
                    </Row>
                    <Row>
                      <h2
                        className="mx-auto mb-1 text-center"
                        style={styles.pageTitle}
                      >
                        {id}
                      </h2>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <LanguageComponent
                    supportedLanguages={data.supportedLanguages}
                    publishableLanguages={data.publishableLanguages}
                    systemLanguages={data.systemLanguages}
                    updateLanguages={updateChallengeCategoryLanguages}
                  />
                </Row>
                <Row>
                  <LiveValidationComponent validation={data.validation} />
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Row className=" align-items-center">
                      <Col className="col-9">
                        <h2 style={styles.secondaryText}>Details:</h2>
                      </Col>
                      <Col className="text-end">
                        <ButtonWithStyle
                          buttonTitle={
                            data.publishable ? "Revoke Live" : "Make Live"
                          }
                          variant="secondary"
                          onClickCallback={() => {
                            setPublishableState(!data.publishable);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <EvolvedTable
                          data={multiLangData}
                          ignoreID
                          saveAction={(editedData: object) => {
                            updateChallengeCategoryDetails(editedData);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3 align-items-center">
                      <Col className="col-9">
                        <h2 style={styles.secondaryText}>Challenges:</h2>
                      </Col>
                      <Col className="text-end">
                        <ButtonWithModal
                          buttonTitle={"Add new"}
                          modalTitle={"Add new Challenge"}
                          isModalWithForm={true}
                        >
                          <NewChallengeForm
                            categoryId={id!}
                            setError={setError}
                            refresh={getChallengeCategoryDetails}
                          />
                        </ButtonWithModal>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <EvolvedTable
                          data={data.challenges.rows}
                          customHeaders={[
                            "coverThumbnailUrl",
                            "title",
                            "description",
                            "publishable",
                            "live",
                          ]}
                          customHeadersText={[
                            "CHALLENGE THUMBNAIL",
                            "TITLE",
                            "DESCRIPTION",
                            "PUBLISHABLE",
                            "LIVE",
                          ]}
                          updateDataOrder={
                            updateChallengeCategoryChallengeOrder
                          }
                          basePath="/challenges/challenge"
                          onClickImageCallback={setCarouselVisibility}
                          insertAfterForm={
                            <NewChallengeForm
                              categoryId={id!}
                              setError={setError}
                              refresh={getChallengeCategoryDetails}
                            />
                          }
                        />
                        <CarouselModal
                          data={data.challenges.rows}
                          path={"coverUrl"}
                          index={carouselIndex}
                          showCarousel={showCarousel}
                          setIndex={setCarouselIndex}
                          setCarouselVisibility={setCarouselVisibility}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    gap: 32,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <div>
                    <MoveRequestModal
                      buttonTitle={`Move "${data.title[language]}" (organization)`}
                      modalTitle={`Move "${data.title[language]}" challenge category to another organization.`}
                      agreeCallback={moveChallengeCategory}
                    />
                  </div>
                  <div>
                    <CopyRequestModal
                      buttonTitle={`Copy "${data.title[language]}" (organization)`}
                      modalTitle={`Copy "${data.title[language]}" challenge category to another organization.`}
                      agreeCallback={copyChallengeCategory}
                    />
                  </div>
                  <div>
                    <DeleteModal
                      buttonTitle={`Delete "${data.title[language]}"`}
                      modalTitle={`Delete "${data.title[language]}" challenge category.`}
                      agreeCallback={() => {
                        deleteChallengeCategory();
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
