import {
  DeleteLessonRequest,
  GetLessonRequest,
  GetLessonResponse,
  GetLessonSkillsRow,
  RemoveChallengeFromLessonRequest,
  RemoveHeroRobotFromLessonRequest,
  RemoveSkillFromLessonRequest,
  UpdateLessonOperationRequest,
  UpdateLessonRequest,
} from "@revolutionrobotics/admin-api";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ErrorState } from "../../components/ErrorState";
import { EvolvedTable } from "../../components/Table";
import { LessonApi } from "../../services/configuration";
import { ErrorResponse, SetError } from "../../services/general";
import { styles } from "../../styles/styles";

import { ButtonWithModal } from "../../components/ButtonWithModal";
import { AddChallengeToLesson } from "../../components/Lessons/AddChallengeToLesson";
import { AddRobotToLesson } from "../../components/Lessons/AddRobotToLesson";
import { AddSkillToLesson } from "../../components/Lessons/AddSkillToLesson";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { DeleteModal } from "../../components/DeleteModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router";

export type Params = {
  id: string;
};

export function LessonDetails() {
  const { id } = useParams<Params>();
  const navigate = useNavigate();

  const [data, setData] = useState({} as GetLessonResponse);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("LessonDetails did mount.", id);
    getLessonDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, []);

  const getLessonDetails = () => {
    console.log("getLessonDetails()");
    if (!id) {
      return;
    }

    setLoading(true);

    const req: GetLessonRequest = {
      id: id,
    };

    LessonApi.getLesson(req)
      .then(
        (result: GetLessonResponse) => {
          setData(result);
          console.log(result);
        },
        (error: ErrorResponse) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const updateLesson = (data: any) => {
    console.log("updateSkill()");

    if (!id) {
      return;
    }

    const request: UpdateLessonRequest = {
      details: data.details,
      name: data.name,
    };

    const req: UpdateLessonOperationRequest = {
      id: id,
      request: request,
    };

    LessonApi.updateLesson(req)
      .then(
        () => {
          console.log("Lesson has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getLessonDetails();
      });
  };

  const deleteLesson = () => {
    console.log("deleteLesson()");

    if (!id) {
      return;
    }

    const req: DeleteLessonRequest = {
      id: id,
    };

    LessonApi.deleteLesson(req)
      .then(
        (result) => {
          console.log(result, id, " has been deleted.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        navigate(`/lessons`);
      });
  };

  const removeSkillFromLesson = (skillId: string) => {
    if (!id) {
      return;
    }
    const req: RemoveSkillFromLessonRequest = {
      id: id,
      sid: skillId,
    };

    LessonApi.removeSkillFromLesson(req)
      .then(
        () => {
          console.log(skillId, " skill has been removed.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getLessonDetails();
      });
  };
  const deleteRobot = (robotId: string) => {
    if (!id) {
      return;
    }
    const req: RemoveHeroRobotFromLessonRequest = {
      id: id,
      hrid: robotId,
    };

    LessonApi.removeHeroRobotFromLesson(req)
      .then(
        () => {
          console.log(robotId, " robot has been removed.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getLessonDetails();
      });
  };

  const deleteChallenge = (challengeId: string) => {
    if (!id) {
      return;
    }
    const req: RemoveChallengeFromLessonRequest = {
      id: id,
      cid: challengeId,
    };

    LessonApi.removeChallengeFromLesson(req)
      .then(
        () => {
          console.log(challengeId, " challenge has been removed.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getLessonDetails();
      });
  };

  return (
    <>
      {error && (
        <ErrorState
          refresh={getLessonDetails}
          error={error}
          setError={setError}
        />
      )}
      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row className="mb-3">
                  <Col style={{ textAlign: "center" }}>
                    <Row>
                      <h1
                        className="mx-auto mb-1 text-center"
                        style={styles.pageTitle}
                      >
                        Lesson Details
                      </h1>
                    </Row>
                    <Row>
                      <h2
                        className="mx-auto mb-1 text-center"
                        style={styles.secondaryText}
                      >
                        {data.name}
                      </h2>
                    </Row>
                    <Row>
                      <h3
                        className="mx-auto mb-1 text-center"
                        style={styles.pageTitle}
                      >
                        {id}
                      </h3>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2 style={styles.secondaryText}>Details:</h2>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    <EvolvedTable
                      data={[
                        { details: data.details, name: data.name, id: id },
                      ]}
                      ignoreID
                      saveAction={(editedData: object) => {
                        updateLesson(editedData);
                      }}
                    />
                  </Col>
                </Row>

                {/** Skills */}
                <Row className="mt-3 align-items-center">
                  <Col className="col-9">
                    <h2 style={styles.secondaryText}>Epics:</h2>
                  </Col>
                  <Col className="text-end">
                    <ButtonWithModal
                      buttonTitle={"Add new"}
                      modalTitle={"Add new Epic"}
                      isModalWithForm={true}
                    >
                      <AddSkillToLesson
                        id={id!}
                        setError={setError}
                        refresh={getLessonDetails}
                      />
                    </ButtonWithModal>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <EvolvedTable
                      data={data.skills.rows}
                      ignoreID
                      deleteAction={removeSkillFromLesson}
                      deleteModalTitle="Remove epic from lesson."
                      deleteModalText="You can re-add the epic later."
                      isDeletePermanent={false}
                      customOnClickAction={(skill: GetLessonSkillsRow) => {
                        navigate(`/skills/skill/${skill.id}`);
                      }}
                    />
                  </Col>
                </Row>

                {/** Robots */}
                <Row className="mt-3 align-items-center">
                  <Col className="col-9">
                    <h2 style={styles.secondaryText}>Robot:</h2>
                  </Col>
                  <Col className="text-end">
                    <ButtonWithModal
                      buttonTitle={"Add new"}
                      modalTitle={"Add new Robot"}
                      isModalWithForm={true}
                    >
                      <AddRobotToLesson
                        id={id!}
                        setError={setError}
                        refresh={getLessonDetails}
                      />
                    </ButtonWithModal>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <EvolvedTable
                      data={data.heroRobots.rows}
                      ignoreID
                      deleteAction={deleteRobot}
                      deleteModalTitle="Remove robot from lesson."
                      deleteModalText="You can re-add the robot later."
                      isDeletePermanent={false}
                      customOnClickAction={(robot: GetLessonSkillsRow) => {
                        navigate(`/robots/hero-robot/${robot.id}`);
                      }}
                    />
                  </Col>
                </Row>

                {/** Challenges */}
                <Row className="mt-3 align-items-center">
                  <Col className="col-9">
                    <h2 style={styles.secondaryText}>Challenges:</h2>
                  </Col>
                  <Col className="text-end">
                    <ButtonWithModal
                      buttonTitle={"Add new"}
                      modalTitle={"Add new Challenge"}
                      isModalWithForm={true}
                    >
                      <AddChallengeToLesson
                        id={id!}
                        setError={setError}
                        refresh={getLessonDetails}
                      />
                    </ButtonWithModal>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <EvolvedTable
                      data={data.challenges.rows}
                      ignoreID
                      deleteAction={deleteChallenge}
                      deleteModalTitle="Remove challange from lesson."
                      deleteModalText="You can re-add the challange later."
                      isDeletePermanent={false}
                      customOnClickAction={(challenge: GetLessonSkillsRow) => {
                        navigate(`/challenges/challenge/${challenge.id}`);
                      }}
                    />
                  </Col>
                </Row>

                {/* Delete button */}
                <Col className="text-end">
                  <DeleteModal
                    icon={<DeleteIcon />}
                    buttonTitle={`Delete Lesson ${data.name}`}
                    modalTitle={`Delete Lesson ${data.name}`}
                    agreeCallback={() => deleteLesson()}
                  />
                </Col>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
