import React, { useState, useEffect } from "react";
import ImageIcon from "@mui/icons-material/Image";
import { styles } from "../styles/styles";

interface ImageWithPlaceholderProps {
  src: string | undefined;
  alt?: string;
  className?: string;
  style?: Object;
  shouldOpenNewTab?: boolean;
}

export function ImageWithPlaceholder({
  src,
  alt,
  className,
  style,
  shouldOpenNewTab,
}: ImageWithPlaceholderProps) {
  const [error, setError] = useState(false);

  useEffect(() => {
    if (src !== (undefined || "")) {
      setError(false);
    }
    // eslint-disable-next-line no-sparse-arrays, react-hooks/exhaustive-deps
  }, [, src]);

  const openImageInNewTab = () => {
    window.open(src, "_blank", "noopener,noreferrer");
  };

  return !error && src !== undefined ? (
    <img
      src={src}
      alt={alt ? alt : src.substring(src.lastIndexOf("/") + 1, src.length)}
      className={className}
      draggable={false}
      style={{
        ...style,
        ...styles.image,
        cursor: shouldOpenNewTab ? "pointer" : "default",
      }}
      onClick={shouldOpenNewTab ? openImageInNewTab : () => {}}
      onError={() => {
        setError(true);
      }}
    />
  ) : (
    <div className={className} style={styles.imagePlaceholderContainer}>
      <ImageIcon
        sx={styles.imagePlaceholderBorder}
        style={{
          ...styles.imagePlaceholder,
          ...style,
        }}
      />
    </div>
  );
}
