import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  AddStudentsToUserGroupItem,
  AddStudentsToUserGroupItemActionEnum,
  AddStudentsToUserGroupOperationRequest,
  AddStudentsToUserGroupRequest,
  AddStudentsToUserGroupResponse,
} from "@revolutionrobotics/admin-api";
import React, { useState } from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { UserGroupApi } from "../../services/configuration";
import { SetError } from "../../services/general";
import { styles } from "../../styles/styles";
import { ButtonWithStyle } from "../ButtonWithStyle";
import { FormItem } from "../FormItem";

interface AddNewUserToUserGroupProps {
  id: string;
  setError: Function;
  refresh: Function;
}

export function AddNewUserToUserGroup({
  id,
  setError,
  refresh,
}: AddNewUserToUserGroupProps) {
  const [validated, setValidated] = useState(false);
  const [inputs, setInputs] = useState<AddStudentsToUserGroupItem[]>([
    {
      action: AddStudentsToUserGroupItemActionEnum.Add,
      codeName: "",
    },
  ]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (!inputs) return;

    const details: AddStudentsToUserGroupRequest = {
      items: inputs.map((item: AddStudentsToUserGroupItem) => ({
        action: item.action,
        codeName: item.codeName,
        error: item.error,
        hint: item.hint,
        note: item.note,
      })),
    };

    const req: AddStudentsToUserGroupOperationRequest = {
      id: id,
      request: details,
    };

    UserGroupApi.addStudentsToUserGroup(req).then(
      (result: AddStudentsToUserGroupResponse) => {
        // Note: there is always come back 200 OK, even if there is an error.
        // So we need to check the result.result property.
        if (result.result === "success") {
          console.log("lesson has been updated.");
          refresh();
        } else {
          // If there is error then we need to show it to the user line by line.
          // That is why we need to update the inputs array with the error messages.
          if (result.items) {
            setInputs(result.items);
          }
        }
      },
      (error) => {
        SetError(error, setError);
      }
    );
  };

  const handleChange = (event: any, index: number) => {
    const value = event.target.value;
    const name = event.target.name;

    const newInputs = [...inputs];
    newInputs[index] = { ...newInputs[index], [name]: value };
    setInputs(newInputs);
  };

  const handleDropdownChange = (
    value: AddStudentsToUserGroupItemActionEnum,
    index: number
  ) => {
    const newInputs = [...inputs];
    newInputs[index] = { ...newInputs[index], action: value };
    setInputs(newInputs);
  };

  const handleAddUser = () => {
    const newInputs = [...inputs];
    newInputs.push({
      action: AddStudentsToUserGroupItemActionEnum.Add,
      codeName: "",
    });
    setInputs(newInputs);
  };

  const handleRemoveUser = () => {
    const newInputs = [...inputs];
    newInputs.splice(-1);
    setInputs(newInputs);
  };

  const renderDropdownItems = (index: number) => {
    return Object.keys(AddStudentsToUserGroupItemActionEnum).map(
      (key: string) => (
        <Dropdown.Item
          key={key}
          onClick={() =>
            handleDropdownChange(
              AddStudentsToUserGroupItemActionEnum[
                key as keyof typeof AddStudentsToUserGroupItemActionEnum
              ],
              index
            )
          }
        >
          {
            AddStudentsToUserGroupItemActionEnum[
              key as keyof typeof AddStudentsToUserGroupItemActionEnum
            ]
          }
        </Dropdown.Item>
      )
    );
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {inputs.map((input, index) => (
        <Row className="mb-3" key={index} style={{ marginBottom: 24 }}>
          <Col style={{ flex: 1 }}>
            <Row>
              <Form.Label>
                Action type:
                <span style={styles.requiredText}>{" *"}</span>
              </Form.Label>
              <Dropdown>
                <Dropdown.Toggle
                  style={styles.tableDropDown}
                  variant="warning"
                  id="dropdown-basic"
                >
                  {input.action}
                </Dropdown.Toggle>
                <Dropdown.Menu>{renderDropdownItems(index)}</Dropdown.Menu>
              </Dropdown>
            </Row>
          </Col>
          <Col style={{ flex: 2 }}>
            <Row>
              <FormItem
                title={"Code name"}
                type={"text"}
                required={true}
                name={"codeName"}
                handleChange={(event: any) => handleChange(event, index)}
              />
            </Row>
          </Col>
          <Col style={{ flex: 2 }}>
            <Row>
              <FormItem
                title={"Note"}
                type={"text"}
                name={"note"}
                handleChange={(event: any) => handleChange(event, index)}
              />
            </Row>
          </Col>

          {input.error && (
            <>
              <div style={styles.errorText}>{input.error}:</div>
              <div style={styles.errorText}>{input.hint}</div>
            </>
          )}
          {
            <div>
              <div style={styles.addUserSeparatorLine} />

              {/* show add button only for the last input */}
              {index === inputs.length - 1 && (
                <div style={styles.addUsersButtonContainer}>
                  <ButtonWithStyle
                    isActionButton
                    icon={<AddIcon />}
                    variant="primary"
                    onClickCallback={() => handleAddUser()}
                  />
                  {/** show remove button only if there is more than one input */}
                  {inputs.length > 1 && (
                    <ButtonWithStyle
                      isActionButton
                      icon={<RemoveIcon />}
                      variant="primary"
                      onClickCallback={() => handleRemoveUser()}
                    />
                  )}
                </div>
              )}
            </div>
          }
        </Row>
      ))}

      <ButtonWithStyle buttonTitle="Create" type="submit" variant="primary" />
    </Form>
  );
}
