import {
  CreateSkillOperationRequest,
  CreateSkillRequest,
  CreateSkillResponse,
} from "@revolutionrobotics/admin-api";
import React, { useState, useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { SkillApi } from "../../services/configuration";
import { SetError } from "../../services/general";
import { ButtonWithStyle } from "../ButtonWithStyle";
import { LanguageSelector } from "../Language/LanguageSelector";
import { FormItem } from "../FormItem";
import LanguageContext from "../../services/LanguageContext";

interface NewSkillFormProps {
  insertAfterId?: string;
  categoryId: string;
  setError: Function;
  refresh: Function;
}

export function NewSkillForm({
  insertAfterId,
  categoryId,
  setError,
  refresh,
}: NewSkillFormProps) {
  const [validated, setValidated] = useState(false);
  const [inputs, setInputs] = useState<any | null>(null);
  const { language } = useContext(LanguageContext);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (!inputs || !inputs.title || !inputs.subtitle) return;

    const details: CreateSkillRequest = {
      lang: language,
      title: inputs.title,
      subtitle: inputs.subtitle,
      categoryId: categoryId,
    };

    const req: CreateSkillOperationRequest = {
      request: details,
      insertAfterID: insertAfterId || undefined,
    };

    SkillApi.createSkill(req)
      .then(
        (response: CreateSkillResponse) => {
          console.log(response.id, "has been created.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        refresh();
      });
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values: any) => ({ ...values, [name]: value }));
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col>
          <Row className="mb-3">
            <Form.Label>Language</Form.Label>
            <LanguageSelector />
          </Row>
          <Row>
            <FormItem
              title={"Title"}
              type={"text"}
              required={true}
              name={"title"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"Subtitle"}
              type={"text"}
              required={true}
              name={"subtitle"}
              handleChange={handleChange}
            />
          </Row>
        </Col>
      </Row>

      <ButtonWithStyle buttonTitle="Create" type="submit" variant="primary" />
    </Form>
  );
}
