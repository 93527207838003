import {
  GetRobotSoftwaresResponse,
  UpdateRobotSoftwarePublishableRequest,
  UpdateRobotSoftwareUpdateImageRequest,
  DeleteRobotSoftwareRequest,
} from "@revolutionrobotics/admin-api";
import { RobotSoftwareApi } from "../../services/configuration";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ErrorResponse, SetError } from "../../services/general";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { EvolvedTable } from "../../components/Table";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { NewUpdateForm } from "../../components/Updates/NewUpdateForm";
import { ErrorState } from "../../components/ErrorState";
import { styles } from "../../styles/styles";

interface RobotSoftwareRow {
  compatibleHardwareRevisions: string;
  softwareRevisionMatching: string;
  id: string;
  order: number;
  publishable: boolean;
  updateUrl: string | undefined;
  version: string;
}

export function Updates() {
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [tableData, setTableData] = useState([] as RobotSoftwareRow[]);
  const [loading, setLoading] = useState(true);

  const getRobotSoftwares = () => {
    console.log("getLanguages()");
    setLoading(true);

    RobotSoftwareApi.getRobotSoftwares()
      .then(
        (result: GetRobotSoftwaresResponse) => {
          let rows: RobotSoftwareRow[] = [];

          result.avatars.rows.forEach((item) => {
            rows.push({
              compatibleHardwareRevisions:
                item.compatibleHardwareRevisions.join(", "),
              softwareRevisionMatching: item.softwareRevisionMatching,
              id: item.id,
              order: item.order,
              publishable: item.publishable,
              updateUrl: item.updateUrl ? item.updateUrl : undefined,
              version: item.version,
            });
          });

          setTableData(rows);
          console.log(result);
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteRobotSoftware = (id: string) => {
    console.log("deleteRobotSoftware() " + id);

    if (!id) {
      return;
    }

    const request: DeleteRobotSoftwareRequest = {
      id: id,
    };

    RobotSoftwareApi.deleteRobotSoftware(request)
      .then(
        () => {
          console.log(id, " step has been deleted.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getRobotSoftwares();
      });
  };

  const updateRobotSoftwarePublishable = (data: any) => {
    console.log("updateRobotSoftwarePublishable()", data.publishable);

    if (!data.id && !data.publishable) {
      setLoading(false);
      return;
    }

    const req: UpdateRobotSoftwarePublishableRequest = {
      publishable: data.publishable,
      id: data.id,
    };

    RobotSoftwareApi.updateRobotSoftwarePublishable(req)
      .then(
        () => {
          console.log(data.id, "publishable state has been updated.");
        },
        (error) => {
          SetError(error, setError);
          setLoading(false);
        }
      )
      .finally(() => {
        getRobotSoftwares();
      });
  };

  const updateRobotSoftwareUpdateImage = (data: any) => {
    console.log("updateRobotSoftwareUpdateImage()", data.publishable);

    if (!data.id || data.updateUrl === null) {
      updateRobotSoftwarePublishable(data);
      return;
    }

    const req: UpdateRobotSoftwareUpdateImageRequest = {
      file: data.updateUrl,
      id: data.id,
    };
    setLoading(true);
    RobotSoftwareApi.updateRobotSoftwareUpdateImage(req)
      .then(
        () => {
          console.log(data.id, "software image has been updated.");
        },
        (error) => {
          SetError(error, setError);
          setLoading(false);
        }
      )
      .finally(() => {
        updateRobotSoftwarePublishable(data);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Updates did mount.");
    getRobotSoftwares();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {error && (
        <ErrorState
          refresh={getRobotSoftwares}
          error={error}
          setError={setError}
        />
      )}
      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row>
                  <Col>
                    <h1
                      className="mx-auto mb-3 text-center text-uppercase"
                      style={styles.pageTitle}
                    >
                      Updates
                    </h1>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3 align-items-center">
                  <Col className="text-end">
                    <ButtonWithModal
                      buttonTitle={"Add new"}
                      modalTitle={"Add new software version"}
                      isModalWithForm={true}
                    >
                      <NewUpdateForm
                        setError={setError}
                        refresh={getRobotSoftwares}
                      />
                    </ButtonWithModal>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EvolvedTable
                      data={tableData}
                      customHeaders={[
                        "version",
                        "compatibleHardwareRevisions",
                        "softwareRevisionMatching",
                        "publishable",
                        "updateUrl",
                      ]}
                      customHeadersText={[
                        "VERSION",
                        "COMPATIBLE HARDWARE REVISIONS",
                        "SOFTWARE REVISION MATCHING",
                        "PUBLISHABLE",
                        "UPDATE URL",
                      ]}
                      saveAction={(editedData: object) => {
                        updateRobotSoftwareUpdateImage(editedData);
                      }}
                      deleteAction={deleteRobotSoftware}
                      editableHeaders={["updateUrl", "publishable"]}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
