import {
  GetHeroRobotsHeroRobotsRow,
  GetHeroRobotsResponse,
  UpdateHeroRobotOrderRequest,
  UpdateHeroRobotsOrderRequest,
} from "@revolutionrobotics/admin-api";
import { HeroApi } from "../../services/configuration";

import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ErrorResponse, SetError } from "../../services/general";
import { EvolvedTable } from "../../components/Table";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { NewHeroForm } from "../../components/Robot/NewHeroForm";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { styles } from "../../styles/styles";
import { ErrorState } from "../../components/ErrorState";
import LanguageContext from "../../services/LanguageContext";

export function Heroes() {
  const [tableData, setTableData] = useState(
    [] as GetHeroRobotsHeroRobotsRow[]
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const { setSystemLanguages } = useContext(LanguageContext);

  const getHeroes = () => {
    console.log("getHeroes()");
    setLoading(true);

    HeroApi.getHeroRobots()
      .then(
        (result: GetHeroRobotsResponse) => {
          setTableData(result.heroRobots.rows);
          setSystemLanguages(result.systemLanguages);
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const updateHeroRobotsOrder = (ids: string[]) => {
    console.log("updateHeroRobotsOrder()");

    const request: UpdateHeroRobotOrderRequest = {
      ids: ids,
    };
    const req: UpdateHeroRobotsOrderRequest = {
      request: request,
    };

    HeroApi.updateHeroRobotsOrder(req)
      .then(
        () => {
          console.log("Heroses order has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getHeroes();
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Avatars did mount.");
    getHeroes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {error && (
        <ErrorState refresh={getHeroes} error={error} setError={setError} />
      )}
      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row>
                  <Col>
                    <h1
                      className="mx-auto mb-3 text-center text-uppercase"
                      style={styles.pageTitle}
                    >
                      Hero Robots
                    </h1>
                  </Col>
                </Row>
                <Row className="mb-4  text-center" xs={"auto"}>
                  <Col>
                    <ButtonWithModal
                      buttonTitle={"Add new"}
                      modalTitle={"Add new hero robot"}
                      isModalWithForm={true}
                    >
                      <NewHeroForm setError={setError} refresh={getHeroes} />
                    </ButtonWithModal>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EvolvedTable
                      data={tableData}
                      customHeaders={[
                        "coverThumbnailUrl",
                        "name",
                        "description",
                        "hasModel",
                        "publishable",
                        "live",
                      ]}
                      customHeadersText={[
                        "ROBOT THUMBNAIL",
                        "NAME",
                        "DESCRIPTION",
                        "HAS MODEL",
                        "PUBLISHABLE",
                        "LIVE",
                      ]}
                      basePath="/robots/hero-robot"
                      updateDataOrder={updateHeroRobotsOrder}
                      imageShouldOpenNewTab={true}
                      insertAfterForm={
                        <NewHeroForm setError={setError} refresh={getHeroes} />
                      }
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
