import { ContentValidation } from "@revolutionrobotics/admin-api";
import React, { useState } from "react";
import { Collapse, Row } from "react-bootstrap";
import { styles } from "../styles/styles";
import { EvolvedTable } from "../components/Table";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

interface LiveValidationComponentProps {
  validation: ContentValidation;
}

export function LiveValidationComponent({
  validation,
}: LiveValidationComponentProps) {
  const [open, setOpen] = useState(false);

  function showCriticals(validation: ContentValidation) {
    if (validation.criticalCount > 0) {
      return <span style={{
        color: 'red'
      }}>{"🚨 " + validation.criticalCount + " critical(s) "}</span>
    } else {
      return null
    }
  }

  function showErrors(validation: ContentValidation) {
    if (validation.errorCount > 0) {
      return <span style={{
        color: 'orange'
      }}>{validation.errorCount + " error(s) "}</span>
    } else {
      return null
    }
  }

  function showWarnings(validation: ContentValidation) {
    if (validation.warningCount > 0) {
      return <span style={{
        color: 'yellow'
      }}>{validation.warningCount + " warning(s)"}</span>
    } else {
      return null
    }
  }

  const getValidationText = () => {
    console.log(validation.criticalCount);
    if (
      validation.criticalCount === 0 &&
      validation.errorCount === 0 &&
      validation.warningCount === 0
    ) {
      return (
        <h2>
          {"Live validation: "}
          
          <span style={{
            color: 'green'
          }}>{"No issues ✅"}</span>
        </h2>
      );
    } else {
      return (
        <h2>
          {"Live validation: "}
          {showCriticals(validation)}
          {showErrors(validation)}
          {showWarnings(validation)}
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </h2>
      );
    }
  };

  return (
    <div>
      <Row
        className="text-center justify-content-center"
        onClick={() => setOpen(!open)}
        style={styles.secondaryText}
      >
        {getValidationText()}
      </Row>

      <Collapse in={open}>
        <Row id="example-collapse-text">
          <EvolvedTable
            data={validation.issues}
            customHeaders={[
              "issueType",
              "language",
              "objectId",
              "objectProperty",
              "objectType",
              "severity",
            ]}
            customHeadersText={[
              "ISSUE TYPE",
              "LANGUAGE",
              "OBJECT ID",
              "OBJECT PROPERTY",
              "OBJECT TYPE",
              "SEVERITY",
            ]}
          />
        </Row>
      </Collapse>
    </div>
  );
}
