import {
  GetChallengeSubmissionsRequest,
  GetChallengeSubmissionsResponse,
  DeleteChallengeSubmissionRequest,
  UpdateChallengeSubmissionVerifiedRequest,
} from "@revolutionrobotics/admin-api";
import React, { useContext, useEffect, useState } from "react";
import { ErrorResponse, SetError } from "../../services/general";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ChallengeApi } from "../../services/configuration";
import { EvolvedTable } from "../../components/Table";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { styles } from "../../styles/styles";
import { Pagination } from "../../components/Pagination";
import { SearchBar } from "../../components/SearchBar";
import { ErrorState } from "../../components/ErrorState";
import LanguageContext from "../../services/LanguageContext";
import SearchIcon from "@mui/icons-material/Search";
import { ModelDetailsModal } from "../../components/ModelDetailsModal";

export type Params = {
  id: string;
};

export function ChallengeSubmissions() {
  const { id } = useParams<Params>();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({} as GetChallengeSubmissionsResponse);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [isFirstSearch, setFirstSearch] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataSize, setDataSize] = useState(0);
  const [show, setShow] = useState(false);
  const [model, setModel] = useState();
  const { language, setSystemLanguages } = useContext(LanguageContext);

  const PAGE_LIMIT = 7;

  const getChallengeSubmissions = () => {
    console.log("getChallengeSubmissions()");
    if (!id) {
      return;
    }

    setLoading(true);

    const req: GetChallengeSubmissionsRequest = {
      id: id,
      limit: PAGE_LIMIT,
      offset: (currentPage - 1) * PAGE_LIMIT,
      search: searchText,
    };

    ChallengeApi.getChallengeSubmissions(req)
      .then(
        (result: GetChallengeSubmissionsResponse) => {
          setDataSize(result.submissions.size);
          setData(result);
          setSystemLanguages(result.systemLanguages);
        },
        (error: ErrorResponse) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteChallengeSubmission = (id: any) => {
    console.log("getChallengeSubmissions()");
    console.log(id);
    if (!id) {
      return;
    }

    const req: DeleteChallengeSubmissionRequest = {
      id: id,
    };

    ChallengeApi.deleteChallengeSubmission(req)
      .then(
        () => {
          console.log(id, "submission has been deleted");
        },
        (error: ErrorResponse) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getChallengeSubmissions();
      });
  };

  const updateChallengeSubmissionVerified = (data: any) => {
    console.log("updateRobotSoftwarePublishable()", data.publishable);

    if (!data.id && !data.verified) {
      return;
    }
    setLoading(true);
    const req: UpdateChallengeSubmissionVerifiedRequest = {
      verified: data.verified,
      id: data.id,
    };

    ChallengeApi.updateChallengeSubmissionVerified(req)
      .then(
        () => {
          console.log(data.id, "publishable state has been updated.");
        },
        (error) => {
          SetError(error, setError);
          setLoading(false);
        }
      )
      .finally(() => {
        getChallengeSubmissions();
      });
  };

  const getSearchResult = () => {
    if (isFirstSearch) {
      setFirstSearch(false);
    }
    setCurrentPage(1);
    getChallengeSubmissions();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("ChallengeDetails did mount.", id);
    getChallengeSubmissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, [, currentPage, id]);

  function handleRowClick(row: any) {
    setModel(JSON.parse(row.model));
    setShow(true);
  }

  return (
    <>
      {error && (
        <ErrorState
          refresh={getChallengeSubmissions}
          error={error}
          setError={setError}
        />
      )}

      <Container fluid>
        <Row>
          <Col>
            <Row className="mb-3">
              <Col>
                <SearchBar
                  placeholder={"Search submission"}
                  value={searchText}
                  setTextCallback={setSearchText}
                  onClickCallback={getSearchResult}
                />
              </Col>
              <Col className="text-center">
                <h1 style={styles.pageTitle}>Submissions</h1>
              </Col>
              <Col></Col>
            </Row>
            {loading ? (
              <LoadingIndicator />
            ) : data.submissions === undefined ||
              data.submissions.rows === undefined ? (
              <div />
            ) : !isFirstSearch && data.submissions?.rows?.length === 0 ? (
              <div className="mt-5" style={styles.noResult}>
                <SearchIcon style={styles.noResultStateIcon} />
                <p>No result found.</p>
                <p>Try search different keyword.</p>
              </div>
            ) : (
              <>
                <Row>
                  <Col>
                    <EvolvedTable
                      data={data.submissions?.rows}
                      ignoreID
                      customHeaders={[
                        "userCodeName",
                        "userEmail",
                        "userId",
                        "rank",
                        "score",
                        "achievement",
                        "submissionDate",
                        "value",
                        "verified",
                        "videoUrl",
                      ]}
                      customHeadersText={[
                        "USER CODE NAME",
                        "USER E-MAIL",
                        "USER ID",
                        "RANK",
                        "SCORE",
                        "ACHIEVEMENT",
                        "SUBMISSION DATE",
                        data.formItemTitle[language] ||
                          data.formItemTitle["en"],
                        "VERIFIED",
                        "VIDEO",
                      ]}
                      deleteAction={deleteChallengeSubmission}
                      saveAction={updateChallengeSubmissionVerified}
                      editableHeaders={["verified"]}
                      customOnClickAction={handleRowClick}
                    />

                    <Pagination
                      size={dataSize}
                      pageLimit={PAGE_LIMIT}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <ModelDetailsModal
        model={model}
        modalTitle="Submission details"
        show={show}
        setShow={setShow}
      />
    </>
  );
}
