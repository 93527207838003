import {
  GetOrganizationsResponse,
  GetOrganizationsOrganizationsRow,
} from "@revolutionrobotics/admin-api";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { NewOrganizationForm } from "../../components/Organization/NewOrganizationForm";
import { EvolvedTable } from "../../components/Table";
import { OrganizationApi } from "../../services/configuration";
import { ErrorResponse, SetError } from "../../services/general";
import { ErrorState } from "../../components/ErrorState";
import { styles } from "../../styles/styles";
import { getActiveOrganization } from "../../services/getSelectedOrganization";

export function Organizations() {
  const [data, setData] = useState([] as GetOrganizationsOrganizationsRow[]);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [loading, setLoading] = useState(true);

  const getOrganizations = () => {
    console.log("getOrganizations()");
    setLoading(true);

    OrganizationApi.getOrganizations()
      .then(
        (result: GetOrganizationsResponse) => {
          setData(result.organizations.rows);
          console.log(result);
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Organisations did mount.");
    getOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAddNewOrganizationButton = useMemo(() => {
    return data.find(
      (organization) => organization.id === getActiveOrganization().id
    )?.root;
  }, [data]);

  return (
    <>
      {error && (
        <ErrorState
          refresh={getOrganizations}
          error={error}
          setError={setError}
        />
      )}
      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row>
                  <Col>
                    <h1
                      className="mx-auto mb-3 text-center text-uppercase"
                      style={styles.pageTitle}
                    >
                      Organizations
                    </h1>
                  </Col>
                </Row>
                <Row
                  className="mb-3 text-center "
                  xs={"auto"}
                  style={{ alignItems: "center" }}
                >
                  <Col>
                    {showAddNewOrganizationButton && (
                      <ButtonWithModal
                        buttonTitle={"Add new"}
                        modalTitle={"Add new organization"}
                        isModalWithForm={true}
                      >
                        <NewOrganizationForm
                          setError={setError}
                          refresh={getOrganizations}
                        />
                      </ButtonWithModal>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EvolvedTable
                      data={data}
                      basePath="organization"
                      customHeaders={["name", "kind", "publishable"]}
                      customHeadersText={["NAME", "KIND", "PUBLISHABLE"]}
                      imageShouldOpenNewTab={true}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
