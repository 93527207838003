export const menuBackgroundColor = "#1C1C1E";

export const primaryButtonColor = "#FFF03A";
export const primaryButtonTextColor = "#201E1E";
export const secondaryButtonColor = "#373F23";
export const secondaryButtonTextColor = "#FFF03A";
export const dangerButtonColor = "#D23B2F";
export const dangerButtonTextColor = "#F2F3F2";
export const buttonColor = "#322D2D";
export const buttonTextColor = "#F2F3F2";

export const pageBackgroundColor = "#0E0E0E";
export const pageTitleColor = "#FFF03A";

export const modalBackgroundColor = "#1C1B1F";

export const primaryTextColor = "#F2F3F2";
export const secondaryTextColor = "#B6B4B5";
export const thirdTextColor = "#9E9C9C";

export const tableBackgroundColor = "#201E1E";
export const tableHeaderBackgroundColor = "#373F23";
export const tableHeaderTextColor = "#FFF03A";

export const primaryIconColor = "#F2F3F2";
export const successIconColor = "#27C856";
export const failIconColor = "#D23B2F";

export const inputBackgroundColor = "#0E0E0E";
export const inputTextColor = "#F2F3F2";

export const imageBackgroundColor = "#1C1B1F";
export const imageBorderColor = "#FFF03A";

export const orderBackground = "rgba(14, 14, 14, 0.8)";
