import {
  CreateLanguageOperationRequest,
  CreateLanguageResponse,
  CreateLanguageRequest,
} from "@revolutionrobotics/admin-api";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { LanguageApi } from "../../services/configuration";
import { SetError } from "../../services/general";
import { ButtonWithStyle } from "../ButtonWithStyle";
import { FormItem } from "../FormItem";

interface NewSkillFormProps {
  setLoading: Function;
  setError: Function;
  refresh: Function;
}

export function NewLanguageForm({
  setLoading,
  setError,
  refresh,
}: NewSkillFormProps) {
  const [validated, setValidated] = useState(false);
  const [inputs, setInputs] = useState<any | null>(null);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    createNewLanguage();
  };

  const createNewLanguage = () => {
    console.log("createNewLanguage()");

    if (!inputs.id || !inputs.name) {
      return;
    }
    setLoading(true);

    const details: CreateLanguageRequest = {
      name: inputs.name,
      id: inputs.id,
    };

    const req: CreateLanguageOperationRequest = {
      request: details,
    };

    LanguageApi.createLanguage(req)
      .then(
        (result: CreateLanguageResponse) => {
          console.log(result, result.id, "has been created.");
        },
        (error) => {
          setLoading(false);
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values: any) => ({ ...values, [name]: value }));
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col>
          <p>Default language: EN</p>

          <Row>
            <FormItem
              title={"Id (isocode)"}
              type={"text"}
              required={true}
              name={"id"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"Name"}
              type={"text"}
              required={true}
              name={"name"}
              handleChange={handleChange}
            />
          </Row>
        </Col>
      </Row>

      <ButtonWithStyle buttonTitle="Create" type="submit" variant="primary" />
    </Form>
  );
}
