import {
  DeleteHeroRobotRequest,
  DeleteHeroRobotBuildStepRequest,
  GetHeroRobotRequest,
  GetHeroRobotResponse,
  UpdateHeroRobotCoverRequest,
  UpdateHeroRobotOperationRequest,
  UpdateHeroRobotPublishableRequest,
  UpdateHeroRobotRequest,
  UpdateHeroRobotBuildStepOrderOperationRequest,
  UpdateHeroRobotBuildStepOrderRequest,
  UpdateHeroRobotLanguagesOperationRequest,
  UpdateHeroRobotLanguagesRequest,
  MoveHeroRobotToOrganizationRequest,
  CopyHeroRobotToOrganizationRequest,
} from "@revolutionrobotics/admin-api";

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { HeroApi, MoveApi, CopyApi } from "../../services/configuration";
import { ErrorResponse, SetError } from "../../services/general";
import LanguageContext from "../../services/LanguageContext";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { EvolvedTable } from "../../components/Table";
import { useNavigate } from "react-router";
import { ButtonWithStyle } from "../../components/ButtonWithStyle";
import { ImageWithPlaceholder } from "../../components/ImageWithPlaceholder";
import { CarouselModal } from "../../components/CarouselModal";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { NewheroRobotBuildStepForm } from "../../components/Robot/NewheroRobotBuildStepForm";
import { NewHeroRobotModelForm } from "../../components/Robot/NewHeroRobotModelForm";
import { DeleteModal } from "../../components/DeleteModal";
import { LanguageComponent } from "../../components/Language/LanguageComponent";
import { styles } from "../../styles/styles";
import { GridTable } from "../../components/GridTable";
import { ErrorState } from "../../components/ErrorState";
import { LiveValidationComponent } from "../../components/LiveValidationComponent";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { MoveRequestModal } from "../../components/MoveRequestModal";
import { CopyRequestModal } from "../../components/CopyRequestModal";
import { ModelDetailsModal } from "../../components/ModelDetailsModal";
import { HeroRobotVariant } from "../../components/Robot/HeroRobot.types";

export type Params = {
  id: string;
};

interface MultiLangData {
  id: string;
  language: string;
  name: string;
  description: string;
}

export function HeroRobotDetails() {
  const { id } = useParams<Params>();

  const [showCarousel, setShowCarousel] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [data, setData] = useState({} as GetHeroRobotResponse);
  const [multiLangData, setMultiLangData] = useState([] as MultiLangData[]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [file, setFile] = useState<Array<File> | null>(null);
  const [showRobotDetailsModal, setShowRobotDetailsModal] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState<HeroRobotVariant>(
    HeroRobotVariant.Classic
  );

  const dataToShow = useMemo(
    () =>
      data.buildSteps?.rows.filter((row) => row.variant === selectedVariant),
    [data.buildSteps?.rows, selectedVariant]
  );

  const { language, setSystemLanguages } = useContext(LanguageContext);
  const navigate = useNavigate();

  const getHeroRobotDetails = () => {
    console.log("getHeroRobotDetails()");
    if (!id) {
      return;
    }

    setLoading(true);

    const req: GetHeroRobotRequest = {
      id: id,
    };

    HeroApi.getHeroRobot(req)
      .then(
        (result: GetHeroRobotResponse) => {
          setData(result);
          setSystemLanguages(result.systemLanguages);
          console.log(result);

          let nameAndDescriptionData: MultiLangData[] = [];

          result.supportedLanguages.forEach((language) => {
            nameAndDescriptionData.push({
              id: id,
              language: language.id,
              name: result.name[language.id],
              description: result.description[language.id],
            });
          });
          console.log(nameAndDescriptionData);

          setMultiLangData(nameAndDescriptionData);
        },
        (error: ErrorResponse) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadHeroRobotImage = () => {
    console.log("uploadHeroRobotImage()", file?.[0]?.name);

    if (!file || !file[0] || !id) {
      return;
    }

    const req: UpdateHeroRobotCoverRequest = {
      id: id,
      file: file[0],
    };

    HeroApi.updateHeroRobotCover(req).then(
      () => {
        console.log(id, "cover has been updated.");
        window.location.reload();
      },
      (error) => {
        SetError(error, setError);
      }
    );
  };

  const updateDetails = (data: any) => {
    console.log("updateDetails()");
    if (!id) {
      return;
    }
    let details: UpdateHeroRobotRequest = {
      description: data["description"],
      lang: data["language"],
      name: data["name"],
    };

    let request: UpdateHeroRobotOperationRequest = {
      id: id,
      request: details,
    };

    HeroApi.updateHeroRobot(request)
      .then(
        (result) => {
          console.log(result, id, "details has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getHeroRobotDetails();
      });
  };

  const setPublishableState = (state: boolean) => {
    console.log("setPublishableState()", state);

    if (!id) {
      return;
    }

    const req: UpdateHeroRobotPublishableRequest = {
      publishable: state,
      id: id,
    };

    HeroApi.updateHeroRobotPublishable(req)
      .then(
        (result) => {
          console.log(result, id, "publishable state has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getHeroRobotDetails();
      });
  };

  const deleteHero = () => {
    console.log("deleteHero()");

    if (!id) {
      return;
    }

    const req: DeleteHeroRobotRequest = {
      id: id,
    };

    HeroApi.deleteHeroRobot(req)
      .then(
        (result) => {
          console.log(result, id, " has been deleted.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        navigate(`/robots`);
      });
  };

  const deleteBuildStep = (id: string) => {
    console.log("deleteBuildStep() " + id);

    const request: DeleteHeroRobotBuildStepRequest = {
      id: id,
    };

    HeroApi.deleteHeroRobotBuildStep(request)
      .then(
        (result) => {
          console.log(result, id, " step has been deleted.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getHeroRobotDetails();
      });
  };

  const updateHeroRobotBuildStepOrder = (ids: string[]) => {
    console.log("updateHeroRobotBuildStepOrder()");

    if (!id) {
      return;
    }
    const request: UpdateHeroRobotBuildStepOrderRequest = {
      ids: ids,
      variant: selectedVariant,
    };
    const req: UpdateHeroRobotBuildStepOrderOperationRequest = {
      id: id,
      request: request,
    };

    HeroApi.updateHeroRobotBuildStepOrder(req)
      .then(
        () => {
          console.log(id, "Hero robot build steps order has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getHeroRobotDetails();
      });
  };
  const updateHeroRobotLanguages = (data: any) => {
    console.log("updateHeroRobotLanguages()");

    if (!id || !data.publishableLanguages || !data.supportedLanguages) {
      return;
    }

    const request: UpdateHeroRobotLanguagesRequest = {
      publishableLanguages: data.publishableLanguages,
      supportedLanguages: data.supportedLanguages,
    };

    const req: UpdateHeroRobotLanguagesOperationRequest = {
      id: id,
      request: request,
    };

    HeroApi.updateHeroRobotLanguages(req)
      .then(
        () => {
          console.log("Hero robot languages has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getHeroRobotDetails();
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("HeroRobotDetails did mount.", id);
    getHeroRobotDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, []);

  const setCarouselVisibility = (value: boolean, index: any) => {
    if (index !== null || index !== undefined) {
      setCarouselIndex(index);
    }
    setShowCarousel(value);
  };

  function moveHeroRobot(organizationID: string) {
    if (!id) {
      return;
    }

    const req: MoveHeroRobotToOrganizationRequest = {
      id: id,
      pid: organizationID,
    };

    MoveApi.moveHeroRobotToOrganization(req)
      .then(
        () => {
          console.log("Move hero robot to organization was successful.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        navigate(`/robots`);
      });
  }

  function copyHeroRobot(organizationID: string) {
    if (!id) {
      return;
    }

    const req: CopyHeroRobotToOrganizationRequest = {
      id: id,
      pid: organizationID,
    };
    setLoading(true);
    CopyApi.copyHeroRobotToOrganization(req)
      .then(
        () => {
          console.log("Copy hero robot to organization was successful.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
        navigate(`/robots`);
      });
  }

  return (
    <>
      {error && (
        <ErrorState
          refresh={getHeroRobotDetails}
          error={error}
          setError={setError}
        />
      )}

      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row className="mb-3">
                  <Col
                    className="col-3 justify-content-around"
                    style={{ textAlign: "center" }}
                  >
                    <Row>
                      <Col>
                        <ImageWithPlaceholder
                          src={data.coverThumbnailUrl}
                          alt={data.name ? data.name[language] : undefined}
                          className="img-thumbnail"
                          style={styles.coverImage}
                          shouldOpenNewTab={true}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <ButtonWithModal
                          buttonTitle="Upload cover"
                          modalTitle="Upload new image for HeroRobot"
                          agreeCallback={() => {
                            uploadHeroRobotImage();
                          }}
                          cancelCallback={() => {}}
                        >
                          <input
                            type="file"
                            accept="image/jpeg"
                            onChange={(e) => {
                              if (
                                e.target &&
                                e.target.files &&
                                e.target.files?.length > 0
                              ) {
                                setFile(Array.from(e.target.files));
                              } else {
                                setFile(null);
                              }
                            }}
                          ></input>
                        </ButtonWithModal>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-7">
                    <Row className="mt-2">
                      <Col>
                        <Row>
                          <h1
                            className="mx-auto mb-1 text-center"
                            style={styles.pageTitle}
                          >
                            {data.name[language]}
                          </h1>
                        </Row>
                        <Row>
                          <h2
                            className="mx-auto mb-3 text-center"
                            style={styles.secondaryText}
                          >
                            {data.description[language]}
                          </h2>
                        </Row>
                        <Row>
                          <h3
                            className="mx-auto mb-1 text-center"
                            style={styles.pageTitle}
                          >
                            {id}
                          </h3>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="col-2 justify-content-around"
                    style={{ textAlign: "center" }}
                  >
                    <div
                      className="mb-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                      }}
                    >
                      <p style={styles.secondaryText}>Has Model:</p>
                      {data.hasModel ? (
                        <CheckCircleOutlineIcon style={styles.successIcon} />
                      ) : (
                        <ClearIcon style={styles.failIcon} />
                      )}
                    </div>

                    <ButtonWithModal
                      buttonTitle="Upload Model"
                      modalTitle="Upload new hero robot model"
                      cancelCallback={() => {}}
                      isModalWithForm={true}
                      style={{ marginBottom: "12px" }}
                    >
                      <NewHeroRobotModelForm
                        id={id}
                        setError={SetError}
                        refresh={getHeroRobotDetails}
                      />
                    </ButtonWithModal>

                    <ButtonWithStyle
                      buttonTitle="Show robot details"
                      onClickCallback={() => setShowRobotDetailsModal(true)}
                    />
                  </Col>
                </Row>
                <Row>
                  <LanguageComponent
                    supportedLanguages={data.supportedLanguages}
                    publishableLanguages={data.publishableLanguages}
                    systemLanguages={data.systemLanguages}
                    updateLanguages={updateHeroRobotLanguages}
                  />
                </Row>
                <Row>
                  <LiveValidationComponent validation={data.validation} />
                </Row>
                <Row className="mt-3">
                  <Col>
                    <h2 style={styles.secondaryText}>Details:</h2>
                    <h4 className="mx-auto mb-3" style={styles.thirdText}>
                      Id: {id}
                    </h4>
                  </Col>
                  <Col className="text-end">
                    <ButtonWithStyle
                      buttonTitle={
                        data.publishable
                          ? "Revoke Publishable"
                          : "Make Publishable"
                      }
                      onClickCallback={() =>
                        setPublishableState(!data.publishable)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EvolvedTable
                      data={multiLangData}
                      ignoreID
                      saveAction={(editedData: object) => {
                        updateDetails(editedData);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Row>
                      <Col>
                        <h2 style={styles.secondaryText}>Build Steps:</h2>
                      </Col>
                      {[HeroRobotVariant.Classic, HeroRobotVariant.Dark].map(
                        (variant) => (
                          <Col key={variant}>
                            <ButtonWithStyle
                              variant={
                                selectedVariant === variant
                                  ? "secondary"
                                  : "primary"
                              }
                              onClickCallback={() =>
                                setSelectedVariant(variant)
                              }
                              buttonTitle={
                                variant.charAt(0).toUpperCase() +
                                variant.slice(1) +
                                " (" +
                                data.buildSteps?.rows.filter(
                                  (row) => row.variant === variant
                                ).length +
                                ")"
                              }
                            />
                          </Col>
                        )
                      )}
                    </Row>
                  </Col>
                  <Col className="mb-3 text-end">
                    <ButtonWithModal
                      buttonTitle="Create new Step"
                      modalTitle="Upload new image for Step"
                      cancelCallback={() => {}}
                      isModalWithForm={true}
                    >
                      <NewheroRobotBuildStepForm
                        id={id}
                        setError={SetError}
                        refresh={getHeroRobotDetails}
                        setLoading={setLoading}
                        variant={selectedVariant}
                      />
                    </ButtonWithModal>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <GridTable
                      data={dataToShow}
                      imagePath={"imageThumbnailUrl"}
                      containerStyle={styles.HeroRobotsImageContainer}
                      setCarouselVisibility={setCarouselVisibility}
                      deleteItemCallback={deleteBuildStep}
                      setOrder={updateHeroRobotBuildStepOrder}
                      showItemNumber={true}
                      insertAfterForm={
                        <NewheroRobotBuildStepForm
                          id={id}
                          setError={SetError}
                          refresh={getHeroRobotDetails}
                          setLoading={setLoading}
                          variant={selectedVariant}
                        />
                      }
                    />
                    <CarouselModal
                      data={dataToShow}
                      path={"imageUrl"}
                      index={carouselIndex}
                      showCarousel={showCarousel}
                      setIndex={setCarouselIndex}
                      setCarouselVisibility={setCarouselVisibility}
                    />
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    gap: 32,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <div>
                    <MoveRequestModal
                      buttonTitle={`Move "${data.name[language]}" (organization)`}
                      modalTitle={`Move "${data.name[language]}" hero robot to another organization.`}
                      agreeCallback={moveHeroRobot}
                    />
                  </div>
                  <div>
                    <CopyRequestModal
                      buttonTitle={`Copy "${data.name[language]}" (organization)`}
                      modalTitle={`Copy "${data.name[language]}" hero robot to another organization.`}
                      agreeCallback={copyHeroRobot}
                    />
                  </div>
                  <div>
                    <DeleteModal
                      icon={<DeleteIcon />}
                      buttonTitle={`Delete "${data.name[language]}"`}
                      modalTitle={`Delete "${data.name[language]}" robot.`}
                      agreeCallback={() => deleteHero()}
                    />
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>

      <ModelDetailsModal
        model={data.model ? JSON.parse(data.model) : undefined}
        modalTitle="Robot details"
        show={showRobotDetailsModal}
        setShow={setShowRobotDetailsModal}
      />
    </>
  );
}
