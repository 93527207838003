import {
  CreateOrganizationOperationRequest,
  CreateOrganizationRequest,
  CreateOrganizationRequestKindEnum,
  CreateOrganizationResponse,
} from "@revolutionrobotics/admin-api";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { OrganizationApi } from "../../services/configuration";
import { SetError } from "../../services/general";
import { ButtonWithStyle } from "../ButtonWithStyle";
import { FormItem } from "../FormItem";

interface NewOrganizationFormProps {
  setError: Function;
  refresh: Function;
}

export function NewOrganizationForm({
  setError,
  refresh,
}: NewOrganizationFormProps) {
  const [validated, setValidated] = useState(false);
  const [inputs, setInputs] = useState<any | null>(null);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    console.log(inputs);
    if (!inputs || !inputs.name) return;

    const details: CreateOrganizationRequest = {
      address: inputs.address ? inputs.address : "",
      contactEmail: inputs.contactEmail ? inputs.contactEmail : "",
      contactName: inputs.contactName ? inputs.contactName : "",
      contactPhone: inputs.contactPhone ? inputs.contactPhone : "",
      name: inputs.name,
      notes: inputs.notes ? inputs.notes : "",
      website: inputs.website ? inputs.website : "",
      kind: inputs.isSchool
        ? CreateOrganizationRequestKindEnum.School
        : CreateOrganizationRequestKindEnum.Public,
      skipStudentSelection: inputs.skipStudentSelection,
    };

    const req: CreateOrganizationOperationRequest = {
      request: details,
    };

    OrganizationApi.createOrganization(req)
      .then(
        (response: CreateOrganizationResponse) => {
          console.log(response.id, "has been created.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        refresh();
      });
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    if (name === "isSchool") {
      setInputs((values: any) => ({
        ...values,
        [name]: event.target.checked,
        skipStudentSelection: false,
      }));
    } else if (name === "skipStudentSelection") {
      setInputs((values: any) => ({
        ...values,
        [name]: event.target.checked,
      }));
    } else {
      setInputs((values: any) => ({
        ...values,
        [name]: event.target.value,
      }));
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col>
          <Row className="mt-2">
            <FormItem
              title={"Name"}
              type={"text"}
              required={true}
              name={"name"}
              handleChange={handleChange}
            />
          </Row>
          <Row>
            <FormItem
              title={"Address"}
              type={"text"}
              name={"address"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"ContactEmail"}
              type={"text"}
              name={"contactEmail"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"ContactName"}
              type={"text"}
              name={"contactName"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"ContactPhone"}
              type={"text"}
              name={"contactPhone"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"Notes"}
              type={"text"}
              name={"notes"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"Website"}
              type={"text"}
              name={"website"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"Is school"}
              type={"checkbox"}
              name={"isSchool"}
              handleChange={handleChange}
            />
          </Row>
          {inputs?.isSchool && (
            <Row className="mt-2">
              <FormItem
                title={
                  "Skip student selection when starting a teammate session"
                }
                type={"checkbox"}
                name={"skipStudentSelection"}
                handleChange={handleChange}
              />
            </Row>
          )}
        </Col>
      </Row>
      <ButtonWithStyle buttonTitle="Create" type="submit" variant="primary" />
    </Form>
  );
}
