import {
  GetUsersResponse,
  GetUsersRow,
  GetUsersRequest,
  GetUsersOrderEnum,
} from "@revolutionrobotics/admin-api";

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { EvolvedTable } from "../../components/Table";
import { UserApi } from "../../services/configuration";
import { ErrorResponse, SetError } from "../../services/general";

import { SearchBar } from "../../components/SearchBar";
import { Pagination } from "../../components/Pagination";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import SearchIcon from "@mui/icons-material/Search";
import { styles } from "../../styles/styles";
import { ErrorState } from "../../components/ErrorState";
// import LanguageContext from "../services/LanguageContext";

export function Users() {
  const [tableData, setTableData] = useState([] as GetUsersRow[]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [searchText, setSearchText] = useState("");
  const [dataSize, setDataSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFirstSearch, setFirstSearch] = useState(true);
  const [order, setOrder] = useState<GetUsersOrderEnum>(
    GetUsersOrderEnum.CodeName
  );

  const PAGE_LIMIT = 6;
  // const { language } = useContext(LanguageContext);

  const getUsers = () => {
    console.log("getUsers()");
    setLoading(true);

    const req: GetUsersRequest = {
      limit: PAGE_LIMIT,
      offset: (currentPage - 1) * PAGE_LIMIT,
      search: searchText,
      order: order,
    };

    UserApi.getUsers(req)
      .then(
        (result: GetUsersResponse) => {
          setDataSize(result.users.size);
          setTableData(result.users.rows);
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const setListOrder = (header: string) => {
    if (header === "codeName") {
      if (order === GetUsersOrderEnum.CodeName) {
        setOrder(GetUsersOrderEnum.CodeNameDesc);
      } else {
        setOrder(GetUsersOrderEnum.CodeName);
      }
    } else if (header === "email") {
      if (order === GetUsersOrderEnum.Email) {
        setOrder(GetUsersOrderEnum.EmailDesc);
      } else {
        setOrder(GetUsersOrderEnum.Email);
      }
    } else if (header === "registrationDate") {
      if (order === GetUsersOrderEnum.RegistrationDate) {
        setOrder(GetUsersOrderEnum.RegistrationDateDesc);
      } else {
        setOrder(GetUsersOrderEnum.RegistrationDate);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Users did mount.");
    getUsers();
    // eslint-disable-next-line no-sparse-arrays, react-hooks/exhaustive-deps
  }, [, currentPage, order]);

  const getSearchResult = () => {
    if (isFirstSearch) {
      setFirstSearch(false);
    }
    setCurrentPage(1);
    getUsers();
  };

  return (
    <>
      {error && (
        <ErrorState refresh={getUsers} error={error} setError={setError} />
      )}
      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row className="mb-3">
                  <Col>
                    <SearchBar
                      placeholder={"Search user"}
                      value={searchText}
                      setTextCallback={setSearchText}
                      onClickCallback={getSearchResult}
                    />
                  </Col>
                  <Col>
                    <h1
                      className="mx-auto mb-3 text-center text-uppercase"
                      style={styles.pageTitle}
                    >
                      Users
                    </h1>
                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>
                    {loading ? (
                      <LoadingIndicator />
                    ) : !isFirstSearch && tableData.length === 0 ? (
                      <div className="mt-5" style={styles.noResult}>
                        <SearchIcon style={styles.noResultStateIcon} />
                        <p>No result found.</p>
                        <p>Try search different keyword.</p>
                      </div>
                    ) : (
                      <>
                        <EvolvedTable
                          data={tableData}
                          customHeaders={[
                            "avatarUrl",
                            "codeName",
                            "email",
                            "isVerified",
                            "registrationDate",
                          ]}
                          customHeadersText={[
                            "AVATAR",
                            "CODE NAME",
                            "E-MAIL",
                            "IS VERIFIED",
                            "REGISTRATION DATE",
                          ]}
                          allowDeleteAll
                          ignoreID
                          smallThumbnails
                          basePath="/users/details"
                          headerOnClickCallback={setListOrder}
                          order={order}
                        />
                        <Pagination
                          size={dataSize}
                          pageLimit={PAGE_LIMIT}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
