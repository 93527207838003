export const getActiveOrganization = () => {
  // This function is used to get the active organization from the cookie

  var allCookieArray = document.cookie.split(";");
  for (var i = 0; i < allCookieArray.length; i++) {
    var temp = allCookieArray[i].trim();
    if (temp.indexOf("Auth_Info=") === 0) {
      var authInfo = temp.substring("Auth_Info=".length, temp.length);
      if (authInfo) {
        return JSON.parse(authInfo).accounts.find((a: any) => a.active);
      }
    }
  }
};
