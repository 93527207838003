import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteModal } from "./DeleteModal";
import { ButtonWithStyle } from "./ButtonWithStyle";

interface ComplexActionBarProps {
  id: string;
  editMode: any;
  setEditMode: Function;
  onSave?: Function;
  name?: string;
  onDelete?: Function;
  deleteModalTitle?: string;
  deleteModalText?: string;
  isDeletePermanent?: boolean;
  onlyIcon?: boolean;
}

export function ComplexActionBar({
  id,
  name,
  editMode,
  setEditMode,
  onSave,
  onDelete,
  deleteModalTitle,
  deleteModalText,
  isDeletePermanent,
  onlyIcon,
}: ComplexActionBarProps) {
  const [showActions, setShowActions] = useState(false);

  const requestID =
    id.lastIndexOf("-") !== -1 ? id.substring(0, id.lastIndexOf("-")) : id;

  useEffect(() => {
    setShowActions(
      (editMode["status"] && editMode["rowKey"] === id) || (onDelete && !onSave)
    );
    // console.log(editMode["status"], editMode["rowKey"], id, showActions);
    // eslint-disable-next-line no-sparse-arrays, react-hooks/exhaustive-deps
  }, [, editMode]);

  return (
    <div className="d-flex justify-content-around">
      {showActions ? (
        <>
          {onSave && (
            <>
              <ButtonWithStyle
                variant="primary"
                onClickCallback={() => {
                  setEditMode({
                    status: false,
                    rowKey: null,
                    rowName: null,
                    requestId: null,
                  });
                  if (onSave) onSave(requestID, name);
                  console.log(`Save: ${requestID}`);
                }}
                icon={<SaveIcon />}
                isActionButton={true}
              />

              <ButtonWithStyle
                onClickCallback={() => {
                  setEditMode({
                    status: false,
                    rowKey: null,
                    rowName: null,
                    requestId: null,
                  });
                  console.log(`Cancel: ${requestID}`);
                }}
                icon={<CancelIcon />}
                isActionButton={true}
              />
            </>
          )}
          {onDelete && (
            <DeleteModal
              buttonTitle="Delete"
              modalTitle={deleteModalTitle}
              modalText={deleteModalText}
              isPermanent={isDeletePermanent}
              onlyIcon={onlyIcon}
              agreeCallback={() => {
                setEditMode({
                  status: false,
                  rowKey: null,
                  rowName: null,
                  requestId: null,
                });
                if (onDelete) onDelete(requestID);
                console.log(`Delete: ${requestID}`);
              }}
            />
          )}
        </>
      ) : (
        <ButtonWithStyle
          variant="primary"
          onClickCallback={() => {
            setEditMode({
              status: true,
              rowKey: id,
              rowName: name,
              requestId: requestID,
            });
            console.log(`Edit: ${requestID}`);
          }}
          icon={<EditIcon />}
          isActionButton={true}
        />
      )}
    </div>
  );
}
