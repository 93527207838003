import {
  UpdateHeroRobotModelOperationRequest,
  UpdateHeroRobotModelRequest,
} from "@revolutionrobotics/admin-api";
import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { HeroApi } from "../../services/configuration";
import { SetError } from "../../services/general";
import { ButtonWithStyle } from "../ButtonWithStyle";
import { FormItem } from "../FormItem";

interface NewHeroRobotModelFormProps {
  id?: string;
  setError: Function;
  refresh: Function;
}

export function NewHeroRobotModelForm({
  id,
  setError,
  refresh,
}: NewHeroRobotModelFormProps) {
  const [validated, setValidated] = useState(false);
  const [inputs, setInputs] = useState<any | null>(null);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (!inputs || !inputs.userRobotId || !id) return;

    const details: UpdateHeroRobotModelRequest = {
      userRobotId: inputs.userRobotId,
    };
    const req: UpdateHeroRobotModelOperationRequest = {
      request: details,
      id: id,
    };

    HeroApi.updateHeroRobotModel(req)
      .then(
        () => {
          console.log("model has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        refresh();
      });
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values: any) => ({ ...values, [name]: value }));
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row Row className="mb-3">
        <Col>
          <Row className="mt-2">
            <FormItem
              title={"User Robot Id"}
              type={"text"}
              required={true}
              name={"userRobotId"}
              handleChange={handleChange}
            />
          </Row>
        </Col>
      </Row>

      <ButtonWithStyle buttonTitle="Update" type="submit" variant="primary" />
    </Form>
  );
}
