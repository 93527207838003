import {
  LoginAdminOperationRequest,
  LoginAdminRequest,
  AdminPasswordResetOperationRequest,
  AdminPasswordResetRequest,
} from "@revolutionrobotics/admin-api";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { AuthenticationApi } from "../../services/configuration";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { ErrorState } from "../../components/ErrorState";
import { ErrorResponse, SetError } from "../../services/general";
import { styles } from "../../styles/styles";
import { FormItem } from "../../components/FormItem";
import { ButtonWithStyle } from "../../components/ButtonWithStyle";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { ForgotPasswordForm } from "../../components/Login/ForgotPasswordForm";
import { useNavigate } from "react-router";

export function Login() {
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState<any | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values: any) => ({ ...values, [name]: value }));
  };

  const loginAdmin = () => {
    if (!inputs || !inputs.email) return;

    const details: LoginAdminRequest = {
      email: inputs.email,
      password: inputs.password ? inputs.password : "",
    };
    const req: LoginAdminOperationRequest = {
      request: details,
    };

    AuthenticationApi.loginAdmin(req).then(
      () => {
        console.log("login.");
        navigateAfterLogin();
      },
      (error) => {
        console.log("error");
        SetError(error, setError);
      }
    );
  };
  const forgotPassword = () => {
    if (!inputs || !inputs.email) return;
    console.log("forgotPassword");

    const details: AdminPasswordResetRequest = {
      email: inputs.email,
    };
    const req: AdminPasswordResetOperationRequest = {
      request: details,
    };

    AuthenticationApi.adminPasswordReset(req).then(
      (resp) => {
        console.log(resp);
        alert("The new password has been sent to you in e-mail.");
      },
      (error) => {
        console.log("error");
        SetError(error, setError);
      }
    );
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    //doLogin
    loginAdmin();
  };
  const navigateAfterLogin = () => {
    navigate("/organizations");
  };

  return (
    <>
      <Container fluid>
        {error && (
          <ErrorState refresh={() => {}} error={error} setError={setError} />
        )}
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div
              className="mx-auto mb-3 text-center mt-5"
              style={styles.loginComponent}
            >
              <Form
                className="pb-3 pe-3 ps-3 pt-3"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col>
                    <h1
                      className="mx-auto mb-3 text-center text-uppercase"
                      style={styles.pageTitle}
                    >
                      Login
                    </h1>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Row className="mb-3">
                      <FormItem
                        title={"E-mail"}
                        type={"text"}
                        required={true}
                        name={"email"}
                        handleChange={handleChange}
                      />
                    </Row>
                    <Row className="mb-3">
                      <FormItem
                        title={"Password"}
                        type={"password"}
                        required={true}
                        name={"password"}
                        handleChange={handleChange}
                      />
                    </Row>

                    <ButtonWithStyle
                      buttonTitle="Login"
                      type="submit"
                      variant="primary"
                    />

                    <Row
                      className="mt-3 center"
                      style={styles.forgotPasswordContainer}
                    >
                      <ButtonWithModal
                        buttonTitle="Forgot password"
                        modalTitle={"Enter your e-mail address"}
                        variant="secondary"
                        withoutIcon={true}
                        agreeCallback={forgotPassword}
                        style={styles.forgotPassword}
                      >
                        <ForgotPasswordForm setInputs={setInputs} />
                      </ButtonWithModal>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        )}
      </Container>
    </>
  );
}
