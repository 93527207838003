import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { styles } from "../../styles/styles";

export function Help() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1
              className="mx-auto mb-3 text-center text-uppercase"
              style={styles.pageTitle}
            >
              Help
            </h1>
          </Col>
        </Row>

        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <iframe title="help" src={"Help.pdf"} width="100%" height="750px" />
          </>
        )}
      </Container>
    </>
  );
}
