export interface ErrorResponse {
  code: number;
  errorCode: string;
  info: string;
}

export function SetError(error: any, setError: Function) {
  error.json().then((result: ErrorResponse) => {
    setError(result);
  });
}
