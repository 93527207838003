import React from "react";
import { styles } from "../../styles/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Dropdown } from "react-bootstrap";

interface LanguageItem {
  id: string;
  name: string;
}

interface LanguageComponentProps {
  supportedLanguages: any[];
  publishableLanguages: any[];
  systemLanguages: any[];
  updateLanguages: Function;
}

export function LanguageComponent({
  supportedLanguages,
  publishableLanguages,
  systemLanguages,
  updateLanguages,
}: LanguageComponentProps) {
  const renderLanguageItemComponent = (item: LanguageItem, type: string) => {
    return (
      <div key={item.id} style={styles.languageComponentItem}>
        {item.name}
        <div
          onClick={() => {
            handleDeleteElement(type, item.id);
          }}
          style={{}}
        >
          <CloseIcon style={styles.languageComponentCloseIcon} />
        </div>
      </div>
    );
  };
  const renderAddElementDropdown = (
    type: string,
    list: LanguageItem[],
    filterList: LanguageItem[]
  ) => {
    let aviableItems = [] as LanguageItem[];
    list.forEach((item: LanguageItem) => {
      if (filterList.find((element) => element.id === item.id) === undefined) {
        aviableItems.push(item);
      }
    });

    return aviableItems.length > 0 ? (
      <Dropdown>
        <Dropdown.Toggle
          style={styles.languageComponentItemDropdown}
          id="dropdown-basic"
        >
          Add
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {aviableItems.map((item: LanguageItem) => (
            <Dropdown.Item
              key={item.id}
              onClick={() => {
                handleAddElement(type, item.id);
              }}
            >
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    ) : (
      <></>
    );
  };

  const handleDeleteElement = (type: string, id: string) => {
    console.log("delete", type, id);
    let supportedArray = [] as string[];
    let publishableArray = [] as string[];
    supportedLanguages.map((item: LanguageItem) =>
      supportedArray.push(item.id)
    );
    publishableLanguages.map((item: LanguageItem) =>
      publishableArray.push(item.id)
    );
    if (type === "supported") {
      const index = supportedArray.indexOf(id);
      if (index > -1) {
        supportedArray.splice(index, 1);
      }
    }
    if (type === "publishable") {
      const index = publishableArray.indexOf(id);
      if (index > -1) {
        publishableArray.splice(index, 1);
      }
    }
    let requestData = {
      supportedLanguages: supportedArray,
      publishableLanguages: publishableArray,
    };
    updateLanguages(requestData);
  };

  const handleAddElement = (type: string, id: string) => {
    console.log("add", type, id);
    let supportedArray = [];
    let publishableArray = [];
    supportedLanguages.map((item: LanguageItem) =>
      supportedArray.push(item.id)
    );
    publishableLanguages.map((item: LanguageItem) =>
      publishableArray.push(item.id)
    );
    if (type === "supported") {
      supportedArray.push(id);
    }
    if (type === "publishable") {
      publishableArray.push(id);
    }
    let requestData = {
      supportedLanguages: supportedArray,
      publishableLanguages: publishableArray,
    };
    updateLanguages(requestData);
  };

  return (
    <>
      <h2 className="mx-auto mb-1" style={styles.secondaryText}>
        Supported Languages:
      </h2>
      <div style={{ display: "flex" }}>
        {supportedLanguages.map((lang) =>
          renderLanguageItemComponent(lang, "supported")
        )}
        {renderAddElementDropdown(
          "supported",
          systemLanguages,
          supportedLanguages
        )}
      </div>
      <h2 className="mx-auto mb-1 " style={styles.secondaryText}>
        Publishable Languages:
      </h2>
      <div style={{ display: "flex" }}>
        {publishableLanguages.map((lang) =>
          renderLanguageItemComponent(lang, "publishable")
        )}
        {renderAddElementDropdown(
          "publishable",
          supportedLanguages,
          publishableLanguages
        )}
      </div>
    </>
  );
}
