import React, { useEffect } from "react";
import { styles } from "../styles/styles";
import { Row, Col } from "react-bootstrap";
import { LanguageSelector } from "../components/Language/LanguageSelector";
import { LogoutComponent } from "../components/Login/LogoutComponent";
import { OrganizationSelector } from "../components/Organization/OrganizationSelector";

export function TopMenuBar() {
  useEffect(() => {
    console.log("Top menu did mount.");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={styles.topMenuContainer}>
      <Row style={{ display: "flex", justifyContent: "flex-end" }}>
        <Col style={styles.organizationSelectorContainer}>
          <OrganizationSelector /*refresh={refresh}*/ />
        </Col>
        <Col style={styles.languageSelectorContainer}>
          <LanguageSelector /*systemLanguages={systemLanguages}*/ />
        </Col>
        <Col style={styles.logoutContainer}>
          <LogoutComponent />
        </Col>
      </Row>
    </div>
  );
}
