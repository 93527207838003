import {
  CreateHeroRobotBuildStepOperationRequest,
  CreateHeroRobotBuildStepRequest,
  CreateHeroRobotBuildStepResponse,
  UpdateHeroRobotBuildStepImageRequest,
} from "@revolutionrobotics/admin-api";
import { HeroApi } from "../../services/configuration";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { ButtonWithStyle } from "../ButtonWithStyle";
import { HeroRobotVariant } from "./HeroRobot.types";

interface NewheroRobotBuildStepFormProps {
  insertAfterId?: string;
  id?: string;
  setError: Function;
  refresh: Function;
  setLoading: Function;
  variant: HeroRobotVariant;
}

export function NewheroRobotBuildStepForm({
  insertAfterId,
  id,
  setError,
  refresh,
  setLoading,
  variant,
}: NewheroRobotBuildStepFormProps) {
  const [file, setFile] = useState<Array<File> | null>(null);

  const createNewStep = (insertAfterId?: string) => {
    console.log("createNewStep()");

    if (!id) {
      return;
    }
    setLoading(true);

    const details: CreateHeroRobotBuildStepRequest = {
      heroRobotId: id,
      variant,
    };

    const req: CreateHeroRobotBuildStepOperationRequest = {
      request: details,
      insertAfterID: insertAfterId || undefined,
    };

    HeroApi.createHeroRobotBuildStep(req)
      .then(
        (result: CreateHeroRobotBuildStepResponse) => {
          console.log(result, result.id, "has been created.");
          uploadStepImage(result.id);
        },
        (error) => {
          setLoading(false);
          setError(error, setError);
        }
      )
      .finally(() => {});
  };

  const uploadStepImage = (id: string) => {
    console.log("uploadStepImage()", file?.[0]?.name, id);

    if (!id || !file || !file[0]) {
      refresh();
      return;
    }

    const req: UpdateHeroRobotBuildStepImageRequest = {
      id: id,
      file: file[0],
    };

    HeroApi.updateHeroRobotBuildStepImage(req)
      .then(
        () => {
          console.log(id, "step image has been updated.");
        },
        (error) => {
          setLoading(false);
          setError(error, setError);
        }
      )
      .finally(() => {
        if (file.length > 1) {
          setFile(file.splice(0, 1));
          createNewStep();
        } else {
          setLoading(false);
          setFile(null);
          refresh();
        }
      });
  };

  return (
    <Form
      onSubmit={() => {
        createNewStep(insertAfterId);
      }}
    >
      <input
        className="mb-3"
        type="file"
        multiple
        accept="image/png"
        onChange={(e) => {
          if (e.target && e.target.files && e.target.files?.length > 0) {
            setFile(Array.from(e.target.files));
          } else {
            setFile(null);
          }
        }}
      ></input>

      <ButtonWithStyle buttonTitle="Create" type="submit" variant="primary" />
    </Form>
  );
}
