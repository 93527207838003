import { LanguageModel } from "@revolutionrobotics/admin-api";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "./containers/Layout";
import { Avatars } from "./pages/Avatar/Avatars";
import { Skills } from "./pages/Skill/SkillsCategories";
import { SkillCategoryDetails } from "./pages/Skill/SkillCategoryDetails";
import LanguageContext from "./services/LanguageContext";
import { Users } from "./pages/User/Users";
import { Heroes } from "./pages/Robot/Heroes";
import { HeroRobotDetails } from "./pages/Robot/HeroRobotDetails";
import { UsersDetails } from "./pages/User/UsersDetails";
import { SkillDetails } from "./pages/Skill/SkillDetails";
import { ChallengesCategories } from "./pages/Challenge/ChallengesCategories";
import { ChallengeCategoryDetails } from "./pages/Challenge/ChallengeCategoryDetails";
import { SkillStepDetails } from "./pages/Skill/SkillStepDetails";
import { Language } from "./pages/Language/Language";
import { ChallengeDetails } from "./pages/Challenge/ChallengeDetails";
import { ChallengeFormDetails } from "./pages/Challenge/ChallengeFormDetails";
import { ChallengeSubmissions } from "./pages/Challenge/ChallengeSubmissions";
import { Updates } from "./pages/Updates/Updates";
import { Organizations } from "./pages/Organization/Organizations";
import { OrganizationDetails } from "./pages/Organization/OrganizationDetails";
import { Blockly } from "./pages/Blockly/Blockly";
import { Login } from "./pages/Login/Login";
import { Help } from "./pages/Help/Help";
import { Lessons } from "./pages/Lessons/Lessons";
import { UserGroups } from "./pages/UserGroups/UserGroups";
import { LessonDetails } from "./pages/Lessons/LessonDetails";
import { UserGroupDetails } from "./pages/UserGroups/UserGroupDetails";

const getSavedLanguageCookie = () => {
  var allCookieArray = document.cookie.split(";");
  for (var i = 0; i < allCookieArray.length; i++) {
    var temp = allCookieArray[i].trim();
    if (temp.indexOf("Language=") === 0) {
      var lang = temp.substring("Language=".length, temp.length);
      if (lang) {
        return lang;
      }
    }
  }
  return "en";
};

function App() {
  const [language, setLanguage] = useState(getSavedLanguageCookie());
  const [systemLanguages, setSystemLanguages] = useState([] as LanguageModel[]);

  useEffect(() => {
    document.cookie = "Language=" + language + "; path=/;";
    // eslint-disable-next-line no-sparse-arrays, react-hooks/exhaustive-deps
  }, [, language]);

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
        systemLanguages,
        setSystemLanguages,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/users" element={<Users />} />
            <Route path="/user-groups" element={<UserGroups />} />
            <Route
              path="/user-groups/group/:id"
              element={<UserGroupDetails />}
            />
            <Route path="/avatars" element={<Avatars />} />
            <Route path="/skills" element={<Skills />} />
            <Route
              path="skills/category/:id"
              element={<SkillCategoryDetails />}
            />
            <Route path="/skills/skill/:id" element={<SkillDetails />} />
            <Route path="/skills/steps/:id" element={<SkillStepDetails />} />
            <Route path="/challenges" element={<ChallengesCategories />} />
            <Route
              path="/challenges/category/:id"
              element={<ChallengeCategoryDetails />}
            />
            <Route
              path="/challenges/challenge/:id"
              element={<ChallengeDetails />}
            />
            <Route
              path="/challenges/form/:id"
              element={<ChallengeFormDetails />}
            />
            <Route
              path="/challenges/submissions/:id"
              element={<ChallengeSubmissions />}
            />
            <Route path="/robots" element={<Heroes />} />
            <Route
              path="robots/hero-robot/:id"
              element={<HeroRobotDetails />}
            />
            <Route path="/lessons" element={<Lessons />} />
            <Route path="/lessons/lesson/:id" element={<LessonDetails />} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/blockly" element={<Blockly />} />
            <Route path="users/details/:id" element={<UsersDetails />} />
            <Route path="/language" element={<Language />} />
            <Route path="/updates" element={<Updates />} />
            <Route path="/help" element={<Help />} />
            <Route path="/organizations" element={<Organizations />} />
            <Route
              path="/organizations/organization/:id"
              element={<OrganizationDetails />}
            />
            <Route
              path="/organization/:id/user/:uid"
              element={<UsersDetails />}
            />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </LanguageContext.Provider>
  );
}

export default App;
