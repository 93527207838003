import { SetActiveAccountRequest } from "@revolutionrobotics/admin-api";
import React, { useState, useEffect } from "react";
import { Dropdown, Row, Col } from "react-bootstrap";
import { styles } from "../../styles/styles";
import { AuthenticationApi } from "../../services/configuration";
import { ErrorResponse } from "../../services/general";
import { useNavigate } from "react-router";

interface OrganizationSelectorItem {
  id: string;
  name: string;
  active: boolean;
  features: string[];
}

export function OrganizationSelector() {
  const [organizations, setOrganizations] = useState(
    [] as OrganizationSelectorItem[]
  );
  const navigate = useNavigate();

  const getCookie = () => {
    var allCookieArray = document.cookie.split(";");
    for (var i = 0; i < allCookieArray.length; i++) {
      var temp = allCookieArray[i].trim();
      if (temp.indexOf("Auth_Info=") === 0) {
        var authInfo = temp.substring("Auth_Info=".length, temp.length);
        if (authInfo) {
          setOrganizations(JSON.parse(authInfo).accounts);
        }
      }
    }
  };

  useEffect(() => {
    console.log("Organization selector did mount.");
    getCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setActiveAccount = (id: string) => {
    if (!id) {
      return;
    }

    const req: SetActiveAccountRequest = {
      id: id,
    };
    AuthenticationApi.setActiveAccount(req)
      .then(
        () => {
          console.log("select account: ", id);
        },
        (error: ErrorResponse) => {
          console.log(error);
        }
      )
      .finally(() => {
        getCookie();
        navigate("/organizations");
        window.location.reload();
      });
  };

  const getSelectedOrganizationName = () => {
    let selectedItem = organizations.find(
      (item: OrganizationSelectorItem) => item.active === true
    );
    if (selectedItem?.name) {
      return selectedItem.name;
    } else {
      return "";
    }
  };

  return (
    <Row className="align-items-center">
      <Col className="col-5" style={styles.secondaryText}>
        organization:
      </Col>
      <Col className=" col-7">
        <Dropdown>
          <Dropdown.Toggle
            style={styles.tableDropDown}
            variant="warning"
            id="dropdown-basic"
          >
            {getSelectedOrganizationName()}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {organizations.map((item: OrganizationSelectorItem) => (
              <Dropdown.Item
                key={item.id}
                onClick={() => {
                  setActiveAccount(item.id);
                }}
              >
                {item.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}
