import React from "react";
import { ButtonWithStyle } from "./ButtonWithStyle";
import PrevIcon from "@mui/icons-material/ChevronLeft";
import NextIcon from "@mui/icons-material/ChevronRight";
import SkipFirstIcon from "@mui/icons-material/SkipPrevious";
import SkipLastIcon from "@mui/icons-material/SkipNext";
import { styles } from "../styles/styles";

interface PaginationProps {
  size: number;
  pageLimit: number;
  currentPage: number;
  setCurrentPage: Function;
}

export function Pagination({
  size,
  pageLimit,
  currentPage,
  setCurrentPage,
}: PaginationProps) {
  const maxPageNumber = Math.ceil(size / pageLimit);

  const isNextButtonVisible = () => {
    return currentPage < maxPageNumber;
  };
  const isPrevButtonVisible = () => {
    return currentPage > 1;
  };
  const isPageNumberButtonVisible = (pageNumber: number) => {
    return pageNumber > 0 && pageNumber <= maxPageNumber;
  };

  const selectNextPage = () => {
    if (isNextButtonVisible()) {
      setCurrentPage(currentPage + 1);
    }
  };
  const selectPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const selectPrevPage = () => {
    if (isPrevButtonVisible()) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      {maxPageNumber > 1 && (
        <div style={styles.paginationContainer}>
          <ButtonWithStyle
            icon={<SkipFirstIcon />}
            onClickCallback={() => selectPage(1)}
            isActionButton={true}
            customStyle={{
              visibility: isPrevButtonVisible() ? "visible" : "hidden",
            }}
          />
          <ButtonWithStyle
            icon={<PrevIcon />}
            onClickCallback={selectPrevPage}
            isActionButton={true}
            customStyle={{
              visibility: isPrevButtonVisible() ? "visible" : "hidden",
            }}
          />

          {isPageNumberButtonVisible(currentPage - 2) && (
            <p style={styles.secondaryText}>...</p>
          )}
          <ButtonWithStyle
            buttonTitle={(currentPage - 1).toString()}
            onClickCallback={() => {
              selectPage(currentPage - 1);
            }}
            isActionButton={true}
            customStyle={{
              visibility: isPageNumberButtonVisible(currentPage - 1)
                ? "visible"
                : "hidden",
            }}
          />
          <ButtonWithStyle
            variant="secondary"
            buttonTitle={currentPage.toString()}
            onClickCallback={() => {
              selectPage(currentPage);
            }}
            isActionButton={true}
          />
          <ButtonWithStyle
            buttonTitle={(currentPage + 1).toString()}
            onClickCallback={() => {
              selectPage(currentPage + 1);
            }}
            isActionButton={true}
            customStyle={{
              visibility: isPageNumberButtonVisible(currentPage + 1)
                ? "visible"
                : "hidden",
            }}
          />
          {isPageNumberButtonVisible(currentPage + 2) && (
            <p style={styles.secondaryText}>...</p>
          )}

          <ButtonWithStyle
            icon={<NextIcon />}
            onClickCallback={selectNextPage}
            isActionButton={true}
            customStyle={{
              visibility: isNextButtonVisible() ? "visible" : "hidden",
            }}
          />
          <ButtonWithStyle
            icon={<SkipLastIcon />}
            onClickCallback={() => selectPage(maxPageNumber)}
            isActionButton={true}
            customStyle={{
              visibility: isNextButtonVisible() ? "visible" : "hidden",
            }}
          />
        </div>
      )}
    </>
  );
}
