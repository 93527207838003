import {
  GetUserRequest,
  GetUserResponse,
  DeleteUserRequest,
  GetUserInOrganizationRequest,
  RemoveUserFromOrganizationRequest,
  GetUserRobotsRow,
} from "@revolutionrobotics/admin-api";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ErrorResponse, SetError } from "../../services/general";
import { formatDate } from "../../services/DateFormatter";
import { UserApi } from "../../services/configuration";
import { OrganizationApi } from "../../services/configuration";
import { ErrorState } from "../../components/ErrorState";
import { useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import { ImageWithPlaceholder } from "../../components/ImageWithPlaceholder";
import { EvolvedTable } from "../../components/Table";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { styles } from "../../styles/styles";
import { DeleteModal } from "../../components/DeleteModal";
import { ModelDetailsModal } from "../../components/ModelDetailsModal";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { UserPasswordForm } from "./UserPasswordForm";

export type Params = {
  id: string;
  uid?: string;
};

export function UsersDetails() {
  const { id, uid } = useParams<Params>();
  const [data, setData] = useState({} as GetUserResponse);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [userRobotList, setUserRobotList] = useState([] as GetUserRobotsRow[]);
  const [showRobotDetailsModal, setShowRobotDetailsModal] = useState(false);
  const [model, setModel] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("UserDetails did mount.", id);
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, []);

  const getUserDetails = () => {
    console.log("getUser()");
    if (!uid) {
      if (!id) {
        return;
      }

      setLoading(true);

      const req: GetUserRequest = {
        id: id,
      };

      UserApi.getUser(req)
        .then(
          (result: GetUserResponse) => {
            setData(result);
            setUserRobotList(result.userRobots.rows);
            console.log(result);
          },
          (error: ErrorResponse) => {
            SetError(error, setError);
          }
        )
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.log("getUserInOrganization()");
      if (!id) {
        return;
      }

      setLoading(true);

      const req: GetUserInOrganizationRequest = {
        id: id,
        uid: uid,
      };

      OrganizationApi.getUserInOrganization(req)
        .then(
          (result: GetUserResponse) => {
            setData(result);
            setUserRobotList(result.userRobots.rows);
            console.log(result);
          },
          (error: ErrorResponse) => {
            SetError(error, setError);
          }
        )
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const deleteUser = () => {
    if (!uid) {
      console.log("deleteUser()");

      if (!id) {
        return;
      }

      const req: DeleteUserRequest = {
        id: id,
      };

      UserApi.deleteUser(req)
        .then(
          (result) => {
            console.log(result, id, " has been deleted.");
          },
          (error) => {
            SetError(error, setError);
          }
        )
        .finally(() => {
          navigate(`/users`);
        });
    } else {
      console.log("removeUserFromOrganization()");

      if (!id) {
        return;
      }

      const req: RemoveUserFromOrganizationRequest = {
        uid: uid,
        id: id,
      };

      OrganizationApi.removeUserFromOrganization(req)
        .then(
          () => {
            console.log(uid, "from", id, " has been removed.");
          },
          (error) => {
            SetError(error, setError);
          }
        )
        .finally(() => {
          navigate("/organizations/organization/" + id);
        });
    }
  };

  function handleRowClick(row: GetUserRobotsRow) {
    row.model && setModel(JSON.parse(row.model));
    setShowRobotDetailsModal(true);
  }

  const getValidationText = () => {
    if (data.needsPassword) {
      return (
        <h2>
          <span
            style={{
              color: "red",
            }}
          >
            {"🚨 Mentor user needs password"}
          </span>
        </h2>
      );
    } else {
      return (
        <h2>
          <span
            style={{
              color: "green",
            }}
          >
            {"✅ Mentor user has password"}
          </span>
        </h2>
      );
    }
  };

  return (
    <>
      {error && (
        <ErrorState
          refresh={getUserDetails}
          error={error}
          setError={setError}
        />
      )}

      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row className="align-items-center mb-3">
                  <Col
                    className="col-3 justify-content-center"
                    style={{ textAlign: "center" }}
                  >
                    <ImageWithPlaceholder
                      src={data.avatarUrl ? data.avatarUrl : ""}
                      className="img-thumbnail"
                      style={styles.coverImage}
                    />
                  </Col>
                  <Col className="col-9">
                    <Row>
                      <Col>
                        <h1
                          className="mx-auto mb-1 text-center"
                          style={styles.pageTitle}
                        >
                          {data.codeName}
                        </h1>
                        <h2
                          className="mx-auto mb-3 text-center "
                          style={styles.secondaryText}
                        >
                          {data.email === "" ? "-" : data.email}
                        </h2>
                        <Row>
                          <div className=" text-secondary d-flex flex-row justify-content-center">
                            <div className="mx-auto mb-3 text-center">
                              <p style={{ fontWeight: "bold" }}>Verified:</p>
                              {data.isVerified ? (
                                <CheckCircleOutlineIcon
                                  style={styles.successIcon}
                                />
                              ) : (
                                <ClearIcon style={styles.failIcon} />
                              )}
                            </div>
                            <div className="mx-auto mb-3 text-center">
                              <p style={{ fontWeight: "bold" }}>
                                Registration Date:
                              </p>
                              {formatDate(data.registrationDate)}
                            </div>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {data.isMentor && !!id && !!uid && (
                  <Row
                    className="text-center justify-content-center"
                    style={styles.secondaryText}
                  >
                    <Col />
                    <Col style={{ minWidth: "fit-content" }}>
                      {getValidationText()}
                    </Col>

                    <Col className="text-end">
                      <ButtonWithModal
                        buttonTitle={
                          data.needsPassword
                            ? "Set password"
                            : "Update password"
                        }
                        modalTitle={
                          data.needsPassword
                            ? "Set password"
                            : "Update password"
                        }
                        isModalWithForm={true}
                      >
                        <UserPasswordForm
                          id={id}
                          uid={uid}
                          getUserDetails={getUserDetails}
                          needsPassword={data.needsPassword}
                        />
                      </ButtonWithModal>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <h2 style={styles.secondaryText}>User Robots:</h2>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EvolvedTable
                      data={userRobotList}
                      customHeaders={[
                        "id",
                        "name",
                        "lastModification",
                        "modelVersion",
                      ]}
                      copyIdOnClick={true}
                      customOnClickAction={handleRowClick}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-end">
                    <DeleteModal
                      icon={<DeleteIcon />}
                      buttonTitle={
                        !uid
                          ? `Delete "${data.codeName}"`
                          : `Remove "${data.codeName}" from organization`
                      }
                      modalTitle={
                        !uid
                          ? `Delete "${data.codeName}" user. `
                          : `Remove "${data.codeName}" user from organization. `
                      }
                      agreeCallback={() => deleteUser()}
                      isPermanent={false}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>

      <ModelDetailsModal
        model={model}
        modalTitle="Robot details"
        show={showRobotDetailsModal}
        setShow={setShowRobotDetailsModal}
      />
    </>
  );
}
