import {
  GetOrganizationRequest,
  GetOrganizationResponse,
  UpdateOrganizationOperationRequest,
  UpdateOrganizationRequest,
  UpdateOrganizationPublishableRequest,
  DeleteOrganizationRequest,
  RemoveAdminFromOrganizationRequest,
  GetOrganizationAdminsRow,
  GetOrganizationUsersRow,
  RemoveUserFromOrganizationRequest,
  UpdateUserInOrganizationRequest,
  UpdateUserInOrganizationOperationRequest,
} from "@revolutionrobotics/admin-api";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ErrorResponse, SetError } from "../../services/general";
import { ExportApi, OrganizationApi } from "../../services/configuration";
import { ButtonWithStyle } from "../../components/ButtonWithStyle";
import { DeleteModal } from "../../components/DeleteModal";
import { EvolvedTable } from "../../components/Table";
import { styles } from "../../styles/styles";
import { useNavigate } from "react-router";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { AddAdminForm } from "../../components/Organization/AddAdminForm";
import { AddUserForm } from "../../components/Organization/AddUserForm";
import { ErrorState } from "../../components/ErrorState";

import { LoadingIndicator } from "../../components/LoadingIndicator";

export type Params = {
  id: string;
};

interface Organization {
  address?: string;
  contactEmail?: string;
  contactName?: string;
  contactPhone?: string;
  name: string;
  notes?: string;
  publishable: boolean;
  website?: string;
  id: string;
  skipStudentSelection?: boolean;
}

export function OrganizationDetails() {
  const { id } = useParams<Params>();
  const [organizationData, setOrganizationData] = useState(
    [] as Organization[]
  );
  const [data, setData] = useState({} as GetOrganizationResponse);
  const [adminData, setAdminData] = useState([] as GetOrganizationAdminsRow[]);
  const [userData, setUserData] = useState([] as GetOrganizationUsersRow[]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("OrganizationDetails did mount.", id);
    getOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, []);

  const getOrganization = () => {
    console.log("getOrganization()");
    if (!id) {
      return;
    }

    setLoading(true);

    const req: GetOrganizationRequest = {
      id: id,
    };

    OrganizationApi.getOrganization(req)
      .then(
        (result: GetOrganizationResponse) => {
          setData(result);

          let organization: Organization[] = [];
          organization.push({
            address: result.address,
            contactEmail: result.contactEmail,
            contactName: result.contactName,
            contactPhone: result.contactPhone,
            name: result.name,
            notes: result.notes,
            website: result.website,
            id: id,
            publishable: result.publishable,
            skipStudentSelection: result.skipStudentSelection,
          });
          setOrganizationData(organization);
          setAdminData(result.admins.rows);
          setUserData(result.users.rows);
          console.log(result);
        },
        (error: ErrorResponse) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const updateOrganization = (data: any) => {
    console.log("updateOrganization()");

    if (!id || !data.name) {
      return;
    }

    const request: UpdateOrganizationRequest = {
      address: data.address,
      contactEmail: data.contactEmail,
      contactName: data.contactName,
      contactPhone: data.contactPhone,
      name: data.name,
      notes: data.notes,
      website: data.website,
      skipStudentSelection: data.skipStudentSelection,
    };

    const req: UpdateOrganizationOperationRequest = {
      id: id,
      request: request,
    };

    OrganizationApi.updateOrganization(req)
      .then(
        () => {
          console.log("Organization has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getOrganization();
      });
  };

  const updateOrganizationPublishable = (state: boolean) => {
    console.log("setPublishableState()", state);

    if (!id) {
      return;
    }

    const req: UpdateOrganizationPublishableRequest = {
      publishable: state,
      id: id,
    };

    OrganizationApi.updateOrganizationPublishable(req)
      .then(
        (result) => {
          console.log(result, id, "publishable state has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getOrganization();
      });
  };

  const deleteOrganization = () => {
    console.log("deleteOrganization()");

    if (!id) {
      return;
    }

    const req: DeleteOrganizationRequest = {
      id: id,
    };

    OrganizationApi.deleteOrganization(req)
      .then(
        (result) => {
          console.log(result, id, " has been deleted.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        navigate(`/organizations`);
      });
  };

  const removeAdminFromOrganization = (adminId: any) => {
    console.log("removeAdmin()");

    if (!id || !adminId) {
      return;
    }

    const req: RemoveAdminFromOrganizationRequest = {
      aid: adminId,
      id: id,
    };

    OrganizationApi.removeAdminFromOrganization(req)
      .then(
        () => {
          console.log(adminId, id, " has been removed.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getOrganization();
      });
  };

  const exportAnalytics = () => {
    console.log("exportTeammateSessions()");
    ExportApi.exportTeammateSessions().then(
      (result) => {
        const url = window.URL.createObjectURL(result);

        const downloadLink = document.createElement("a");
        document.body.append(downloadLink);
        downloadLink.setAttribute("style", "display: none");
        downloadLink.href = url;
        downloadLink.download = `Teammate Sessions ${new Date().toLocaleDateString()}.xlsx`;
        downloadLink.click();
        window.URL.revokeObjectURL(url);
        downloadLink.remove();
      },
      (error) => {
        SetError(error, setError);
      }
    );
  };
  const deleteUser = (userId: string) => {
    console.log("removeUserFromOrganization()");

    if (!id) {
      return;
    }

    const req: RemoveUserFromOrganizationRequest = {
      uid: userId,
      id: id,
    };

    OrganizationApi.removeUserFromOrganization(req)
      .then(
        () => {
          console.log(userId, "from", id, " has been removed.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getOrganization();
      });
  };

  const updateUserInOrganization = (data: any) => {
    console.log("updateUserInOrganization()");
    if (!id || !data.id) {
      return;
    }
    let request: UpdateUserInOrganizationRequest = {
      isMentor: data.isMentor,
      notes: data.notes,
    };

    let req: UpdateUserInOrganizationOperationRequest = {
      id: id,
      uid: data.id,
      request,
    };

    OrganizationApi.updateUserInOrganization(req)
      .then(
        () => {
          console.log(data.id, "User data has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getOrganization();
      });
  };

  return (
    <>
      {error && (
        <ErrorState
          refresh={getOrganization}
          error={error}
          setError={setError}
        />
      )}
      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row className="mb-3">
                  <Col />
                  <Col style={{ textAlign: "center" }}>
                    <Row>
                      <h1
                        className="mx-auto mb-1 text-center"
                        style={styles.pageTitle}
                      >
                        Organization Details
                      </h1>
                    </Row>
                    <Row>
                      <h2
                        className="mx-auto mb-1 text-center"
                        style={styles.secondaryText}
                      >
                        {data.name}
                      </h2>
                    </Row>
                    <Row>
                      <h3
                        className="mx-auto mb-1 text-center"
                        style={styles.pageTitle}
                      >
                        {id}
                      </h3>
                    </Row>
                  </Col>
                  <Col className="text-end">
                    {data.kind === "school" && (
                      <ButtonWithStyle
                        variant="primary"
                        buttonTitle="Export analytics"
                        onClickCallback={exportAnalytics}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2 style={styles.secondaryText}>Details:</h2>
                  </Col>
                  <Col className="mb-3 text-end">
                    <ButtonWithStyle
                      buttonTitle={
                        data.publishable
                          ? "Revoke Publishable"
                          : "Make Publishable"
                      }
                      onClickCallback={() =>
                        updateOrganizationPublishable(!data.publishable)
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    <EvolvedTable
                      data={organizationData}
                      customHeaders={[
                        "name",
                        "website",
                        "address",
                        "contactName",
                        "contactEmail",
                        "contactPhone",
                        "notes",
                        "skipStudentSelection",
                        "publishable",
                      ]}
                      customHeadersText={[
                        "NAME",
                        "WEBSITE",
                        "ADDRESS",
                        "CONTACT NAME",
                        "CONTACT EMAIL",
                        "CONTACT PHONE",
                        "NOTES",
                        "SKIP STUDENT SELECTION",
                        "PUBLISHABLE",
                      ]}
                      editableHeaders={[
                        "name",
                        "website",
                        "address",
                        "contactName",
                        "contactEmail",
                        "contactPhone",
                        "notes",
                        "skipStudentSelection",
                      ]}
                      saveAction={updateOrganization}
                    />
                  </Col>
                </Row>
                <Row className="mt-3 align-items-center">
                  <Col className="col-9">
                    <h2 style={styles.secondaryText}>Admins:</h2>
                  </Col>
                  <Col className="text-end">
                    <ButtonWithModal
                      buttonTitle={"Add new"}
                      modalTitle={"Add new Admin to Organization"}
                      isModalWithForm={true}
                    >
                      <AddAdminForm
                        id={id}
                        setError={setError}
                        refresh={getOrganization}
                        setLoading={setLoading}
                      />
                    </ButtonWithModal>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <EvolvedTable
                      data={adminData}
                      deleteAction={removeAdminFromOrganization}
                      customHeaders={["email", "name", "phone"]}
                    />
                  </Col>
                </Row>
                {data.canManageUsers && (
                  <>
                    <Row className="mt-3 align-items-center">
                      <Col className="col-9">
                        <h2 style={styles.secondaryText}>Users:</h2>
                      </Col>
                      <Col className="text-end">
                        <ButtonWithModal
                          buttonTitle={"Add new"}
                          modalTitle={"Add new User to Organization"}
                          isModalWithForm={true}
                        >
                          <AddUserForm
                            id={id}
                            setError={setError}
                            refresh={getOrganization}
                            setLoading={setLoading}
                          />
                        </ButtonWithModal>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <EvolvedTable
                          data={userData}
                          customHeaders={[
                            "avatarUrl",
                            "codeName",
                            "notes",
                            "isMentor",
                            "isVerified",
                          ]}
                          customHeadersText={[
                            "AVATAR URL",
                            "CODE NAME",
                            "NOTES",
                            "MENTOR",
                            "VERIFIED",
                          ]}
                          customOnClickAction={(
                            user: GetOrganizationUsersRow
                          ) => {
                            navigate(`/organization/${id}/user/${user.id}`);
                          }}
                          saveAction={(editedData: object) => {
                            updateUserInOrganization(editedData);
                          }}
                          editableHeaders={["isMentor", "notes"]}
                          deleteAction={deleteUser}
                          deleteModalTitle="Remove user from organization."
                          deleteModalText="You can re-add the user later."
                          isDeletePermanent={false}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <DeleteModal
                      buttonTitle={`Delete "${data.name}"`}
                      modalTitle={`Delete "${data.name}" organization.`}
                      agreeCallback={() => {
                        deleteOrganization();
                      }}
                    ></DeleteModal>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
