import {
  GetSkillRequest,
  GetSkillResponse,
  DeleteSkillRequest,
  UpdateSkillPublishableRequest,
  UpdateSkillOperationRequest,
  UpdateSkillRequest,
  UpdateSkillStepOrderOperationRequest,
  UpdateSkillStepOrderRequest,
  UpdateSkillLanguagesRequest,
  UpdateSkillLanguagesOperationRequest,
  UpdateSkillBadgeRequest,
  DeleteSkillBadgeRequest,
  MoveSkillToCategoryRequest,
  CopySkillToCategoryRequest,
} from "@revolutionrobotics/admin-api";
import React, { useContext, useEffect, useState } from "react";
import { ErrorResponse, SetError } from "../../services/general";
import { Col, Container, Row } from "react-bootstrap";
import LanguageContext from "../../services/LanguageContext";
import { ButtonWithStyle } from "../../components/ButtonWithStyle";
import { useParams } from "react-router-dom";
import { CopyApi, MoveApi, SkillApi } from "../../services/configuration";
import { useNavigate } from "react-router";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { EvolvedTable } from "../../components/Table";
import { NewSkillStepForm } from "../../components/Skill/NewSkillStepForm";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { styles } from "../../styles/styles";
import { DeleteModal } from "../../components/DeleteModal";
import { CarouselModal } from "../../components/CarouselModal";
import { LanguageComponent } from "../../components/Language/LanguageComponent";
import { LiveValidationComponent } from "../../components/LiveValidationComponent";
import { ErrorState } from "../../components/ErrorState";
import { ImageWithPlaceholder } from "../../components/ImageWithPlaceholder";
import { MoveRequestModal } from "../../components/MoveRequestModal";
import { CopyRequestModal } from "../../components/CopyRequestModal";

export type Params = {
  id: string;
};

interface MultiLangData {
  id: string;
  language: string;
  title: string;
  subtitle: string;
}

interface MultiLangRowData {
  explanation: string;
  id: string;
  imageUrl: string | undefined;
  imageThumbnailUrl: string | undefined;
  order: number;
  title: string;
}

export function SkillDetails() {
  const { id } = useParams<Params>();
  const [loading, setLoading] = useState(true);
  const [showCarousel, setShowCarousel] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [data, setData] = useState({} as GetSkillResponse);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [multiLangData, setMultiLangData] = useState([] as MultiLangData[]);
  const [multiLangSkillStepsData, setMultiLangSkillStepsData] = useState(
    [] as MultiLangRowData[]
  );
  const [file, setFile] = useState<File | null>(null);

  const { language, setSystemLanguages } = useContext(LanguageContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("SkillDetails did mount.", id);
    getSkill();
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, []);

  const getSkill = () => {
    console.log("getSkill()");
    if (!id) {
      return;
    }

    setLoading(true);

    const req: GetSkillRequest = {
      id: id,
    };

    SkillApi.getSkill(req)
      .then(
        (result: GetSkillResponse) => {
          setData(result);
          setSystemLanguages(result.systemLanguages);
          console.log(result);

          let titleAndSubtitleData: MultiLangData[] = [];

          result.supportedLanguages.forEach((language) => {
            titleAndSubtitleData.push({
              id: id,
              language: language.id,
              title: result.title[language.id],
              subtitle: result.subtitle[language.id],
            });
          });

          setMultiLangData(titleAndSubtitleData);

          let rows: MultiLangRowData[] = [];

          result.steps.rows.forEach((row) => {
            rows.push({
              explanation: row.explanation[language],
              id: row.id,
              imageUrl: row.imageUrl,
              imageThumbnailUrl: row.imageThumbnailUrl,
              order: row.order,
              title: row.title[language],
            });
          });

          setMultiLangSkillStepsData(rows);
        },
        (error: ErrorResponse) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteSkill = () => {
    console.log("deleteSkill()");

    if (!id) {
      return;
    }

    const req: DeleteSkillRequest = {
      id: id,
    };

    SkillApi.deleteSkill(req)
      .then(
        (result) => {
          console.log(result, id, " has been deleted.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        navigate(`/skills`);
      });
  };

  const setSkillPublishableState = (state: boolean) => {
    console.log("setPublishableState()", state);

    if (!id) {
      return;
    }

    const req: UpdateSkillPublishableRequest = {
      publishable: state,
      id: id,
    };

    SkillApi.updateSkillPublishable(req)
      .then(
        (result) => {
          console.log(result, id, "publishable state has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getSkill();
      });
  };

  const updateSkill = (data: any) => {
    console.log("updateSkill()");
    if (!id) {
      return;
    }
    let details: UpdateSkillRequest = {
      lang: data["language"],
      title: data["title"],
      subtitle: data["subtitle"],
    };

    let request: UpdateSkillOperationRequest = {
      id: id,
      request: details,
    };

    SkillApi.updateSkill(request)
      .then(
        (result) => {
          console.log(result, id, "skill has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getSkill();
      });
  };

  const updateSkillStepOrder = (ids: string[]) => {
    console.log("updateSkillStepOrder()");

    if (!id) {
      return;
    }
    const request: UpdateSkillStepOrderRequest = {
      ids: ids,
    };
    const req: UpdateSkillStepOrderOperationRequest = {
      id: id,
      request: request,
    };

    SkillApi.updateSkillStepOrder(req)
      .then(
        () => {
          console.log("Skill details skill step order has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getSkill();
      });
  };
  const updateSkillLanguages = (data: any) => {
    console.log("updateSkillLanguages()");

    if (!id || !data.publishableLanguages || !data.supportedLanguages) {
      return;
    }

    const request: UpdateSkillLanguagesRequest = {
      publishableLanguages: data.publishableLanguages,
      supportedLanguages: data.supportedLanguages,
    };

    const req: UpdateSkillLanguagesOperationRequest = {
      id: id,
      request: request,
    };

    SkillApi.updateSkillLanguages(req)
      .then(
        () => {
          console.log("Skill languages has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getSkill();
      });
  };

  const updateBadge = () => {
    console.log("updateBadge()", file?.name);

    if (!file || !id) {
      return;
    }

    const req: UpdateSkillBadgeRequest = {
      id: id,
      file: file,
    };

    SkillApi.updateSkillBadge(req).then(
      (result) => {
        console.log(result, id, "badge has been updated.");
        window.location.reload();
      },
      (error) => {
        SetError(error, setError);
      }
    );
  };

  function deleteBadge() {
    console.log("deleteBadge()");

    if (!id) {
      return;
    }

    const req: DeleteSkillBadgeRequest = {
      id: id,
    };

    SkillApi.deleteSkillBadge(req)
      .then(
        (result) => {
          console.log(result, id, " has been deleted.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getSkill();
      });
  }

  const setCarouselVisibility = (value: boolean, index: any) => {
    if (index !== null || index !== undefined) {
      setCarouselIndex(index);
    }
    setShowCarousel(value);
  };

  function moveSkill(organizationID: string) {
    if (!id) {
      return;
    }

    const req: MoveSkillToCategoryRequest = {
      id: id,
      pid: organizationID,
    };

    MoveApi.moveSkillToCategory(req)
      .then(
        () => {
          console.log("Challenge Category languages has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        navigate(`/skills`);
      });
  }
  function copySkill(organizationID: string) {
    if (!id) {
      return;
    }
    const req: CopySkillToCategoryRequest = {
      id: id,
      pid: organizationID,
    };

    setLoading(true);
    CopyApi.copySkillToCategory(req)
      .then(
        () => {
          console.log("Copy skill to organization was successful.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
        navigate(`/skills`);
      });
  }

  return (
    <>
      {error && (
        <ErrorState refresh={getSkill} error={error} setError={setError} />
      )}

      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row className="mb-3">
                  <Col
                    className="justify-content-around"
                    style={{ textAlign: "center" }}
                  >
                    <Row className="mt-2">
                      <Col>
                        <Row>
                          <h1
                            className="mx-auto mb-1 text-center"
                            style={styles.pageTitle}
                          >
                            {data.title[language]}
                          </h1>
                        </Row>
                        <Row>
                          <h2
                            className="mx-auto mb-3 text-center "
                            style={styles.secondaryText}
                          >
                            {data.subtitle[language]}
                          </h2>
                        </Row>
                        <Row>
                          <h3
                            className="mx-auto mb-1 text-center"
                            style={styles.pageTitle}
                          >
                            {id}
                          </h3>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <LanguageComponent
                      supportedLanguages={data.supportedLanguages}
                      publishableLanguages={data.publishableLanguages}
                      systemLanguages={data.systemLanguages}
                      updateLanguages={updateSkillLanguages}
                    />
                  </Col>
                  <Col className="col-2" style={{ textAlign: "center" }}>
                    <ImageWithPlaceholder
                      src={data.badgeUrl}
                      alt={data.title ? data.title[language] : undefined}
                      className="img-thumbnail"
                      shouldOpenNewTab={true}
                      style={styles.coverImage}
                    />
                    <Row className="mt-2">
                      <Col>
                        <ButtonWithModal
                          buttonTitle="Upload badge"
                          modalTitle={`Upload new badge`}
                          agreeCallback={() => {
                            updateBadge();
                          }}
                          cancelCallback={() => {}}
                          onlyIcon
                        >
                          <input
                            type="file"
                            accept="image/png"
                            onChange={(e) => {
                              if (
                                e.target &&
                                e.target.files &&
                                e.target.files?.length > 0
                              ) {
                                setFile(e.target.files[0]);
                              } else {
                                setFile(null);
                              }
                            }}
                          ></input>
                        </ButtonWithModal>
                      </Col>
                      <Col>
                        <DeleteModal
                          buttonTitle="Delete badge"
                          modalTitle="Delete current badge"
                          agreeCallback={deleteBadge}
                          onlyIcon
                        ></DeleteModal>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <LiveValidationComponent validation={data.validation} />
                </Row>
                <Row>
                  <Col>
                    <h2 style={styles.secondaryText}>Details:</h2>
                  </Col>
                  <Col className="mb-3 text-end">
                    <ButtonWithStyle
                      buttonTitle={
                        data.publishable
                          ? "Revoke Publishable"
                          : "Make Publishable"
                      }
                      onClickCallback={() =>
                        setSkillPublishableState(!data.publishable)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EvolvedTable
                      data={multiLangData}
                      ignoreID
                      customHeaders={["language", "title", "subtitle"]}
                      saveAction={(editedData: object) => {
                        updateSkill(editedData);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <h2 style={styles.secondaryText}>Epic Steps:</h2>
                  </Col>
                  <Col className="mb-3 text-end">
                    <ButtonWithModal
                      buttonTitle="New Epic Step"
                      modalTitle="Upload new epic step"
                      cancelCallback={() => {}}
                      isModalWithForm={true}
                    >
                      <NewSkillStepForm
                        setError={setError}
                        refresh={getSkill}
                        setLoading={setLoading}
                        skillId={id}
                      />
                    </ButtonWithModal>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EvolvedTable
                      data={multiLangSkillStepsData}
                      ignoreID
                      customHeaders={[
                        "imageThumbnailUrl",
                        "title",
                        "explanation",
                      ]}
                      customHeadersText={[
                        "EPIC STEP THUMBNAIL",
                        "TITLE",
                        "EXPLANATION",
                      ]}
                      basePath="/skills/steps"
                      onClickImageCallback={setCarouselVisibility}
                      updateDataOrder={updateSkillStepOrder}
                      insertAfterForm={
                        <NewSkillStepForm
                          setError={setError}
                          refresh={getSkill}
                          setLoading={setLoading}
                          skillId={id}
                        />
                      }
                    />
                    <CarouselModal
                      data={multiLangSkillStepsData}
                      path={"imageThumbnailUrl"}
                      titlePath="title"
                      subtitlePath="explanation"
                      index={carouselIndex}
                      setIndex={setCarouselIndex}
                      showCarousel={showCarousel}
                      setCarouselVisibility={setCarouselVisibility}
                    />
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    gap: 32,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <div>
                    <MoveRequestModal
                      buttonTitle={`Move "${data.title[language]}" (category)`}
                      modalTitle={`Move "${data.title[language]}" epic to another category.`}
                      agreeCallback={moveSkill}
                    />
                  </div>
                  <div>
                    <CopyRequestModal
                      buttonTitle={`Copy "${data.title[language]}" (category)`}
                      modalTitle={`Copy "${data.title[language]}" epic to another category.`}
                      agreeCallback={copySkill}
                    />
                  </div>
                  <div>
                    <DeleteModal
                      icon={<DeleteIcon />}
                      buttonTitle={`Delete "${data.title[language]}"`}
                      modalTitle={`Delete "${data.title[language]}" epic.`}
                      agreeCallback={() => deleteSkill()}
                    />
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
