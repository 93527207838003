import {
  GetSkillStepResponse,
  GetSkillStepRequest,
  DeleteSkillStepRequest,
  UpdateSkillStepRequest,
  UpdateSkillStepOperationRequest,
  UpdateSkillStepImageRequest,
} from "@revolutionrobotics/admin-api";
import React, { useContext, useEffect, useState } from "react";
import { ErrorResponse, SetError } from "../../services/general";
import { Col, Container, Row } from "react-bootstrap";
import LanguageContext from "../../services/LanguageContext";
import { useParams } from "react-router-dom";
import { SkillApi } from "../../services/configuration";
import { useNavigate } from "react-router";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { EvolvedTable } from "../../components/Table";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { styles } from "../../styles/styles";
import { DeleteModal } from "../../components/DeleteModal";
import { ImageWithPlaceholder } from "../../components/ImageWithPlaceholder";
import { LiveValidationComponent } from "../../components/LiveValidationComponent";
import { ErrorState } from "../../components/ErrorState";

export type Params = {
  id: string;
};

interface MultiLangData {
  id: string;
  language: string;
  title: string;
  explanation: string;
}

export function SkillStepDetails() {
  const { id } = useParams<Params>();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({} as GetSkillStepResponse);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [multiLangData, setMultiLangData] = useState([] as MultiLangData[]);
  const [file, setFile] = useState<File | null>(null);
  const { language, setSystemLanguages } = useContext(LanguageContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("SkillDetails did mount.", id);
    getSkillStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, []);

  const getSkillStep = () => {
    console.log("getSkillStep()");
    if (!id) {
      return;
    }

    setLoading(true);

    const req: GetSkillStepRequest = {
      id: id,
    };

    SkillApi.getSkillStep(req)
      .then(
        (result: GetSkillStepResponse) => {
          setData(result);
          setSystemLanguages(result.systemLanguages);
          console.log(result);

          let titleAndExplanationData: MultiLangData[] = [];

          result.supportedLanguages.forEach((language) => {
            titleAndExplanationData.push({
              id: id,
              language: language.id,
              title: result.title[language.id],
              explanation: result.explanation[language.id],
            });
          });
          console.log(titleAndExplanationData);
          setMultiLangData(titleAndExplanationData);
        },
        (error: ErrorResponse) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const updateSkillStep = (data: any) => {
    console.log("updateSkill()");
    console.log(data);
    if (!id) {
      return;
    }

    let details: UpdateSkillStepRequest = {
      lang: data["language"],
      title: data["title"],
      explanation: data["explanation"],
    };
    console.log(details);
    let request: UpdateSkillStepOperationRequest = {
      id: id,
      request: details,
    };
    console.log(request);

    SkillApi.updateSkillStep(request)
      .then(
        (result) => {
          console.log(result, data.id, "skill step has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getSkillStep();
      });
  };

  const updateCover = () => {
    console.log("uploadSkillStepImage()", file?.name, id);

    if (!file || !id) {
      return;
    }

    const req: UpdateSkillStepImageRequest = {
      id: id,
      file: file,
    };

    SkillApi.updateSkillStepImage(req)
      .then(
        () => {
          console.log(id, "step image has been updated.");
          window.location.reload();
        },
        (error) => {
          SetError(error, setError);
        }
      );
  };

  const deleteSkilldStep = () => {
    console.log("deleteSkillStep() " + id);

    if (!id) {
      return;
    }
    const request: DeleteSkillStepRequest = {
      id: id,
    };

    SkillApi.deleteSkillStep(request)
      .then(
        (result) => {
          console.log(result, id, " step has been deleted.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        navigate(`/skills`);
      });
  };

  return (
    <>
      {error && (
        <ErrorState refresh={getSkillStep} error={error} setError={setError} />
      )}

      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row className="mb-3">
                  <Col
                    className="col-3 justify-content-around"
                    style={{ textAlign: "center" }}
                  >
                    <Row>
                      <Col>
                        <ImageWithPlaceholder
                          src={data.imageThumbnailUrl}
                          alt={data.title ? data.title[language] : undefined}
                          className="img-thumbnail"
                          style={styles.coverImage}
                          shouldOpenNewTab={true}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <ButtonWithModal
                          buttonTitle="Upload cover"
                          modalTitle="Upload new image for epic step"
                          agreeCallback={() => {
                            updateCover();
                          }}
                          cancelCallback={() => {}}
                        >
                          <input
                            type="file"
                            accept="image/png"
                            onChange={(e) => {
                              if (
                                e.target &&
                                e.target.files &&
                                e.target.files?.length > 0
                              ) {
                                setFile(e.target.files[0]);
                              } else {
                                setFile(null);
                              }
                            }}
                          ></input>
                        </ButtonWithModal>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-9">
                    <Row className="mt-2">
                      <Col>
                        <h1
                          className="mx-auto mb-1 text-center"
                          style={styles.pageTitle}
                        >
                          {data.title[language]}
                        </h1>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <LiveValidationComponent validation={data.validation} />
                </Row>
                <Row className="mb-3">
                  <EvolvedTable
                    data={multiLangData}
                    ignoreID
                    customHeaders={["language", "title", "explanation"]}
                    saveAction={(editedData: object) => {
                      updateSkillStep(editedData);
                    }}
                  />
                </Row>
                <Col className="text-end">
                  <DeleteModal
                    icon={<DeleteIcon />}
                    buttonTitle={`Delete "${data.title[language]}"`}
                    modalTitle={`Delete "${data.title[language]}" step.`}
                    agreeCallback={() => deleteSkilldStep()}
                  />
                </Col>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
