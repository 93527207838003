import React, { useEffect } from "react";
import { Carousel, Modal } from "react-bootstrap";
import { ImageWithPlaceholder } from "./ImageWithPlaceholder";
import { styles } from "../styles/styles";
interface CarouselModalProps {
  data: any[];
  path: string;
  index: number;
  showCarousel: boolean;
  setCarouselVisibility: Function;
  setIndex: Function;
  titlePath?: string;
  subtitlePath?: string;
}

export function CarouselModal({
  data,
  path,
  index,
  showCarousel,
  setCarouselVisibility,
  setIndex,
  titlePath,
  subtitlePath,
}: CarouselModalProps) {
  const onSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const goBack = () => {
    setIndex(index - 1 < 0 ? data.length - 1 : index - 1);
  };

  const goNext = () => {
    setIndex(index + 1 < data.length ? index + 1 : 0);
  };

  const useKeyPress = () => {
    const upHandler = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        goBack();
      }
      if (event.key === "ArrowRight") {
        goNext();
      }
    };

    useEffect(() => {
      window.addEventListener("keyup", upHandler);
      return () => {
        window.removeEventListener("keyup", upHandler);
      };
      // eslint-disable-next-line no-sparse-arrays, react-hooks/exhaustive-deps
    }, [, index]);
  };

  useKeyPress();

  return (
    <Modal
      show={showCarousel}
      onHide={() => {
        setCarouselVisibility(false);
      }}
      size="lg"
      centered
      style={styles.carouselModal}
    >
      <Carousel
        variant="light"
        onSelect={onSelect}
        defaultActiveIndex={index}
        activeIndex={index}
        interval={null}
        keyboard={false}
        controls={true}
        slide={false}
      >
        {data.map((row, index) => (
          <Carousel.Item style={styles.carouselItem} key={index}>
            <ImageWithPlaceholder
              src={row[path]}
              className="img-thumbnail mx-auto"
              style={styles.carouselImage}
            />

            <div className="mb-5">
              {titlePath && <h3>{row[titlePath]}</h3>}
              {subtitlePath && <p>{row[subtitlePath]}</p>}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </Modal>
  );
}
