import {
  Configuration,
  SkillApi as SkillApiDocs,
  AvatarApi as AvatarApiDocs,
  UserApi as UserApiDocs,
  HeroRobotApi as HeroApiDocs,
  ChallengeApi as ChallengeApiDocs,
  LanguageApi as LanguageApiDocs,
  RobotSoftwareApi as RobotSoftwareApiDocs,
  OrganizationApi as OrganizationApiDocs,
  AuthenticationApi as AuthenticationApiDocs,
  LessonApi as LessonApiDocs,
  MoveApi as MoveApiDocs,
  CopyApi as CopyApiDocs,
  UserGroupApi as UserGroupApiDocs,
  ExportApi as ExportApiDocs,
} from "@revolutionrobotics/admin-api";

function getBasePath(): string {
  switch (window.location.origin) {
    case "https://admin.revolutionrobotics.org":
      return "https://api.revolutionrobotics.org/cms/v1";
    case "https://test.rr.scapps.io":
      return "https://api.test.rr.scapps.io/cms/v1";
    default:
      return "http://127.0.0.1:34782/cms/v1";
  }
}

const config = new Configuration({
  basePath: getBasePath(),
  credentials: "include",
  middleware: [
    {
      post(context) {
        let header = context.response.headers.get("Robotics-Auth-Info");
        if (header) {
          document.cookie = "Auth_Info=" + header + "; path=/;";
        }
        return new Promise((resolve) => {
          resolve(context.response);
        });
      },
    },
  ],
});

export const SkillApi = new SkillApiDocs(config);
export const AvatarApi = new AvatarApiDocs(config);
export const UserApi = new UserApiDocs(config);
export const HeroApi = new HeroApiDocs(config);
export const ChallengeApi = new ChallengeApiDocs(config);
export const LanguageApi = new LanguageApiDocs(config);
export const RobotSoftwareApi = new RobotSoftwareApiDocs(config);
export const OrganizationApi = new OrganizationApiDocs(config);
export const AuthenticationApi = new AuthenticationApiDocs(config);
export const MoveApi = new MoveApiDocs(config);
export const CopyApi = new CopyApiDocs(config);
export const LessonApi = new LessonApiDocs(config);
export const UserGroupApi = new UserGroupApiDocs(config);
export const ExportApi = new ExportApiDocs(config);
