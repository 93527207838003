import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { styles } from "../styles/styles";
import { ImageWithDelete } from "../components/ImageWithDelete";
import { ButtonWithModal } from "./ButtonWithModal";
import SourceIcon from "@mui/icons-material/Source";

interface GridTableProps {
  data: any[];
  imagePath: string;
  setOrder?: Function;
  setCarouselVisibility?: Function;
  deleteItemCallback?: Function;
  containerStyle: Object;
  insertAfterForm?: JSX.Element;
  showItemNumber?: boolean;
}

export function GridTable({
  data,
  imagePath,
  setOrder,
  setCarouselVisibility,
  deleteItemCallback,
  containerStyle,
  insertAfterForm,
  showItemNumber,
}: GridTableProps) {
  const [dragList, setDragList] = useState(data);
  const [dragging, setDragging] = useState(false);
  const [onHover, setOnHover] = useState("");
  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);

  useEffect(() => {
    setDragList(data);
  }, [data]);

  const handleDragStart = (index: number) => {
    setDragging(true);
    dragItem.current = index;
  };

  const handleDragEnter = (index: number) => {
    dragOverItem.current = index;
    let _data = JSON.parse(JSON.stringify(data));
    const draggedItemContent = _data.splice(dragItem.current, 1)[0];
    _data.splice(dragOverItem.current, 0, draggedItemContent);

    setDragList(_data);
  };

  const handleSort = () => {
    setDragging(false);
    if (
      dragItem.current === null ||
      dragOverItem.current === null ||
      !setOrder
    ) {
      return;
    }

    dragItem.current = null;
    dragOverItem.current = null;

    let ids: string[] = [];
    for (let i = 0; i < dragList.length; i++) {
      ids.push(dragList[i].id);
    }
    setOrder(ids);
  };

  const getDragStyle = (index: number) => {
    if (index === dragOverItem.current) {
      return {
        ...containerStyle,
        ...{ visibility: "hidden" },
      };
    }
    return containerStyle;
  };

  return dragList.length === 0 ? (
    <div style={styles.tableEmptyState}>
      <SourceIcon style={styles.tableEmptyStateIcon} />
      <p>No data to display.</p>
      <p>Click Add new button to create new items.</p>
    </div>
  ) : (
    <>
      <Row
        className="justify-content-md-left "
        onDragOver={(e) => e.preventDefault()}
      >
        {dragList.map((row, index) => (
          <Col
            style={getDragStyle(index)}
            key={index}
            draggable={setOrder ? true : false}
            onDragStart={() => {
              handleDragStart(index);
            }}
            onDragEnter={() => {
              handleDragEnter(index);
            }}
            onDragEnd={handleSort}
            onMouseEnter={() => {
              setOnHover(row.id + "-" + index);
            }}
            onMouseLeave={() => {
              setOnHover("");
            }}
          >
            {showItemNumber ? (
              <div style={styles.OrderNumberText}>{index + 1}</div>
            ) : undefined}
            <ImageWithDelete
              src={row[imagePath]}
              id={`${row.id}-${index}`}
              index={index}
              onClickCallback={setCarouselVisibility}
              deleteAction={(id: string) => {
                if (deleteItemCallback) {
                  deleteItemCallback(id);
                }
              }}
              allowDeleteAll
              onlyIcon
            />
            {insertAfterForm && (
              <ButtonWithModal
                modalTitle={"Insert new element"}
                onlyIcon
                style={{
                  ...styles.gridTableInsertAfterButton,
                  display:
                    onHover === `${row.id}-${index}` && !dragging
                      ? "block"
                      : "none",
                }}
                isModalWithForm={true}
              >
                {React.cloneElement(insertAfterForm, {
                  insertAfterId: row.id,
                })}
              </ButtonWithModal>
            )}
          </Col>
        ))}
      </Row>
    </>
  );
}
