import React from "react";
import { Button } from "react-bootstrap";
import { styles } from "../styles/styles";

interface ButtonWithStyleProps {
  buttonTitle?: string;
  type?: string;
  icon?: JSX.Element;
  variant?: string;
  disabled?: boolean;
  onClickCallback?: Function;
  isActionButton?: boolean;
  customStyle?: Object;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
}

export function ButtonWithStyle({
  buttonTitle,
  type,
  icon,
  variant,
  onClickCallback,
  disabled,
  isActionButton,
  customStyle,
  onMouseEnter,
  onMouseLeave,
}: ButtonWithStyleProps) {
  const buttonType = () => {
    switch (type) {
      case "submit":
        return "submit";
      case "reset":
        return "reset";
      default:
        return "button";
    }
  };
  const variantStyle = () => {
    switch (variant) {
      case "primary":
        return styles.primaryButton;
      case "secondary":
        return styles.secondaryButton;
      case "danger":
        return styles.dangerButton;
      default:
        return styles.button;
    }
  };
  const getWidth = () => {
    return isActionButton ? styles.actionButtonWidth : styles.buttonWidth;
  };

  return (
    <>
      <Button
        variant={variant ? variant : "primary"}
        onClick={() => {
          if (onClickCallback) {
            onClickCallback();
          }
        }}
        disabled={disabled}
        style={Object.assign({}, getWidth(), variantStyle(), customStyle)}
        type={buttonType()}
        onMouseEnter={() => {
          if (onMouseEnter) onMouseEnter();
        }}
        onMouseLeave={() => {
          if (onMouseLeave) onMouseLeave();
        }}
      >
        {icon ? (
          <>
            {icon} <br />
          </>
        ) : null}
        {buttonTitle ? buttonTitle : ""}
      </Button>
    </>
  );
}
