import React, { useState } from "react";
import { styles } from "../styles/styles";
import { ComplexActionBar } from "./ComplexActionBar";
import { ImageWithPlaceholder } from "./ImageWithPlaceholder";

interface ImageWithDeleteProps {
  src: string;
  id: string;
  index: number;
  deleteAction?: Function;
  allowDeleteAll?: boolean;
  onClickCallback?: Function;
  onlyIcon?: boolean;
}

export function ImageWithDelete({
  src,
  id,
  index,
  deleteAction,
  onClickCallback,
  onlyIcon,
}: ImageWithDeleteProps) {
  const [isImageHovering, setIsImageHovering] = useState(false);
  const [isButtonHovering, setIsButtonHovering] = useState(false);

  const onDelete = (id: string) => {
    if (!deleteAction) return;
    deleteAction(id);
  };

  const handleMouseOverImage = () => {
    setIsImageHovering(true);
  };

  const handleMouseOutImage = () => {
    setIsImageHovering(false);
  };

  const handleMouseOverButton = () => {
    setIsButtonHovering(true);
  };

  const handleMouseOutButton = () => {
    setIsButtonHovering(false);
  };

  const onClickImage = () => {
    if (!isButtonHovering && onClickCallback) {
      onClickCallback(true, index);
    }
  };

  const renderDeleteActions = (id: string) => {
    return (
      <ComplexActionBar
        id={id}
        onDelete={onDelete}
        editMode={false}
        setEditMode={() => {}}
        onlyIcon={onlyIcon}
      />
    );
  };

  return (
    <div
      onClick={() => {
        onClickImage();
      }}
      onMouseOver={handleMouseOverImage}
      onMouseOut={handleMouseOutImage}
      style={{ position: "relative" }}
    >
      <ImageWithPlaceholder src={src} className="img-thumbnail mx-auto" />
      {isImageHovering && (
        <div
          onMouseOver={handleMouseOverButton}
          onMouseOut={handleMouseOutButton}
          style={styles.imageDeleteIconContainer}
        >
          {renderDeleteActions(id)}
        </div>
      )}
    </div>
  );
}
