import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { FormItem } from "../FormItem";
interface ForgotPasswordProps {
  setInputs: Function;
}

export function ForgotPasswordForm({ setInputs }: ForgotPasswordProps) {
  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values: any) => ({ ...values, [name]: value }));
  };

  return (
    <Form>
      <Row Row className="mb-3">
        <Col>
          <Row className="mt-2">
            <FormItem
              title={"Email"}
              type={"text"}
              required={true}
              name={"email"}
              handleChange={handleChange}
            />
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
