import {
  UpdateSkillCategoryOrderRequest,
  UpdateSkillCategoryOrderOperationRequest,
  GetSkillCategoriesResponse,
  GetSkillCategoriesCategoriesRow,
} from "@revolutionrobotics/admin-api";
import React, { useEffect, useState, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { NewSkillCategoryForm } from "../../components/Skill/NewSkillCategoryForm";
import { EvolvedTable } from "../../components/Table";
import { SkillApi } from "../../services/configuration";
import { ErrorResponse, SetError } from "../../services/general";
import { ErrorState } from "../../components/ErrorState";
import { styles } from "../../styles/styles";
import LanguageContext from "../../services/LanguageContext";

export function Skills() {
  const [tableData, setTableData] = useState(
    [] as GetSkillCategoriesCategoriesRow[]
  );
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const { setSystemLanguages } = useContext(LanguageContext);

  const getSkills = () => {
    console.log("getSkills()");
    setLoading(true);

    SkillApi.getSkillCategories()
      .then(
        (result: GetSkillCategoriesResponse) => {
          setTableData(result.categories.rows);
          setSystemLanguages(result.systemLanguages);
          console.log(result);
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const updateSkillCategoriesOrder = (ids: string[]) => {
    console.log("updateSkillCategoriesOrder()");

    const request: UpdateSkillCategoryOrderRequest = {
      ids: ids,
    };
    const req: UpdateSkillCategoryOrderOperationRequest = {
      request: request,
    };

    SkillApi.updateSkillCategoryOrder(req)
      .then(
        () => {
          console.log("Skill category order has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getSkills();
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Skill Categories did mount.");
    getSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {error && (
        <ErrorState refresh={getSkills} error={error} setError={setError} />
      )}
      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row>
                  <Col>
                    <h1
                      className="mx-auto mb-3 text-center text-uppercase"
                      style={styles.pageTitle}
                    >
                      Epics
                    </h1>
                  </Col>
                </Row>
                <Row
                  className="mb-3 text-center "
                  xs={"auto"}
                  style={{ alignItems: "center" }}
                >
                  <Col>
                    <ButtonWithModal
                      buttonTitle={"Add new"}
                      modalTitle={"Add new epic category"}
                      isModalWithForm={true}
                    >
                      <NewSkillCategoryForm
                        setError={setError}
                        refresh={getSkills}
                      />
                    </ButtonWithModal>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2
                      className="mx-auto mb-1 text-uppercase"
                      style={styles.secondaryText}
                    >
                      Epic categories
                    </h2>
                    <EvolvedTable
                      data={tableData}
                      basePath="category"
                      customHeaders={["coverThumbnailUrl", "title", "live"]}
                      customHeadersText={["EPIC THUMBNAIL", "TITLE", "LIVE"]}
                      updateDataOrder={updateSkillCategoriesOrder}
                      imageShouldOpenNewTab={true}
                      insertAfterForm={
                        <NewSkillCategoryForm
                          setError={setError}
                          refresh={getSkills}
                        />
                      }
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
