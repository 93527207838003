import {
  GetLessonsResponseRow,
  GetUserGroupsResponse,
} from "@revolutionrobotics/admin-api";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { ErrorState } from "../../components/ErrorState";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { EvolvedTable } from "../../components/Table";
import { NewUserGroup } from "../../components/UserGroups/NewUserGroup";
import { UserGroupApi } from "../../services/configuration";
import { ErrorResponse, SetError } from "../../services/general";
import { styles } from "../../styles/styles";

export function UserGroups() {
  const [data, setData] = useState([] as GetLessonsResponseRow[]);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [loading, setLoading] = useState(true);

  const getUserGroups = () => {
    console.log("getUserGroups()");
    setLoading(true);

    UserGroupApi.getUserGroups()
      .then(
        (result: GetUserGroupsResponse) => {
          setData(result.rows);
          console.log(result);
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("User groups did mount.");
    getUserGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {error && (
        <ErrorState refresh={getUserGroups} error={error} setError={setError} />
      )}
      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row>
                  <Col>
                    <h1
                      className="mx-auto mb-3 text-center text-uppercase"
                      style={styles.pageTitle}
                    >
                      User groups
                    </h1>
                  </Col>
                </Row>
                <Row
                  className="mb-3 text-center "
                  xs={"auto"}
                  style={{ alignItems: "center" }}
                >
                  <Col>
                    <ButtonWithModal
                      buttonTitle={"Add new"}
                      modalTitle={"Add new user group"}
                      isModalWithForm={true}
                    >
                      <NewUserGroup
                        setError={setError}
                        refresh={getUserGroups}
                      />
                    </ButtonWithModal>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EvolvedTable
                      data={data}
                      basePath="group"
                      imageShouldOpenNewTab={true}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
