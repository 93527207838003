import {
  CreateHeroRobotOperationRequest,
  CreateHeroRobotRequest,
  CreateHeroRobotResponse,
  UpdateHeroRobotCoverRequest,
} from "@revolutionrobotics/admin-api";
import React, { useState, useContext } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { HeroApi } from "../../services/configuration";
import { SetError } from "../../services/general";
import { ButtonWithStyle } from "../ButtonWithStyle";
import { LanguageSelector } from "../Language/LanguageSelector";
import { FormItem } from "../FormItem";
import LanguageContext from "../../services/LanguageContext";

interface HeroFormProps {
  insertAfterId?: string;
  setError: Function;
  refresh: Function;
}

export function NewHeroForm({
  setError,
  refresh,
  insertAfterId,
}: HeroFormProps) {
  const [validated, setValidated] = useState(false);
  const [inputs, setInputs] = useState<any | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const { language } = useContext(LanguageContext);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (!inputs || !inputs.name || !inputs.file) return;

    const details: CreateHeroRobotRequest = {
      description: inputs.description,
      lang: language,
      name: inputs.name,
    };
    const req: CreateHeroRobotOperationRequest = {
      request: details,
      insertAfterID: insertAfterId || undefined,
    };

    HeroApi.createHeroRobot(req)
      .then(
        (response: CreateHeroRobotResponse) => {
          console.log(response.id, "has been created.");
          uploadHeroRobotImage(response.id);
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {});
  };

  const uploadHeroRobotImage = (id: string) => {
    console.log("uploadHeroRobotImage()", file?.name);

    if (!file || !id) {
      return;
    }

    const req: UpdateHeroRobotCoverRequest = {
      id: id,
      file: file,
    };

    HeroApi.updateHeroRobotCover(req)
      .then(
        () => {
          console.log(id, "cover has been updated.");
        },
        (error) => {
          SetError(error, setError);
          refresh();
        }
      )
      .finally(() => {
        setFile(null);
        refresh();
      });
  };

  const handleFileUpload = (event: any) => {
    if (event.target && event.target.files && event.target.files?.length > 0) {
      setFile(event.target.files[0]);
    } else {
      setFile(null);
    }
  };

  const handleChange = (event: any) => {
    if (event.target.name === "file") {
      handleFileUpload(event);
    }
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values: any) => ({ ...values, [name]: value }));
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col>
          <Row className="mb-3">
            <Form.Label>Language</Form.Label>
            <LanguageSelector />
          </Row>
          <Row>
            <FormItem
              title={"Title"}
              type={"text"}
              required={true}
              name={"name"}
              handleChange={handleChange}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"Description"}
              type={"text"}
              name={"description"}
              handleChange={handleChange}
              isTextArea={true}
            />
          </Row>
          <Row className="mt-2">
            <FormItem
              title={"Cover"}
              type={"file"}
              required={true}
              name={"file"}
              handleChange={handleChange}
              fileAccept="image/jpeg"
            />
          </Row>
        </Col>
      </Row>

      <ButtonWithStyle buttonTitle="Create" type="submit" variant="primary" />
    </Form>
  );
}
