import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { ButtonWithStyle } from "./ButtonWithStyle";
import { styles } from "../styles/styles";

interface ButtonWithModalProps {
  buttonTitle?: string;
  modalTitle?: string;
  icon?: JSX.Element;
  variant?: string;
  disabled?: boolean;
  onlyIcon?: boolean;
  agreeCallback?: Function;
  cancelCallback?: Function;
  style?: object;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  children: React.ReactNode | React.ReactNode[];
  isModalWithForm?: boolean;
  withoutIcon?: boolean;
  customModalWidth?: number;
}

export function ButtonWithModal({
  buttonTitle,
  icon,
  variant,
  modalTitle,
  onlyIcon,
  agreeCallback,
  cancelCallback,
  children,
  style,
  onMouseEnter,
  onMouseLeave,
  disabled,
  isModalWithForm,
  withoutIcon,
  customModalWidth = 500,
}: ButtonWithModalProps) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);

    if (cancelCallback) cancelCallback();
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <ButtonWithStyle
        // className="p-3 "
        variant={variant ? variant : "primary"}
        onClickCallback={() => {
          handleShow();
          console.log(`${modalTitle}`);
        }}
        buttonTitle={!onlyIcon ? buttonTitle : ""}
        icon={
          !withoutIcon ? (
            icon ? (
              <>{icon}</>
            ) : (
              <AddCircleOutlineIcon />
            )
          ) : undefined
        }
        disabled={disabled}
        isActionButton={onlyIcon ? true : false}
        customStyle={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <Modal show={show} onHide={handleClose}>
        {/** There is needed to set modal width in inline style, because in some case we have to set custom width of the modal body */}
        <div style={{ width: customModalWidth, alignSelf: "center" }}>
          <Modal.Header
            style={styles.buttonWithModalHeader}
            closeButton
            closeVariant="white"
          >
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={styles.buttonWithModalBody}>
            <Container>
              <Row>
                <Col>{children}</Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer style={styles.buttonWithModalFooter}>
            <ButtonWithStyle
              buttonTitle="Cancel"
              variant="secondary"
              onClickCallback={() => {
                handleClose();
                if (cancelCallback) cancelCallback();
              }}
              customStyle={
                isModalWithForm ? styles.modalFormCancelButton : undefined
              }
            />
            {agreeCallback && (
              <ButtonWithStyle
                buttonTitle={buttonTitle}
                variant="primary"
                onClickCallback={() => {
                  handleClose();
                  if (agreeCallback) agreeCallback();
                }}
              />
            )}
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
