import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { ButtonWithStyle } from "./ButtonWithStyle";
import { styles } from "../styles/styles";

interface SubmissionModalProps {
  model: any;
  show: boolean;
  setShow: any;
  modalTitle?: string;
}

export function ModelDetailsModal({
  model,
  show,
  setShow,
  modalTitle,
}: SubmissionModalProps) {
  const handleClose = () => {
    setShow(false);
  };

  const programs = (model && model.programs) || [];

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header
          style={styles.buttonWithModalHeader}
          closeButton
          closeVariant="white"
        >
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={styles.buttonWithModalBody}>
          <Container>
            <Row style={{ display: "flex" }}>
              <Col style={{ width: "50%" }}>
                <pre>{JSON.stringify(model, null, 2)}</pre>
              </Col>
              <Col style={{ width: "50%" }}>
                {programs.map((program: any) => (
                  <Row key={program.id} style={{ marginBottom: "40px" }}>
                    <h3 style={styles.pageTitle}>{program.name}</h3>
                    <pre> {atob(program.python)}</pre>
                  </Row>
                ))}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer style={styles.buttonWithModalFooter}>
          <ButtonWithStyle
            buttonTitle="Cancel"
            variant="secondary"
            onClickCallback={handleClose}
            customStyle={styles.modalFormCancelButton}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}
