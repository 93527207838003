import { LanguageModel } from "@revolutionrobotics/admin-api";
import React from "react";

interface LanguageContextState {
  language: string;
  setLanguage: Function;
  systemLanguages: LanguageModel[];
  setSystemLanguages: Function;
}
const LanguageContext = React.createContext({} as LanguageContextState);

export default LanguageContext;
