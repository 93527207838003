import React from "react";
import { ButtonWithModal } from "./ButtonWithModal";
import DeleteIcon from "@mui/icons-material/Delete";

interface DeleteModalProps {
  icon?: JSX.Element;
  buttonTitle?: string;
  modalTitle?: string;
  modalText?: string;
  agreeCallback: Function;
  cancelCallback?: Function;
  onlyIcon?: boolean;
  isPermanent?: boolean;
}

export function DeleteModal({
  icon,
  buttonTitle,
  modalTitle,
  modalText,
  agreeCallback,
  cancelCallback,
  onlyIcon = false,
  isPermanent = true,
}: DeleteModalProps) {
  return (
    <>
      <ButtonWithModal
        icon={icon ? icon : <DeleteIcon />}
        variant="danger"
        buttonTitle={buttonTitle ? buttonTitle : ""}
        modalTitle={modalTitle ? modalTitle : "Delete"}
        agreeCallback={() => agreeCallback()}
        cancelCallback={() => {
          if (cancelCallback) cancelCallback();
        }}
        onlyIcon={onlyIcon}
      >
        <h3>
          Are you sure you want to do this? <br />
          {isPermanent && (
            <span className="text-danger fw-bold">
              This action is permanent and can not be undone!
            </span>
          )}
          {modalText && <span>{modalText}</span>}
        </h3>
      </ButtonWithModal>
    </>
  );
}
