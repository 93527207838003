import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { ButtonWithStyle } from "./ButtonWithStyle";
import { styles } from "../styles/styles";

interface SearchBarProps {
  setTextCallback: Function;
  onClickCallback: Function;
  value: string;
  placeholder?: string;
}

export function SearchBar({
  setTextCallback,
  onClickCallback,
  value,
  placeholder,
}: SearchBarProps) {
  return (
    <>
      <input
        placeholder={placeholder}
        className="p-2"
        style={styles.searchBarInput}
        onChange={(event) => {
          setTextCallback(event.target.value);
        }}
        defaultValue={value}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            onClickCallback();
          }
        }}
      />
      <ButtonWithStyle
        buttonTitle=""
        variant="primary"
        icon={<SearchIcon />}
        isActionButton={true}
        onClickCallback={onClickCallback}
        customStyle={styles.searchBarButton}
      />
    </>
  );
}
