import {
  GetLessonsResponse,
  GetLessonsResponseRow,
} from "@revolutionrobotics/admin-api";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { ErrorState } from "../../components/ErrorState";
import { NewLessonForm } from "../../components/Lessons/NewLessonForm";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { EvolvedTable } from "../../components/Table";
import { LessonApi } from "../../services/configuration";
import { ErrorResponse, SetError } from "../../services/general";
import { styles } from "../../styles/styles";

export function Lessons() {
  const [data, setData] = useState([] as GetLessonsResponseRow[]);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [loading, setLoading] = useState(true);

  const getLessons = () => {
    console.log("getLessons()");
    setLoading(true);

    LessonApi.getLessons()
      .then(
        (result: GetLessonsResponse) => {
          setData(result.rows);
          console.log(result);
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Lessons did mount.");
    getLessons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {error && (
        <ErrorState refresh={getLessons} error={error} setError={setError} />
      )}
      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row>
                  <Col>
                    <h1
                      className="mx-auto mb-3 text-center text-uppercase"
                      style={styles.pageTitle}
                    >
                      Lessons
                    </h1>
                  </Col>
                </Row>
                <Row
                  className="mb-3 text-center "
                  xs={"auto"}
                  style={{ alignItems: "center" }}
                >
                  <Col>
                    <ButtonWithModal
                      buttonTitle={"Add new"}
                      modalTitle={"Add new lesson"}
                      isModalWithForm={true}
                    >
                      <NewLessonForm setError={setError} refresh={getLessons} />
                    </ButtonWithModal>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EvolvedTable
                      data={data}
                      basePath="lesson"
                      imageShouldOpenNewTab={true}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
