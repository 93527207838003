import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router";
import { Menu } from "./Menu";
import { TopMenuBar } from "./TopMenuBar";
import { styles } from "../styles/styles";

export function Layout() {
  return (
    <Container fluid>
      <Row>
        <Col
          xs={2}
          xl={1}
          className="position-fixed px-1"
          style={styles.menuContainer}
        >
          <Menu></Menu>
        </Col>
        <Col xs={{ span: 10, offset: 2 }} xl={{ span: 11, offset: 1 }}>
          <TopMenuBar />
        </Col>
        <Col
          xs={{ span: 10, offset: 2 }}
          xl={{ span: 11, offset: 1 }}
          className="p-2 pt-3"
          style={styles.pageContainer}
        >
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
}
