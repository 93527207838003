import { LanguageModel } from "@revolutionrobotics/admin-api";
import React, { useContext, useEffect } from "react";
import { Dropdown, Row, Col } from "react-bootstrap";
import { styles } from "../../styles/styles";
import LanguageContext from "../../services/LanguageContext";

export function LanguageSelector() {
  const { language, setLanguage, systemLanguages } =
    useContext(LanguageContext);

  useEffect(() => {
    console.log("Language selector did mount.");
    getSelectedLanguageName();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectedLanguageName = () => {
    let langItem = systemLanguages.find(
      (item: LanguageModel) => item.id === language
    );
    if (langItem?.name && language) {
      return langItem.name;
    } else {
      return "-";
    }
  };

  return (
    <Row className="align-items-center">
      <Col className="col-5" style={styles.secondaryText}>
        {" "}
        language:{" "}
      </Col>
      <Col className="col-7">
        <Dropdown>
          <Dropdown.Toggle
            style={styles.tableDropDown}
            variant="warning"
            id="dropdown-basic"
          >
            {getSelectedLanguageName()}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {systemLanguages.map((lang: LanguageModel) => (
              <Dropdown.Item
                key={lang.id}
                onClick={() => {
                  setLanguage(lang.id);
                }}
              >
                {lang.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}
