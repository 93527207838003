import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { ButtonWithStyle } from "../components/ButtonWithStyle";
import { useNavigate } from "react-router";
interface ErrorStateProps {
  refresh: Function;
  error: any;
  setError: Function;
}

export function ErrorState({ refresh, error, setError }: ErrorStateProps) {
  const navigate = useNavigate();
  useEffect(() => {
    if (error.code === 401) {
      navigate("/login");
    }
    // eslint-disable-next-line no-sparse-arrays, react-hooks/exhaustive-deps
  }, [, error.code]);

  return (
    <Alert variant="danger">
      <p>{error.code}</p>
      <p>
        {error.errorCode}
        {error.info != null ? " :" + JSON.stringify(error.info) : null}
      </p>
      <ButtonWithStyle
        buttonTitle="Close"
        onClickCallback={() => {
          refresh();
          setError(null);
        }}
      />
    </Alert>
  );
}
