import React, { useState } from "react";
import { ButtonWithModal } from "./ButtonWithModal";
import EditIcon from "@mui/icons-material/Edit";
import { Form, Row, Col } from "react-bootstrap";
import { FormItem } from "./FormItem";

interface MoveRequestModalProps {
  icon?: JSX.Element;
  buttonTitle?: string;
  modalTitle?: string;
  agreeCallback: Function;
  cancelCallback?: Function;
}

export function MoveRequestModal({
  icon,
  buttonTitle,
  modalTitle,
  agreeCallback,
  cancelCallback,
}: MoveRequestModalProps) {
  const [moveId, setMoveId] = useState("");

  const handleChange = (event: any) => {
    setMoveId(event.target.value);
  };

  return (
    <>
      <ButtonWithModal
        icon={icon ? icon : <EditIcon />}
        variant="secondary"
        buttonTitle={buttonTitle ? buttonTitle : ""}
        modalTitle={modalTitle ? modalTitle : "Move"}
        agreeCallback={() => {
          agreeCallback(moveId);
        }}
        cancelCallback={() => {
          if (cancelCallback) cancelCallback();
        }}
      >
        <Row>
          <Col>
            <Row>
              <h3>
                Are you sure you want to do this? <br />
                <span className=" text-danger fw-bold">
                  This action is permanent and can not be undone!
                </span>
              </h3>
            </Row>
            <Row>
              <Form noValidate>
                <Row className="mb-3">
                  <Col>
                    <Row>
                      <FormItem
                        title={"Id"}
                        type={"text"}
                        required={true}
                        name={"id"}
                        handleChange={handleChange}
                      />
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Col>
        </Row>
      </ButtonWithModal>
    </>
  );
}
