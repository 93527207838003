import {
  CreateAvatarRequest,
  DeleteAvatarRequest,
  GetAvatarsResponse,
  CreateAvatarResponse,
  GetAvatarsRow,
  UpdateAvatarsOrderOperationRequest,
  UpdateAvatarsOrderRequest,
} from "@revolutionrobotics/admin-api";
import { AvatarApi } from "../../services/configuration";

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ErrorResponse, SetError } from "../../services/general";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { CarouselModal } from "../../components/CarouselModal";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { styles } from "../../styles/styles";
import { GridTable } from "../../components/GridTable";
import { ErrorState } from "../../components/ErrorState";

export function Avatars() {
  const [showCarousel, setShowCarousel] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [tableData, setTableData] = useState([] as GetAvatarsRow[]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [file, setFile] = useState<Array<File> | null>(null);

  const getAvatars = () => {
    console.log("getAvatars()");
    setLoading(true);

    AvatarApi.getAvatars()
      .then(
        (result: GetAvatarsResponse) => {
          setTableData(result.avatars.rows);
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        console.log(tableData);
        setLoading(false);
      });
  };

  const deleteAvatar = (id: string) => {
    console.log("deleteAvatar()");
    const request: DeleteAvatarRequest = {
      id: id,
    };

    AvatarApi.deleteAvatar(request)
      .then(
        () => {},
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getAvatars();
      });
  };

  const uploadAvatar = () => {
    console.log("uploadAvatar()");
    if (!file || !file[0]) {
      return;
    }
    setLoading(true);
    const request: CreateAvatarRequest = {
      file: file[0],
    };

    AvatarApi.createAvatar(request)
      .then(
        (result: CreateAvatarResponse) => {
          console.log(result.id, "has been created.");
        },
        (error) => {
          setLoading(false);
          SetError(error, setError);
        }
      )
      .finally(() => {
        if (file.length > 1) {
          setFile(file.splice(0, 1));
          uploadAvatar();
        } else {
          setLoading(false);
          setFile(null);
          getAvatars();
        }
      });
  };

  const updateAvatarsOrder = (ids: string[]) => {
    console.log("uploadAvatar()");
    if (!ids) {
      return;
    }
    const requestData: UpdateAvatarsOrderRequest = {
      ids: ids,
    };

    const request: UpdateAvatarsOrderOperationRequest = {
      request: requestData,
    };

    AvatarApi.updateAvatarsOrderRaw(request)
      .then(
        () => {
          console.log("Avatars order has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getAvatars();
      });
  };

  const setCarouselVisibility = (value: boolean, index: any) => {
    if (index !== null || index !== undefined) {
      setCarouselIndex(index);
    }
    setShowCarousel(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Avatars did mount.");
    getAvatars();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {error && (
        <ErrorState refresh={getAvatars} error={error} setError={setError} />
      )}
      <Container fluid>
        <Row>
          <Col>
            <h1
              className="mx-auto mb-3 text-center text-uppercase"
              style={styles.pageTitle}
            >
              Avatars
            </h1>
          </Col>
        </Row>
        <Row className="mb-3 p-2">
          <Col>
            <ButtonWithModal
              buttonTitle={"Add new"}
              modalTitle={"Add new avatar."}
              agreeCallback={() => uploadAvatar()}
            >
              <input
                type="file"
                multiple
                accept="image/png"
                onChange={(e) => {
                  if (
                    e.target &&
                    e.target.files &&
                    e.target.files?.length > 0
                  ) {
                    setFile(Array.from(e.target.files));
                  } else {
                    setFile(null);
                  }
                }}
              ></input>
            </ButtonWithModal>
          </Col>
        </Row>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <GridTable
              data={tableData}
              imagePath={"url"}
              containerStyle={styles.avatarImageContainer}
              setCarouselVisibility={setCarouselVisibility}
              deleteItemCallback={deleteAvatar}
              setOrder={updateAvatarsOrder}
            />
            <CarouselModal
              path={"url"}
              data={tableData}
              index={carouselIndex}
              showCarousel={showCarousel}
              setIndex={setCarouselIndex}
              setCarouselVisibility={setCarouselVisibility}
            />
          </>
        )}
      </Container>
    </>
  );
}
