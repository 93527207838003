/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Table,
  Tooltip,
  OverlayTrigger,
  Dropdown,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import LanguageContext from "../services/LanguageContext";
import { ComplexActionBar } from "./ComplexActionBar";
import { ImageWithPlaceholder } from "./ImageWithPlaceholder";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SourceIcon from "@mui/icons-material/Source";
import DescriptionIcon from "@mui/icons-material/Description";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ButtonWithModal } from "./ButtonWithModal";
import { styles } from "../styles/styles";
import "../styles/input.css";
import { formatDate } from "../services/DateFormatter";

interface TableProps {
  data: any[];
  customHeaders?: string[];
  customHeadersText?: string[];
  basePath?: string;
  saveAction?: Function;
  deleteAction?: Function;
  deleteModalTitle?: string;
  deleteModalText?: string;
  isDeletePermanent?: boolean;
  ignoreID?: boolean;
  allowDeleteAll?: boolean;
  forceURL?: boolean;
  smallThumbnails?: boolean;
  onClickImageCallback?: Function;
  headerOnClickCallback?: Function;
  imageShouldOpenNewTab?: boolean;
  updateDataOrder?: Function;
  insertAfterForm?: JSX.Element;
  copyIdOnClick?: boolean;
  order?: string;
  editableHeaders?: string[];
  customOnClickAction?: Function;
}

export function EvolvedTable({
  data,
  customHeaders,
  customHeadersText,
  basePath,
  saveAction,
  deleteAction,
  deleteModalTitle,
  deleteModalText,
  isDeletePermanent,
  ignoreID,
  forceURL,
  smallThumbnails,
  onClickImageCallback,
  headerOnClickCallback,
  imageShouldOpenNewTab,
  updateDataOrder,
  insertAfterForm,
  copyIdOnClick,
  order,
  editableHeaders,
  customOnClickAction,
}: TableProps) {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);

  const [dragList, setDragList] = useState(data);
  const [dragging, setDragging] = useState(false);
  const [tableType, setTableType] = useState("wrong");
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: "",
    rowName: "",
    requestId: "",
  });

  const [onHover, setOnHover] = useState("");
  const [clickableItemOnHover, setClickableItemOnHover] = useState(false);
  const [editedData, setEditedData] = useState({} as { [key: string]: any });

  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);

  const HEADERS = customHeaders
    ? customHeaders
    : data.length !== 0
    ? ignoreID
      ? Object.keys(data[0]).filter((element) => element !== "id")
      : Object.keys(data[0])
    : [];

  // If only basePath --> LinkTable
  // If only saveAction --> Edit and Save button
  // If only deleteAction --> Delete button
  // Else --> Edit, Save and Delete button
  useEffect(() => {
    if (basePath && !saveAction && !deleteAction) setTableType("link");
    if (!basePath && saveAction && !deleteAction) setTableType("edit");
    if (!basePath && !saveAction && deleteAction) setTableType("delete");
    if (!basePath && saveAction && deleteAction) setTableType("both");
    if (!basePath && !saveAction && !deleteAction) setTableType("readonly");

    let obj: any = {};
    Object.values(HEADERS).forEach((key) => {
      obj[key] = "";
    });

    setEditedData(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (rowData: string, name: string) => {
    if (!saveAction) return;

    let currentEditedData: any = editedData;
    currentEditedData[name] = rowData;

    saveAction(currentEditedData);
    setEditedData({});
  };

  const onDelete = (id: string) => {
    if (!deleteAction) return;

    deleteAction(id);
    setEditedData({});
  };

  const RenderWrongInputs = () => {
    return (
      tableType === "wrong" && (
        <Alert variant="warning">
          Something went wrong while loading the data.
        </Alert>
      )
    );
  };

  const setEditMode = (editData: any) => {
    if (editData.status === true) {
      let item = data.find(
        (item) => item[editData.rowName] === editData.requestId
      );

      let obj: any = {};
      Object.values(HEADERS).forEach((key) => {
        obj[key] = item[key];
      });
      if (obj["pdf"]) {
        obj["pdf"] = null;
      }
      if (obj["updateUrl"]) {
        obj["updateUrl"] = null;
      }
      setEditedData(obj);
    }
    setInEditMode(editData);
  };

  const renderEditOnlyActions = (id: string, name: string) => {
    return (
      <ComplexActionBar
        id={id}
        onSave={onSave}
        name={name}
        editMode={inEditMode}
        setEditMode={setEditMode}
      />
    );
  };

  const renderDeleteOnlyActions = (id: string) => {
    return (
      <ComplexActionBar
        id={id}
        onDelete={onDelete}
        deleteModalTitle={deleteModalTitle}
        deleteModalText={deleteModalText}
        isDeletePermanent={isDeletePermanent}
        editMode={inEditMode}
        setEditMode={setEditMode}
        onlyIcon={true}
      />
    );
  };

  const renderBothActions = (id: string, name: string) => {
    return (
      <ComplexActionBar
        id={id}
        onDelete={onDelete}
        deleteModalTitle={deleteModalTitle}
        deleteModalText={deleteModalText}
        isDeletePermanent={isDeletePermanent}
        onSave={onSave}
        name={name}
        editMode={inEditMode}
        setEditMode={setEditMode}
        onlyIcon={true}
      />
    );
  };

  const isImage = (header: any) => {
    return (
      header.toLocaleUpperCase().includes("THUMBNAIL") ||
      header.toLocaleUpperCase().includes("AVATAR") ||
      (forceURL && header.toLocaleUpperCase().includes("URL"))
    );
  };

  const isDate = (row: any, header: any) => {
    return (
      typeof row[header] === "number" &&
      row[header].toString().length === 10 &&
      (header.toLocaleUpperCase().includes("DATE") ||
        header.toLocaleUpperCase().includes("LAST"))
    );
  };

  const getTableRowWidth = (header: string) => {
    let headerUpper = header.toLocaleUpperCase();
    if (
      headerUpper === "ISVERIFIED" ||
      headerUpper === "LIVE" ||
      headerUpper === "PUBLISHABLE" ||
      headerUpper === "SKIPSTUDENTSELECTION" ||
      headerUpper === "HASMODEL"
    ) {
      return { width: "100px" };
    } else if (headerUpper === "PDF") {
      return { width: "150px" };
    } else if (headerUpper === "CONTACTEMAIL") {
      return { minWidth: "150px" };
    } else if (
      headerUpper === "NOTES" ||
      isImage(header) ||
      headerUpper === "UPDATEURL"
    ) {
      return { width: "200px" };
    } else if (
      headerUpper === "REGISTRATIONDATE" ||
      headerUpper === "TITLE" ||
      headerUpper === "NAME"
    ) {
      return { minWidth: "250px" };
    } else if (headerUpper === "CODENAME" || headerUpper === "EMAIL") {
      return { width: "300px" };
    } else if (headerUpper === "DESCRIPTION" || headerUpper === "SUBTITLE") {
      return { minWidth: "300px" };
    } else if (headerUpper === "ORGANIZATION NOTES") {
      return { minWidth: "400px" };
    } else {
      return undefined;
    }
  };

  const renderTableText = (text: string) => {
    return <p style={styles.tableText}>{text}</p>;
  };

  const renderIconWithToolTip = (
    icon: JSX.Element,
    tooltipText: string,
    text: string,
    openUrl: string
  ) => {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={text}>{tooltipText}</Tooltip>}
      >
        <p
          style={styles.tableText}
          onClick={(e) => {
            e.stopPropagation();
            window.open(openUrl, "_blank", "noopener, noreferrer");
          }}
        >
          {icon}
        </p>
      </OverlayTrigger>
    );
  };

  const renderCopyableId = (id: string) => {
    if (copyIdOnClick) {
      return (
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="copy">Click to copy</Tooltip>}
        >
          <div
            style={{ display: "inline-flex" }}
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(id);
            }}
          >
            {renderTableText(id)}
            {copyIdOnClick && <ContentCopyIcon style={styles.copyIcon} />}
          </div>
        </OverlayTrigger>
      );
    } else {
      return renderTableText(id);
    }
  };

  const renderClickableImage = (row: any, header: any) => {
    return (
      <div
        onClick={() => {
          if (onClickImageCallback) {
            onClickImageCallback(true, row.order);
          }
        }}
        onMouseEnter={() => {
          setClickableItemOnHover(true);
        }}
        onMouseLeave={() => {
          setClickableItemOnHover(false);
        }}
      >
        <ImageWithPlaceholder
          src={row[header]}
          className="img-thumbnail mx-auto"
          shouldOpenNewTab={
            imageShouldOpenNewTab ? imageShouldOpenNewTab : false
          }
          style={
            smallThumbnails ? styles.tableSmallThumbnail : styles.tableThumbnail
          }
        />
      </div>
    );
  };

  const RenderReadonlyCell = (header: any, row: any) => {
    if (header === "videoUrl") {
      return renderIconWithToolTip(
        <PlayCircleIcon style={styles.videoIcon} />,
        "Click here to open video in new tab.",
        "video",
        row[header]
      );
    } else if (header === "updateUrl") {
      return row["updateUrl"] !== undefined
        ? renderIconWithToolTip(
            <FileDownloadIcon style={styles.videoIcon} />,
            "Click here to download robot software image.",
            "updateUrl",
            row[header]
          )
        : renderTableText("-");
    } else if (header === "pdf") {
      return row[header]?._exists
        ? renderIconWithToolTip(
            <DescriptionIcon style={styles.pdfIcon} />,
            "Click to open pdf in new tab.",
            "pdf",
            row[header]?.url
          )
        : renderTableText("-");
    } else if (header === "id") {
      return renderCopyableId(row[header]);
    } else if (isImage(header)) {
      return renderClickableImage(row, header);
    } else if (isDate(row, header)) {
      return renderTableText(formatDate(row[header]));
    } else if (typeof row[header] === "object") {
      return row[header][language]?.toString().length > 0
        ? renderTableText(row[header][language]?.toString())
        : renderTableText("-");
    } else if (typeof row[header] === "boolean") {
      return row[header] ? (
        <CheckCircleOutlineIcon style={styles.successIcon} />
      ) : (
        <ClearIcon style={styles.failIcon} />
      );
    } else if (header === "contactEmail") {
      return (
        <p style={{ ...styles.tableText, wordBreak: "break-word" }}>
          {row[header]}
        </p>
      );
    } else if (row[header]) {
      return renderTableText(row[header].toString());
    } else {
      return renderTableText("-");
    }
  };

  const RenderEditableCell = (header: any, row: any) => {
    return (
      <>
        {header === "language" || header === "id" ? (
          renderCopyableId(row[header])
        ) : header === "updateUrl" ? (
          <input
            type="file"
            style={styles.tableFileInput}
            accept=".gz"
            onChange={(e) => {
              let currentData: any = editedData;
              if (e.target && e.target.files && e.target.files?.length > 0) {
                currentData[header] = e.target.files[0];
              } else {
                currentData[header] = null;
              }
              setEditedData(currentData);
            }}
          />
        ) : header === "pdf" ? (
          <input
            type="file"
            style={styles.tableFileInput}
            accept=".pdf"
            onChange={(e) => {
              let currentData: any = editedData;
              if (e.target && e.target.files && e.target.files?.length > 0) {
                currentData[header] = e.target.files[0];
              } else {
                currentData[header] = null;
              }
              setEditedData(currentData);
            }}
          />
        ) : typeof row[header] === "boolean" ? (
          <input
            type="checkbox"
            style={{ height: 25, width: 25 }}
            defaultChecked={row[header]}
            onChange={(event) => {
              let currentData: any = editedData;

              currentData[header] = event.target.checked;
              setEditedData(currentData);
            }}
          />
        ) : isImage(header) ? (
          <ImageWithPlaceholder
            src={row[header]}
            className="img-thumbnail mx-auto"
            shouldOpenNewTab={
              imageShouldOpenNewTab ? imageShouldOpenNewTab : false
            }
            style={
              smallThumbnails
                ? styles.tableSmallThumbnail
                : styles.tableThumbnail
            }
          />
        ) : header === "typeId" && row["availableTypeIds"] ? (
          <Dropdown>
            <Dropdown.Toggle
              style={styles.tableDropDown}
              variant="warning"
              id="dropdown-basic"
            >
              {editedData[header] ? editedData[header] : "-"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {row["availableTypeIds"].map((item: string) => (
                <Dropdown.Item
                  key={item}
                  onClick={(event) => {
                    let currentData: any = editedData;
                    const input = event.target as HTMLElement;
                    currentData[header] = input.innerText;
                    setEditedData(currentData);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      onSave(inEditMode.requestId, inEditMode.rowName);
                    }
                  }}
                >
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <textarea
            className="form-control"
            placeholder={row[header]}
            style={styles.tableInput}
            defaultValue={row[header]}
            onChange={(event) => {
              let currentData: any = editedData;

              currentData[header] = event.target.value;
              setEditedData(currentData);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                onSave(inEditMode.requestId, inEditMode.rowName);
              }
            }}
          />
        )}
      </>
    );
  };

  const handleDragStart = (index: number) => {
    setDragging(true);
    dragItem.current = index;
  };

  const handleDragEnter = (index: number) => {
    dragOverItem.current = index;
    let _data = JSON.parse(JSON.stringify(data));
    const draggedItemContent = _data.splice(dragItem.current, 1)[0];
    _data.splice(dragOverItem.current, 0, draggedItemContent);

    setDragList(_data);
  };

  const handleSort = () => {
    setDragging(false);
    if (
      dragItem.current === null ||
      dragOverItem.current === null ||
      !updateDataOrder
    ) {
      return;
    }

    dragItem.current = null;
    dragOverItem.current = null;
    let ids: string[] = [];
    for (let i = 0; i < dragList.length; i++) {
      ids.push(dragList[i].id);
    }

    updateDataOrder(ids);
  };

  const getRowDragStyle = (index: number) => {
    let tableRowStyle =
      tableType === "link" ? styles.clickableTableRow : styles.tableRow;
    if (index === dragOverItem.current) {
      return {
        ...tableRowStyle,
        ...{ visibility: "hidden" },
      };
    }
    return tableRowStyle;
  };

  function handleRowClick(row: any) {
    if (
      tableType === "link" &&
      onHover &&
      !clickableItemOnHover &&
      !inEditMode.status
    ) {
      return navigate(`${basePath}/${row.id}`);
    }
    if (customOnClickAction && !clickableItemOnHover && !inEditMode.status) {
      return customOnClickAction(row);
    }
  }

  function CreateTable() {
    return data.length === 0 ? (
      <div style={styles.tableEmptyState}>
        <SourceIcon style={styles.tableEmptyStateIcon} />
        <p>No data to display.</p>
        <p>Click Add new button to create new items.</p>
      </div>
    ) : (
      <Table
        striped
        bordered
        hover
        style={styles.table}
        onDragOver={(e) => e.preventDefault()}
      >
        <thead>
          <tr style={styles.tableHeader}>
            {HEADERS.map((header, index) => (
              <th
                key={header}
                style={styles.tableHeaderText}
                className="align-top"
                onClick={() => {
                  if (headerOnClickCallback) {
                    headerOnClickCallback(header);
                  }
                }}
              >
                {customHeadersText
                  ? customHeadersText[index]
                  : header.toUpperCase()}
                {order?.includes(header) ? (
                  order.includes("desc") ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )
                ) : undefined}
              </th>
            ))}
            {tableType !== "readonly" && tableType !== "link" && (
              <th style={styles.tableHeader}></th>
            )}
          </tr>
        </thead>
        <tbody>
          {dragList.map((row, index) => (
            <tr
              key={index}
              onClick={() => handleRowClick(row)}
              style={getRowDragStyle(index)}
              draggable={updateDataOrder ? true : false}
              onDragStart={() => {
                handleDragStart(index);
              }}
              onDragEnter={() => {
                handleDragEnter(index);
              }}
              onDragEnd={handleSort}
              onMouseEnter={() => {
                setOnHover(row.id + "-" + index);
              }}
              onMouseLeave={() => {
                setOnHover("");
              }}
            >
              {HEADERS.map((header, headerIndex) => (
                <td
                  key={`${header}-${row.id}`}
                  className={
                    isImage(header)
                      ? smallThumbnails
                        ? "col-1 p-2"
                        : "col-3 p-2"
                      : typeof row[header] === "boolean" ||
                        header.toLocaleUpperCase().includes("LANGUAGE")
                      ? "col-1 p-2"
                      : "p-2"
                  }
                  style={{
                    ...styles.tableDataCell,
                    textAlign:
                      isImage(header) || typeof row[header] === "boolean"
                        ? "center"
                        : "inherit",
                  }}
                >
                  {headerIndex === 0 && insertAfterForm && (
                    <ButtonWithModal
                      modalTitle={
                        row[header][language]
                          ? `Create new after "${row[header][language]}"`
                          : "Insert new element"
                      }
                      onlyIcon
                      style={{
                        ...styles.tableInsertAfterButton,
                        display:
                          onHover === `${row.id}-${index}` && !dragging
                            ? "block"
                            : "none",
                      }}
                      onMouseEnter={() => {
                        setClickableItemOnHover(true);
                      }}
                      onMouseLeave={() => {
                        setClickableItemOnHover(false);
                      }}
                      cancelCallback={() => {}}
                      isModalWithForm={true}
                    >
                      {React.cloneElement(insertAfterForm, {
                        insertAfterId: row.id,
                      })}
                    </ButtonWithModal>
                  )}
                  <div
                    style={{
                      ...styles.tableTextContainer,
                      ...getTableRowWidth(header),
                    }}
                  >
                    {inEditMode.status &&
                    (inEditMode.rowKey === `${row.language}` ||
                      inEditMode.rowKey === `${row.id}-${index}`) &&
                    (!editableHeaders || editableHeaders?.includes(header))
                      ? RenderEditableCell(header, row)
                      : RenderReadonlyCell(header, row)}
                  </div>
                </td>
              ))}
              {tableType !== "readonly" && tableType !== "link" && (
                <td
                  className="p-3 col-auto"
                  style={styles.tableActionButtonContainer}
                  onMouseEnter={() => {
                    setClickableItemOnHover(true);
                  }}
                  onMouseLeave={() => {
                    setClickableItemOnHover(false);
                  }}
                >
                  <>
                    {tableType === "delete" &&
                      renderDeleteOnlyActions(
                        row.language ? row.language : `${row.id}-${index}`
                      )}
                    {tableType === "both" &&
                      renderBothActions(
                        row.language ? row.language : `${row.id}-${index}`,
                        row.language ? "language" : "id"
                      )}
                    {tableType === "edit" &&
                      renderEditOnlyActions(
                        row.language ? row.language : `${row.id}-${index}`,
                        row.language ? "language" : "id"
                      )}
                  </>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return <>{tableType === "wrong" ? RenderWrongInputs() : CreateTable()}</>;
}
