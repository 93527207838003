import {
  DeleteLessonRequest,
  GetUserGroupLessonsRow,
  GetUserGroupRequest,
  GetUserGroupResponse,
  GetUserGroupUsersRow,
  RemoveLessonFromUserGroupRequest,
  RemoveUserFromUserGroupRequest,
  UpdateUserGroupOperationRequest,
  UpdateUserGroupRequest,
} from "@revolutionrobotics/admin-api";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ErrorState } from "../../components/ErrorState";
import { EvolvedTable } from "../../components/Table";
import { UserGroupApi } from "../../services/configuration";
import { ErrorResponse, SetError } from "../../services/general";
import { styles } from "../../styles/styles";

import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { DeleteModal } from "../../components/DeleteModal";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { AddNewLessonToUserGroup } from "../../components/UserGroups/AddNewLessonToUserGroup";
import { AddNewUserToUserGroup } from "../../components/UserGroups/AddNewUserToUserGroup";
import { getActiveOrganization } from "../../services/getSelectedOrganization";

export type Params = {
  id: string;
};

export function UserGroupDetails() {
  const { id } = useParams<Params>();
  const navigate = useNavigate();

  const [data, setData] = useState({} as GetUserGroupResponse);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorResponse | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("UserGroupDetails did mount.", id);
    getUserGroupDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, []);

  const getUserGroupDetails = () => {
    console.log("getUserGroupDetails()");
    if (!id) {
      return;
    }

    setLoading(true);

    const req: GetUserGroupRequest = {
      id: id,
    };

    UserGroupApi.getUserGroup(req)
      .then(
        (result: GetUserGroupResponse) => {
          setData(result);
          console.log(result);
        },
        (error: ErrorResponse) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const updateUserGroup = (data: any) => {
    console.log("updateSkill()");

    if (!id) {
      return;
    }

    const request: UpdateUserGroupRequest = {
      details: data.details,
      name: data.name,
    };

    const req: UpdateUserGroupOperationRequest = {
      id: id,
      request: request,
    };

    UserGroupApi.updateUserGroup(req)
      .then(
        () => {
          console.log("User group has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getUserGroupDetails();
      });
  };

  const deleteUserGroup = () => {
    console.log("deleteLesson()");

    if (!id) {
      return;
    }

    const req: DeleteLessonRequest = {
      id: id,
    };

    UserGroupApi.deleteUserGroup(req)
      .then(
        () => {
          console.log(id, " has been deleted.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        navigate(`/user-groups`);
      });
  };

  const removeLessonFromUserGroup = (lessonId: string) => {
    if (!id) {
      return;
    }
    const req: RemoveLessonFromUserGroupRequest = {
      id: id,
      lid: lessonId,
    };

    UserGroupApi.removeLessonFromUserGroup(req)
      .then(
        () => {
          console.log(lessonId, " skill has been removed.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getUserGroupDetails();
      });
  };

  const removeUserFromUserGroup = (userId: string) => {
    if (!id) {
      return;
    }
    const req: RemoveUserFromUserGroupRequest = {
      id: id,
      uid: userId,
    };

    UserGroupApi.removeUserFromUserGroup(req)
      .then(
        () => {
          console.log(userId, " robot has been removed.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getUserGroupDetails();
      });
  };

  return (
    <>
      {error && (
        <ErrorState
          refresh={getUserGroupDetails}
          error={error}
          setError={setError}
        />
      )}
      <Container fluid>
        <Row>
          <Col>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Row className="mb-3">
                  <Col style={{ textAlign: "center" }}>
                    <Row>
                      <h1
                        className="mx-auto mb-1 text-center"
                        style={styles.pageTitle}
                      >
                        Group Details
                      </h1>
                    </Row>
                    <Row>
                      <h2
                        className="mx-auto mb-1 text-center"
                        style={styles.secondaryText}
                      >
                        {data.name}
                      </h2>
                    </Row>
                    <Row>
                      <h3
                        className="mx-auto mb-1 text-center"
                        style={styles.pageTitle}
                      >
                        {id}
                      </h3>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2 style={styles.secondaryText}>Details:</h2>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    <EvolvedTable
                      data={[
                        { details: data.details, name: data.name, id: id },
                      ]}
                      ignoreID
                      saveAction={(editedData: object) => {
                        updateUserGroup(editedData);
                      }}
                    />
                  </Col>
                </Row>

                {/** Lesson */}
                <Row className="mt-3 align-items-center">
                  <Col className="col-9">
                    <h2 style={styles.secondaryText}>Lessons:</h2>
                  </Col>
                  <Col className="text-end">
                    <ButtonWithModal
                      buttonTitle={"Add new"}
                      modalTitle={"Add new Lesson"}
                      isModalWithForm={true}
                    >
                      <AddNewLessonToUserGroup
                        id={id!}
                        setError={setError}
                        refresh={getUserGroupDetails}
                      />
                    </ButtonWithModal>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <EvolvedTable
                      data={data.lessons.rows}
                      ignoreID
                      deleteAction={removeLessonFromUserGroup}
                      deleteModalTitle="Remove lesson from user group."
                      deleteModalText="You can re-add the lesson later."
                      isDeletePermanent={false}
                      customOnClickAction={(lesson: GetUserGroupLessonsRow) => {
                        navigate(`/lessons/lesson/${lesson.id}`);
                      }}
                    />
                  </Col>
                </Row>

                {/** Users */}
                <Row className="mt-3 align-items-center">
                  <Col className="col-9">
                    <h2 style={styles.secondaryText}>Users:</h2>
                  </Col>
                  <Col className="text-end">
                    <ButtonWithModal
                      buttonTitle={"Add new"}
                      modalTitle={"Add new Users"}
                      isModalWithForm={true}
                      customModalWidth={800}
                    >
                      <AddNewUserToUserGroup
                        id={id!}
                        setError={setError}
                        refresh={getUserGroupDetails}
                      />
                    </ButtonWithModal>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <EvolvedTable
                      customHeadersText={["CODENAME", "ORGANIZATION NOTES"]}
                      data={data.users.rows}
                      ignoreID
                      deleteAction={removeUserFromUserGroup}
                      deleteModalTitle="Remove user from user group."
                      deleteModalText="You can re-add the user later."
                      isDeletePermanent={false}
                      customOnClickAction={(user: GetUserGroupUsersRow) => {
                        navigate(
                          `/organization/${getActiveOrganization().id}/user/${
                            user.id
                          }`
                        );
                      }}
                    />
                  </Col>
                </Row>

                {/* Delete button */}
                <Col className="text-end">
                  <DeleteModal
                    icon={<DeleteIcon />}
                    buttonTitle={`Delete ${data.name}`}
                    modalTitle={`Delete ${data.name}`}
                    agreeCallback={() => deleteUserGroup()}
                  />
                </Col>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
