import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { LoadingIndicator } from "../../components/LoadingIndicator";

export function Blockly() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container fluid>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <iframe
              src={"desktop.html"}
              height={"750px"}
              width={"100%"}
              frameBorder={"0"}
              allowFullScreen
              title="html"
            />
          </>
        )}
      </Container>
    </>
  );
}
