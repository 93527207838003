import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import PersonIcon from "@mui/icons-material/Person";
import FaceIcon from "@mui/icons-material/Face";
import BoltIcon from "@mui/icons-material/Bolt";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import LanguageIcon from "@mui/icons-material/Language";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ExtensionIcon from "@mui/icons-material/Extension";
import HelpIcon from "@mui/icons-material/Help";
import PlayLessonIcon from "@mui/icons-material/PlayLesson";
import GroupsIcon from "@mui/icons-material/Groups";
import "../styles/Menu.css";
import { styles } from "../styles/styles";

interface AccountItem {
  id: string;
  name: string;
  active: boolean;
  features: string[];
}

export function Menu() {
  const [features, setFeatures] = useState([] as string[]);

  const getCookie = () => {
    var allCookieArray = document.cookie.split(";");
    for (var i = 0; i < allCookieArray.length; i++) {
      var temp = allCookieArray[i].trim();
      if (temp.indexOf("Auth_Info=") === 0) {
        var authInfo = temp.substring("Auth_Info=".length, temp.length);
        if (authInfo) {
          let featureList = JSON.parse(authInfo).accounts.find(
            (item: AccountItem) => item.active === true
          ).features;
          setFeatures(featureList);
        }
      }
    }
  };
  useEffect(() => {
    getCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Nav>
      {features.includes("organizations") && (
        <Nav.Item className="my-navitem">
          <LinkContainer to="/organizations">
            <Nav.Link className="my-navbar align-center">
              <CorporateFareIcon style={styles.menuIcon} />
              <br />
              Organization
            </Nav.Link>
          </LinkContainer>
        </Nav.Item>
      )}

      {features.includes("user-groups") && (
        <Nav.Item className="my-navitem">
          <LinkContainer to="/user-groups">
            <Nav.Link className="my-navbar align-center">
              <GroupsIcon style={styles.menuIcon} />
              <br />
              User Groups
            </Nav.Link>
          </LinkContainer>
        </Nav.Item>
      )}

      {features.includes("users") && (
        <Nav.Item className="my-navitem">
          <LinkContainer to="/users">
            <Nav.Link className="my-navbar align-center">
              <PersonIcon style={styles.menuIcon} />
              <br />
              Users
            </Nav.Link>
          </LinkContainer>
        </Nav.Item>
      )}

      {features.includes("avatars") && (
        <Nav.Item className="my-navitem">
          <LinkContainer to="/avatars">
            <Nav.Link className="my-navbar">
              <FaceIcon style={styles.menuIcon} />
              <br />
              Avatars
            </Nav.Link>
          </LinkContainer>
        </Nav.Item>
      )}

      {features.includes("skills") && (
        <Nav.Item className="my-navitem">
          <LinkContainer to="/skills">
            <Nav.Link className="my-navbar">
              <BoltIcon style={styles.menuIcon} />
              <br />
              Epics
            </Nav.Link>
          </LinkContainer>
        </Nav.Item>
      )}

      {features.includes("challenges") && (
        <Nav.Item className="my-navitem">
          <LinkContainer to="/challenges">
            <Nav.Link className="my-navbar">
              <EmojiEventsIcon style={styles.menuIcon} />
              <br />
              Challenges
            </Nav.Link>
          </LinkContainer>
        </Nav.Item>
      )}

      {features.includes("hero-robots") && (
        <Nav.Item className="my-navitem">
          <LinkContainer to="/robots">
            <Nav.Link className="my-navbar">
              <SmartToyIcon style={styles.menuIcon} />
              <br />
              Robots
            </Nav.Link>
          </LinkContainer>
        </Nav.Item>
      )}

      {features.includes("lessons") && (
        <Nav.Item className="my-navitem">
          <LinkContainer to="/lessons">
            <Nav.Link className="my-navbar">
              <PlayLessonIcon style={styles.menuIcon} />
              <br />
              Lessons
            </Nav.Link>
          </LinkContainer>
        </Nav.Item>
      )}

      <Nav.Item className="my-navitem">
        <LinkContainer to="/blockly">
          <Nav.Link className="my-navbar">
            <ExtensionIcon style={styles.menuIcon} />
            <br />
            Blockly
          </Nav.Link>
        </LinkContainer>
      </Nav.Item>

      {features.includes("languages") && (
        <Nav.Item className="my-navitem">
          <LinkContainer to="/language">
            <Nav.Link className="my-navbar">
              <LanguageIcon style={styles.menuIcon} />
              <br />
              Language
            </Nav.Link>
          </LinkContainer>
        </Nav.Item>
      )}

      {features.includes("robot-softwares") && (
        <Nav.Item className="my-navitem">
          <LinkContainer to="/updates">
            <Nav.Link className="my-navbar">
              <BrowserUpdatedIcon style={styles.menuIcon} />
              <br />
              Updates
            </Nav.Link>
          </LinkContainer>
        </Nav.Item>
      )}

      <Nav.Item className="my-navitem">
        <LinkContainer to="/help">
          <Nav.Link className="my-navbar">
            <HelpIcon style={styles.menuIcon} />
            <br />
            Help
          </Nav.Link>
        </LinkContainer>
      </Nav.Item>
    </Nav>
  );
}
