import React from "react";
import { Spinner } from "react-bootstrap";
import { styles } from "../styles/styles";

export function LoadingIndicator() {
  return (
    <>
      <div style={styles.loadingIndicatorContainer}>
        <div>
          <Spinner
            animation="border"
            role="status"
            style={styles.loadingSpinner}
          />
          <p style={styles.loadingText}>Loading...</p>
        </div>
      </div>
    </>
  );
}
