import {
  GetChallengeCategoriesResponse,
  GetChallengeCategoriesCategoriesRow,
  UpdateChallengeCategoryOrderRequest,
  UpdateChallengeCategoryOrderOperationRequest,
} from "@revolutionrobotics/admin-api";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ButtonWithModal } from "../../components/ButtonWithModal";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { NewChallengeCategoryForm } from "../../components/Challenge/NewChallengeCategoryForm";
import { EvolvedTable } from "../../components/Table";
import { ChallengeApi } from "../../services/configuration";
import { ErrorResponse, SetError } from "../../services/general";
import { styles } from "../../styles/styles";
import LanguageContext from "../../services/LanguageContext";
import { ErrorState } from "../../components/ErrorState";

export function ChallengesCategories() {
  const [tableData, setTableData] = useState(
    [] as GetChallengeCategoriesCategoriesRow[]
  );
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const { setSystemLanguages } = useContext(LanguageContext);

  const getChallengeCategories = () => {
    console.log("getChallengeCategories()");
    setLoading(true);

    ChallengeApi.getChallengeCategories()
      .then(
        (result: GetChallengeCategoriesResponse) => {
          setTableData(result.categories.rows);
          setSystemLanguages(result.systemLanguages);
          console.log(result);
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const updateChallengeCategoriesOrder = (ids: string[]) => {
    console.log("updateChallengeCategoriesOrder()");

    const request: UpdateChallengeCategoryOrderRequest = {
      ids: ids,
    };
    const req: UpdateChallengeCategoryOrderOperationRequest = {
      request: request,
    };

    ChallengeApi.updateChallengeCategoryOrder(req)
      .then(
        () => {
          console.log("Challenge category order has been updated.");
        },
        (error) => {
          SetError(error, setError);
        }
      )
      .finally(() => {
        getChallengeCategories();
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getChallengeCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid>
      {error && (
        <ErrorState
          refresh={getChallengeCategories}
          error={error}
          setError={setError}
        />
      )}
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Row>
            <Col>
              <h1
                className="mx-auto mb-3 text-center text-uppercase"
                style={styles.pageTitle}
              >
                Challenges Categories
              </h1>
            </Col>
          </Row>
          <Row
            className="mb-3 text-center "
            xs={"auto"}
            style={{ alignItems: "center" }}
          >
            <Col>
              <ButtonWithModal
                buttonTitle={"Add new"}
                modalTitle={"Add new challenge category"}
                isModalWithForm={true}
              >
                <NewChallengeCategoryForm
                  setError={setError}
                  refresh={getChallengeCategories}
                />
              </ButtonWithModal>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2
                className="mx-auto mb-1 text-uppercase"
                style={styles.secondaryText}
              >
                Challenge categories
              </h2>
              <EvolvedTable
                data={tableData}
                basePath="category"
                customHeaders={["title", "live"]}
                updateDataOrder={updateChallengeCategoriesOrder}
                insertAfterForm={
                  <NewChallengeCategoryForm
                    setError={setError}
                    refresh={getChallengeCategories}
                  />
                }
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
